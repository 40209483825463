import React, { PureComponent } from 'react';
import styled from 'styled-components';


class Legend extends PureComponent {
    render() {
        const {
            children,
            ...props
        } = this.props;

        return (
            <Element {...props}>{children}</Element>
        );
    }
}


const Element = styled.div`
    color: #999999;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    text-transform: uppercase;
    margin: 0 0 4px;
`;


export default Legend;

export const CHANGE_META = 'CHANGE_META';

export function changeMeta(payload) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_META,
            payload: payload,
        });
    };
}

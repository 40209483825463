import { Component } from 'react';

class ComponentDidMount extends Component {

    componentDidMount() {
        this.props.onComponentDidMount();
    };

    render() {
        return this.props.children;
    }
}

export default ComponentDidMount;

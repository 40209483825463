import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';
import i18n from "i18n-js";

import * as ActionTypes from "../actions";
import * as LinkService from "../services/LinkService";
import * as ItemService from "../services/ItemService";
import * as Const from "../Const";

import Kicker from "./Kicker";
import Button from "./Button";
import Modal from "./Modal";
import Inquiry from "../containers/Result/Inquiry";
import LoadingIndicator from "./LoadingIndicator";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        location: state.router.location,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        download: (filetype, configuration) => dispatch(ActionTypes.download(filetype, configuration)),
        showInquiry: () => dispatch(ActionTypes.changeInquiryStatus({newStatus: Const.INQUIRY_STATUS_SHOWING})),
        resetInquiry: () => dispatch(ActionTypes.changeInquiryStatus({newStatus: Const.INQUIRY_STATUS_HIDDEN})),
    };
};

class StatusBar extends Component {

    static getLabelForOutput(output) {
        switch (output) {
            case "HI":
                return "High";
            case "LOW":
                return "Low";
            default:
                return "";
        }
    }

    static getDimensions(items) {
        return ItemService.getDimensions(items);
    }

    nextFunction() {

        let customNextAllowed = true;
        if (this.props.beforeNextFunction) {
            customNextAllowed = this.props.beforeNextFunction();
        }

        if (customNextAllowed && this.isNextAllowed()) {
            const currentContainer = LinkService.getContainerFromPath(this.props.location.pathname);

            switch (currentContainer) {
                case "ProductSelection":
                    this.props.redirectTo(
                        LinkService.getPathForContainer(this.props.language.locale, "FormSelection")
                    );
                    break;
                case "FormSelection":
                    this.props.redirectTo(
                        LinkService.getPathForContainer(this.props.language.locale, "LineInput")
                    );
                    break;
                case "LineInput":
                    this.props.redirectTo(
                        LinkService.getPathForContainer(this.props.language.locale, "Configurator")
                    );
                    break;
                case "Configurator":
                    this.props.redirectTo(
                        LinkService.getPathForContainer(this.props.language.locale, "Result")
                    );
                    break;
                default:
                    this.props.redirectTo(
                        LinkService.getPathForContainer(this.props.language.locale, "ProductSelection")
                    );
                    break;
            }

        }
    }

    isNextAllowed() {
        const currentContainer = LinkService.getContainerFromPath(this.props.location.pathname);
        const {
            configuration,
        } = this.props;

        if (currentContainer === "ProductSelection" && LinkService.isContainerAllowed("FormSelection", configuration)) {
            return true;
        }

        if (currentContainer === "FormSelection" && LinkService.isContainerAllowed("LineInput", configuration)) {
            return true;
        }

        if (currentContainer === "LineInput" && LinkService.isContainerAllowed("Configurator", configuration)) {
            return true;
        }

        if (currentContainer === "Configurator" && LinkService.isContainerAllowed("Result", configuration)) {
            return true;
        }

        return false;
    }

    isInquiryAllowed() {
        const currentContainer = LinkService.getContainerFromPath(this.props.location.pathname);

        if (currentContainer === "Result") {
            return true;
        }

        return false;
    }

    getNextLabel() {
        const currentContainer = LinkService.getContainerFromPath(this.props.location.pathname);

        if (currentContainer === "LineInput") {
            return i18n.t('statusBar.nextConfigurator');
        }
        else if (currentContainer === "Configurator") {
            return i18n.t('statusBar.nextResult');
        }

        return i18n.t('action.continue');
    }

    performReluxDownload = () => {
        this.props.download("relux", this.props.configuration);
    };

    render() {

        const {
            configuration,
        } = this.props,
            attributes = configuration.selectedAttributes,
            result = configuration.result;

        let mainOptics = "";
        if (configuration?.availableLines[1] && configuration.availableLines[1]?.items[0] && configuration.availableLines[1]?.items[0]?.line?.optics) {
            mainOptics = configuration.availableLines[1].items[0].line.optics;
        }
        const optics = ItemService.getTotalOpticsList(configuration.items, mainOptics);


        return (
            <>

                {Const.INQUIRY_STATUS_SHOWING === configuration.meta.inquiryStatus && <Inquiry onClose={() => this.props.resetInquiry()} />}

                <LoadingIndicator isVisible={Const.INQUIRY_STATUS_SENDING === configuration.meta.inquiryStatus} info={i18n.t('inquiry.sendingMessage')} />

                {[Const.INQUIRY_STATUS_SUCCESS, Const.INQUIRY_STATUS_ERROR].indexOf(configuration.meta.inquiryStatus) !== -1 &&
                    <Modal small isVisible={true}>
                        {Const.INQUIRY_STATUS_SUCCESS === configuration.meta.inquiryStatus && <p>
                            {i18n.t('inquiry.successMessage')}
                        </p>}
                        {Const.INQUIRY_STATUS_ERROR === configuration.meta.inquiryStatus && <p>
                            {i18n.t('inquiry.errorMessage')}
                        </p>}
                        <p style={{textAlign: "right"}}>
                            <Button label={i18n.t('action.ok')} onClick={() => this.props.resetInquiry()} />
                        </p>
                    </Modal>
                }

                <Container>
                    <Content>

                        <HeadlineContainer>
                            <Kicker>{i18n.t('statusBar.configurationData')}</Kicker>
                        </HeadlineContainer>

                        {attributes.mountingType !== false &&
                            <p>
                                <strong>{i18n.t('title.mountingType')}</strong> <br />
                                {i18n.t('value.mountingType.' + attributes.mountingType)}
                            </p>
                        }

                        {attributes.opticsSimple !== false && <>
                            <p>
                                <strong>{i18n.t('title.lightEngine')}</strong> <br />
                            </p>
                            <p>
                                {i18n.t('title.lightEngineDirect')}:
                                <br />{i18n.t('value.opticsSimple.' + attributes.opticsSimple)}
                                {optics.direct.length > 0 && <>
                                    {Object.entries(optics.direct).map(([key, optic]) => (
                                        <React.Fragment key={'value.optic.' + optic}><br/>+ {i18n.t('value.optic.' + optic)}</React.Fragment>
                                    ))}
                                </>}
                            </p>
                            <p>
                                {optics.indirect.length > 0 && <>
                                    {i18n.t('title.lightEngineIndirect')}:
                                    {Object.entries(optics.indirect).map(([key, optic]) => (
                                        <React.Fragment key={'value.optic.' + optic}><br/>{i18n.t('value.optic.' + optic)}</React.Fragment>
                                    ))}
                                </>}
                            </p>
                        </>}

                        {attributes.form !== false &&
                            <p>
                                <strong>{i18n.t('title.form')}</strong> <br />
                                {i18n.t('value.form.' + attributes.form)}
                            </p>
                        }

                        {LinkService.isContainerAllowed("Configurator", configuration) &&
                            <p>
                                <strong>{i18n.t('title.dimensions')}</strong> <br />
                                {Object.entries(StatusBar.getDimensions(configuration.items))
                                    .map(([line, length]) => (
                                        <span key={line}>{i18n.t("title.laneShort")}{line}: {length} mm<br /></span>
                                    ))
                                }
                            </p>
                        }

                        {attributes.output &&
                        <p>
                            <strong>{i18n.t('title.lightIntensity')}</strong> <br/>
                            {StatusBar.getLabelForOutput(attributes.output)}
                        </p>
                        }

                        {attributes.dimmable &&
                            <p>
                                <strong>{i18n.t('title.operatingDevice')}</strong> <br/>
                                {i18n.t('value.dimmable.' + attributes.dimmable)}
                            </p>
                        }

                        {attributes.color &&
                            <p>
                                <strong>{i18n.t('title.caseColor')}</strong> <br/>
                                {i18n.t('value.color.' + attributes.color)}
                            </p>
                        }

                        {(result.power || result.luminousFlux || result.powerSuppliesCount) &&
                            <div style={{marginTop: 40}}>

                                <HeadlineContainer>
                                    <Kicker>{i18n.t('statusBar.calculation')}</Kicker>
                                </HeadlineContainer>

                                {result.power &&
                                    <p>
                                        <strong>{i18n.t('title.systemPower')}</strong> <br />
                                        <NumberFormat value={result.power} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={0} fixedDecimalScale={true} suffix={" " + i18n.t('title.watts')} />
                                    </p>
                                }

                                {result.weight &&
                                    <p>
                                        <strong>{i18n.t('title.weight')}</strong> <br />
                                        <NumberFormat value={result.weight} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} suffix={" kg"} />
                                    </p>
                                }

                                {result.luminousFlux &&
                                    <p>
                                        <strong>{i18n.t('title.luminousFlux')}</strong> <br />
                                        <NumberFormat value={result.luminousFlux} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={0} fixedDecimalScale={false} suffix={" lm"} />
                                    </p>
                                }

                                {result.powerSuppliesCount &&
                                    <p>
                                        <strong>{i18n.t('title.powerSuppliesCount')}</strong> <br />
                                        {result.powerSuppliesCount}
                                    </p>
                                }

                            </div>
                        }

                    </Content>
                    <Controls>

                        {result.price &&
                            <PriceContainer>
                                <PriceLabel>{i18n.t('statusBar.systemPrice')}<sup>*</sup></PriceLabel>
                                <NumberFormat value={result.price} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} suffix={" Euro"} renderText={value => <Price>{value}</Price>} />
                                <PriceDisclaimer><sup>*</sup> {i18n.t("statusBar.priceDisclaimer")}</PriceDisclaimer>
                            </PriceContainer>
                        }

                        {this.isInquiryAllowed() ?
                            <Button disabled={!configuration.configurationKey} onClick={() => this.props.showInquiry()} label={i18n.t('statusBar.showInquiryModal')} block filled /> :
                            <Button disabled={!this.isNextAllowed()} onClick={() => this.nextFunction()} label={this.getNextLabel()} block filled />
                        }

                        <Button disabled={!LinkService.isContainerAllowed("Configurator", configuration)} onClick={() => this.performReluxDownload()} block
                                label={<span><span style={{marginRight: 8}}><FontAwesomeIcon icon={["fal", "arrow-to-bottom"]} /></span>{i18n.t('statusBar.openInRelux')}</span>}
                        />

                    </Controls>

                </Container>
            </>
        );
    }
}


const Container = styled.div`
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;
const Content = styled.div`
    flex: 1 0 auto;
`;
const Controls = styled.div`
    position: sticky;
    bottom: 0;
    padding: 50px 0 0;
    background-image: linear-gradient(to bottom, hsla(0, 0%, 100%, 0) 0%, white 45px, white 100%);
`;

const HeadlineContainer = styled.div`
    margin-bottom: 20px;
`;

const PriceContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 26px;
`;
const PriceLabel = styled.div`
	color: #999999;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 6px;
`;
const Price = styled.div`
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
	font-size: 26px;
	font-weight: 300;
	line-height: 30px;
`;
const PriceDisclaimer = styled.div`
	color: #999999;
    font-size: 11px;
    line-height: 11px;
    margin: 10px auto 6px;
`;



export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";

import styled from "styled-components";
import i18n from "i18n-js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ActionTypes from "../actions";
import * as LinkService from "../services/LinkService";

import HomeHeroImage from "../assets/Images/serio-homeHero.png";

import Content from "./Home/Content";

import Header from "../components/Header";
import Button from "../components/Button";
import Label from "../components/Label";
import Input from "../components/Input";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        changeLanguage: (params) => dispatch(ActionTypes.changeLanguage(params)),
        loadConfiguration: (params) => dispatch(ActionTypes.loadConfiguration(params)),
    };
};

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            configurationKeyInput: "",
        }
    }

    onConfigurationKeyInputValueChanged(value) {
        value = value.trim().toUpperCase();

        this.setState({
            ...this.state,
            configurationKeyInput: value,
        });
    }

    loadConfiguration() {
        this.props.loadConfiguration(this.state.configurationKeyInput);
    }

    startConfiguration() {
        this.props.redirectTo(
            LinkService.getPathForContainer(this.props.language.locale, "ProductSelection")
        );
    }

    componentDidUpdate() {

        // We assume loading the configuration was successful once the items array is rehydrated
        // ToDo: this could provoke errors, so maybe find a better way?
        if (this.props.configuration.items.length) {
            this.props.redirectTo(
                LinkService.getPathForContainer(this.props.language.locale, "Result")
            );
        }
    };


    changeLanguage(language) {
        this.props.changeLanguage(language);
        // ToDo: A more robust way would be to react to the language change and redirect afterwards instead of anticipating it
        // Even better, always "listen" to language changes and dynamically redirect to the new link
        this.props.redirectTo(
            LinkService.getPathForContainer(language, "Home")
        );
    }

    render() {

        const {language} = this.props;

        return (
            <Container className="Configurator">

                <Header customContent={
                    <div>
                        <LanguageButton onClick={() => this.changeLanguage(language.locale === "de" ? "en" : "de")}>
                            <FontAwesomeIcon icon={['fal', 'globe']} style={{marginRight: ".25em"}} /> {language.locale === "de" ? "EN" : "DE"}
                        </LanguageButton>
                        <Button filled wide label={i18n.t('home.start')} onClick={() => this.startConfiguration()} />
                    </div>
                } />

                {this.props.isLoading ?

                    <LoadingContainer>Lade...</LoadingContainer>
                    :

                    <div>

                        <HeroWrap>
                            <Hero>
                                <HeroImage src={HomeHeroImage} />
                                <HeroContent>
                                    <HeroHeadline>{i18n.t('home.hero')}</HeroHeadline>
                                    <Button filled wide label={i18n.t('home.startLong')} onClick={() => this.startConfiguration()} />
                                </HeroContent>
                                <HeroInputContainer>
                                    <div>
                                        <Label>{i18n.t('title.configurationKey')}</Label>
                                        <Input style={{width: 250, margin: "0 30px 0 0"}} onInput={(event) => this.onConfigurationKeyInputValueChanged(event.target.value)} maxlength="8" />
                                    </div>
                                    <Button label={i18n.t('action.open')} onClick={() => this.loadConfiguration()} disabled={this.state.configurationKeyInput.length !== 8} style={{alignSelf: "end"}} />
                                </HeroInputContainer>
                            </Hero>
                        </HeroWrap>

                        <Content />

                        <Footer>

                            <div>© lichtwerk</div>

                            <div>
                                <a target="_blank" rel="noopener noreferrer" href={i18n.t('footer.termsAndConditions.link')}>{i18n.t('footer.termsAndConditions.label')}</a>
                                <a target="_blank" rel="noopener noreferrer" href={i18n.t('footer.dataProtection.link')}>{i18n.t('footer.dataProtection.label')}</a>
                                <a target="_blank" rel="noopener noreferrer" href={i18n.t('footer.imprint.link')}>{i18n.t('footer.imprint.label')}</a>
                            </div>

                        </Footer>

                    </div>

                }

            </Container>
        )
    }
}

const Container = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
`;

const LanguageButton = styled.span`
    display: inline-block;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif; 
    color: #999999;
    vertical-align: middle;
    cursor: pointer;
    padding: 8px;
    margin-right: 28px;
    :hover {
        color: #E45200;
    }
`;

const HeroWrap = styled.div`
    overflow-x: hidden;
`;
const Hero = styled.div`
    position: relative;
    max-width: 1200px;
    margin: 0 auto 20px;
    :before {
        content: '';
        background-color: #EAEAEA;
        position: absolute;
        top: 0;
        bottom: 13.6%;
        left: -100vw;
        right: -100vw;
        z-index: -1;
    }
`;
const HeroImage = styled.img`
    width: 100%;
    height: auto;
`;
const HeroContent = styled.div`
    position: absolute;
    max-width: 35%;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
`;
const HeroHeadline = styled.div`
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif; 
    font-size: 36px;
    line-height: 42px;
    font-weight: 300;
    margin-bottom: 40px;
`;

const HeroInputContainer = styled.div`
    position: absolute;
    right: 20px;
    bottom: -20px;
    display: flex;
    flex-direction: row;
`;


const Footer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 15px;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    color: #999;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 1;
    
    a {
        text-transform: uppercase;
        color: inherit;
        padding: 0 8px;
        :hover, :focus {
            color: #e45200;
        }
    }
`;

const LoadingContainer = styled.div`
    margin-top: 120px;
    text-align: center;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Home);

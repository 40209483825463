// Live Assets
export const ASSET_URL = "https://www.lichtwerk.de/";
// Local Development
// export const ASSET_URL = "https://typo3-lichtwerk-distribution.ddev.site/";
export const ASSET_PATH = "fileadmin/serio/";

// Live API
export const API_URL = "https://www.lichtwerk.de/";
// Local development
// export const API_URL = "https://typo3-lichtwerk-distribution.ddev.site/";
// Staging flow
// export const API_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "https://lichtwerk-dev.ddev.site/" : "https://www.lichtwerk.de/";

export const INQUIRY_STATUS_HIDDEN = 0;
export const INQUIRY_STATUS_SHOWING = 1;
export const INQUIRY_STATUS_SENDING = 2;
export const INQUIRY_STATUS_SUCCESS = 3;
export const INQUIRY_STATUS_ERROR = 4;

export const DOWNLOAD_STATUS_NONE = 0;
export const DOWNLOAD_STATUS_SENDING = 1;
export const DOWNLOAD_STATUS_SUCCESS = 2;
export const DOWNLOAD_STATUS_ERROR = 3;

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";

import styled from "styled-components";
import i18n from "i18n-js";

import Legend from "../components/Legend";
import SelectForm from "../components/SelectForm";
import Tooltip from "../components/Tooltip";
import Screen from "../components/Screen";

import * as ActionTypes from "../actions";
import * as LinkService from "../services/LinkService";
import * as Const from "../Const";

import ImageKaree from "../assets/Images/Forms/karree.svg";
import ImageLForm from "../assets/Images/Forms/l-form.svg";
import ImageLinear from "../assets/Images/Forms/linear.svg";
import ImageSForm from "../assets/Images/Forms/s-form.svg";
import ImageUForm from "../assets/Images/Forms/u-form.svg";
import ImageOctagonForm from "../assets/Images/Forms/octagon.svg";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        changeSelectedAttribute: (params) => dispatch(ActionTypes.changeSelectedAttribute(params)),
        showInquiry: () => dispatch(ActionTypes.changeInquiryStatus({newStatus: Const.INQUIRY_STATUS_SHOWING})),
    };
};

class FormSelection extends Component {

    constructor(props) {
        super(props);

        if (!LinkService.isContainerAllowed("FormSelection", props.configuration)) {
            this.props.redirectTo(
                LinkService.getPathForContainer(props.language.locale, "ProductSelection")
            );
        }
    }


    handleChangeForm = (newValue) => {
        this.props.changeSelectedAttribute({
            attribute: "form",
            value: newValue,
        });
        this.props.redirectTo(
            LinkService.getPathForContainer(this.props.language.locale, "LineInput")
        );
    };


    render() {

        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;

        return (
            <Screen>

                <Legend>{i18n.t('title.form')} <Tooltip mainLink={"https://www.lichtwerk.de"} secLink={"https://www.lichtwerk.de"}>Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Aenean eu quam. Vestibulum id ligula porta felis semper.</Tooltip></Legend>

                <SelectionRow>

                    <SelectForm label={i18n.t('value.form.Linear')} image={ImageLinear}
                                 active={attributes.form === "Linear"}
                                 onClick={() => this.handleChangeForm("Linear")}/>
                    {attributes.mountingType === "Pendel-Lichtbandmontage" && <>
                        <SelectForm label={i18n.t('value.form.L-Form')} image={ImageLForm} flipX={true} flipY={true}
                                     active={attributes.form === "L-Form"}
                                     onClick={() => this.handleChangeForm("L-Form")}/>
                        <SelectForm label={i18n.t('value.form.U-Form')} image={ImageUForm} flipY={true}
                                     active={attributes.form === "U-Form"}
                                     onClick={() => this.handleChangeForm("U-Form")}/>
                        <SelectForm label={i18n.t('value.form.S-Form')} image={ImageSForm} flipX={true}s
                                     active={attributes.form === "S-Form"}
                                     onClick={() => this.handleChangeForm("S-Form")}/>
                        <SelectForm label={i18n.t('value.form.Karree')} image={ImageKaree}
                                     active={attributes.form === "Karree"}
                                     onClick={() => this.handleChangeForm("Karree")}/>
                    </>}

                    <SelectForm label={<>{i18n.t('form.otherForm')} <Tooltip languageKey={"otherForm"} /></>}
                                image={ImageOctagonForm} onClick={() => this.props.showInquiry()}
                    />

                </SelectionRow>

            </Screen>
        )
    }
}

const SelectionRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export default connect(mapStateToProps, mapDispatchToProps)(FormSelection);

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from "styled-components";
import i18n from "i18n-js";

import Backlink from "../components/Backlink";
import Legend from "../components/Legend";
import Tooltip from "../components/Tooltip";
import Screen from "../components/Screen";

import Karree from "./LineInput/Karree";
import Linear from "./LineInput/Linear";
import LForm from "./LineInput/LForm";
import UForm from "./LineInput/UForm";
import SForm from "./LineInput/SForm";

import * as ActionTypes from "../actions";
import * as LinkService from "../services/LinkService";

import IconFlipX from "../assets/Icons/flipX.svg";
import IconFlipY from "../assets/Icons/flipY.svg";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        changeLayoutFlipX: (params) => dispatch(ActionTypes.changeLayoutFlipX(params)),
        changeLayoutFlipY: (params) => dispatch(ActionTypes.changeLayoutFlipY(params)),
        changeLayoutRotation: (params) => dispatch(ActionTypes.changeLayoutRotation(params)),
    };
};

class LineInput extends Component {

    constructor(props) {
        super(props);

        if (!LinkService.isContainerAllowed("LineInput", props.configuration)) {
            this.props.redirectTo(
                LinkService.getPathForContainer(props.language.locale, "FormSelection")
            );
        }
    }


    onFlipX = () => {
        this.props.changeLayoutFlipX(
            !this.props.configuration.layout.flipX
        );
    };

    onFlipY = () => {
        this.props.changeLayoutFlipY(
            !this.props.configuration.layout.flipY
        );
    };

    onRotateRight = () => {
        this.props.changeLayoutRotation(
            (this.props.configuration.layout.rotation + 90) % 360
        );
    };

    onRotateLeft = () => {
        this.props.changeLayoutRotation(
            (this.props.configuration.layout.rotation - 90) + (this.props.configuration.layout.rotation - 90 < 0 ? 360 : 0)
        );
    };

    redirectBack = (newValue) => {
        this.props.redirectTo(
            LinkService.getPathForContainer(this.props.language.locale, "FormSelection")
        );
    };

    render() {

        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;

        return (
            <Screen>

                <div>
                    <Backlink onClick={() => this.redirectBack()}>{i18n.t('action.back')}</Backlink>
                </div>

                <FormSelectionContainer>
                    <Legend>{i18n.t('title.dimensions')} <Tooltip languageKey={"lineInput"} /></Legend>

                    {attributes.form === "Linear" && <Linear />}
                    {attributes.form === "L-Form" && <LForm />}
                    {attributes.form === "U-Form" && <UForm />}
                    {attributes.form === "S-Form" && <SForm />}
                    {attributes.form === "Karree" && <Karree />}

                    <Controls visible={configuration.result.isValid && attributes.form !== "Karree"}>
                        <Control onClick={() => this.onFlipX()}>
                            <IconCustom src={IconFlipX} />
                            <ControlLabel>{i18n.t('action.flipX')}</ControlLabel>
                        </Control>
                        <Control onClick={() => this.onFlipY()}>
                            <IconCustom src={IconFlipY} />
                            <ControlLabel>{i18n.t('action.flipY')}</ControlLabel>
                        </Control>
                        <Control onClick={() => this.onRotateLeft()}>
                            <FontAwesomeWrap>
                                <FontAwesomeIcon color={"#DDDDDD"} icon={["fal", "undo"]} />
                            </FontAwesomeWrap>
                            <ControlLabel>{i18n.t('action.rotateLeft')}</ControlLabel>
                        </Control>
                        <Control onClick={() => this.onRotateRight()}>
                            <FontAwesomeWrap>
                                <FontAwesomeIcon color={"#DDDDDD"} icon={["fal", "redo"]} />
                            </FontAwesomeWrap>
                            <ControlLabel>{i18n.t('action.rotateRight')}</ControlLabel>
                        </Control>
                    </Controls>

                </FormSelectionContainer>

            </Screen>
        )
    }
}

const FormSelectionContainer = styled.div`
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
`;
const Control = styled.div`
    margin: 0 10px;
    padding: 10px;
    display: flex;
    align-items: center;
`;
const Controls = styled.div`
    position: sticky;
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 10px;
    margin: 30px auto 0;
    opacity: ${props => props.visible ? 1 : 0};
    ${Control} {
        pointer-events: ${props => props.visible ? "all" : "none"};
        cursor: ${props => props.visible ? "pointer" : "default"};
    }
`;
const ControlLabel = styled.span`
    display: inline-block;
    margin-left: 10px;
    color: #999999;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;

    ${Control}:hover & {
        color: #E45200;
    }
`;
const IconCustom = styled.img`
    width: 16px;
    height: 16px;
    object-fit: contain;
    object-position: center;
`;
const FontAwesomeWrap = styled.span`
    font-size: 16px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(LineInput);

import React, { Component } from 'react';
import styled from 'styled-components';
import i18n from "i18n-js";

import TooltipTrigger from 'react-popper-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Tooltip extends Component {

    render() {
        const {
            icon,
            showBelow,
            ...props
        } = this.props;

        let {
            languageKey,
        } = this.props;

        if (!languageKey) {
            languageKey = "demo";
        }

        const TooltipContent = ({arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement}) => (
            <Element {...getTooltipProps({
                ref: tooltipRef,
                className: 'tooltip-container'
            })}>
                {showBelow && <TooltipArrow {...getArrowProps({
                    ref: arrowRef,
                    'data-placement': placement
                })} top />}
                <Content>
                    <Text>{i18n.t('infobox.' + languageKey + '.content')}</Text>
                    {(i18n.t('infobox.' + languageKey + '.mainLink') || i18n.t('infobox.' + languageKey + '.secLink')) && (
                        <ContentControls>
                            <div>
                                {i18n.t('infobox.' + languageKey + '.secLink') &&
                                    <SecLink href={i18n.t('infobox.' + languageKey + '.secLink')} target={"_BLANK"}>{i18n.t('infobox.' + languageKey + '.secLinkLabel')}</SecLink>
                                }
                            </div>
                            <div>
                                {i18n.t('infobox.' + languageKey + '.mainLink') &&
                                    <MainLink href={i18n.t('infobox.' + languageKey + '.mainLink')} target={"_BLANK"}>{i18n.t('infobox.' + languageKey + '.mainLinkLabel')}</MainLink>
                                }
                            </div>
                        </ContentControls>
                    )}
                </Content>
                {!showBelow && <TooltipArrow {...getArrowProps({
                        ref: arrowRef,
                        'data-placement': placement
                    })} />
                }
            </Element>
        );
        const Trigger = ({getTriggerProps, triggerRef}) => (
            <Icon {...getTriggerProps({
                ref: triggerRef,
            })} onClick={(e) => e.stopPropagation()}>
                <FontAwesomeIcon icon={icon ? icon : ['fal', 'info-circle']} />
            </Icon>
        );

        return (
            <>
                {i18n.t('infobox.' + languageKey + '.content') &&
                    <Container {...props}>

                        <TooltipTrigger placement={showBelow ? "bottom-start" : "top-start"} trigger="hover" /*tooltipShown={true}*/ tooltip={TooltipContent}>
                            {Trigger}
                        </TooltipTrigger>

                    </Container>
                }
            </>
        );
    }

}


const Container = styled.span`
    position: relative;
`;

const Element = styled.div`
    z-index: 2000; 
    width: 100vw;
    max-width: 280px;
    display: flex;
    flex-direction: column;
    //transition: opacity .3s ease-in;
`;
const Content = styled.div` 
    font-size: 12px;
    line-height: 17px;
    color: #ECECEC;
    background-color: #343434;
    padding: 14px 15px;
    margin-left: -20px;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, .3);
`;
const Text = styled.div`
    white-space: pre-line;
`;
const ContentControls = styled.div` 
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const MainLink = styled.a`
    color: #ECECEC;	
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif; 
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
`;
const SecLink = styled.a`
    color: #999999;
    font-size: 12px;
    line-height: 23px;
`;
const TooltipArrow = styled.div`
  height: 16px;
  position: relative;
  width: 20px;
  margin-bottom: 0;
  ::after {
      content: '';
      position: absolute;
      margin: auto;
      border-style: solid;
  }
  ::after {
      ${props => !props.top && "top: 0;"}
      ${props => props.top && "bottom: 0;"}
      border-color: ${props => props.top ? "transparent transparent #343434 transparent" : "#343434 transparent transparent transparent"};
      border-width: ${props => props.top ? "0 10px 10px 10px" : "10px 10px 0 10px"};
  }
`;

const Icon = styled.span`
    color: #999999;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
`;


export default Tooltip;

import React, { PureComponent } from 'react';

import WebsiteImage from './WebsiteImage';

import styled from 'styled-components';


class SelectImage extends PureComponent {

    render() {

        const {
            imageName,
            label,
            active,
            small,
            ...props
        } = this.props;


        const Container = styled.div`
            position: relative;
            width: ${small ? 220 : 280 }px;
            margin: 0 5px 5px;
            border: 1px solid ${active ? "#e45200" : "transparent"};
            cursor: pointer;
            :hover {
                border-color: #e45200;
            }
        `;
        const Wrap = styled.div`
            position: relative;
            padding-bottom: 66.666%;
        `;
        const Inner = styled.div`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        `;
        const Label = styled.div`
            position: absolute;
            top: 10px;
            left: 12px;
            right: 12px;
            font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
            font-size: ${small ? 12 : 16}px;
            line-height: ${small ? 14 : 19}px;
            text-transform: uppercase;
            color: ${active ? "#e45200" : "#444444"};
            ${Container}:hover & {
                color: #e45200;
            }
        `;


        return (
            <Container {...props}>
                <Wrap>
                    <Inner>

                        <WebsiteImage name={imageName} absolute={true} />
                        <Label>{label}</Label>

                    </Inner>
                </Wrap>
            </Container>
        );
    }

}


export default SelectImage;

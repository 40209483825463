import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from "styled-components";
import i18n from "i18n-js";
import _ from 'lodash';

import * as ActionTypes from "../actions";
import * as LinkService from "../services/LinkService";
import * as ProductService from "../services/ProductService";
import * as ItemService from "../services/ItemService";

import Controls from "./Configurator/Controls";

import Legend from "../components/Legend";

import Canvas from "../components/Canvas";
import Screen from "../components/Screen";
import Modal from "../components/Modal";
import SelectImage from "../components/SelectImage";
import TextLink from "../components/TextLink";
import Input from "../components/Input";
import Label from "../components/Label";
import Button from "../components/Button";
import SpotSelector from "./Configurator/SpotSelector";
import Tooltip from "../components/Tooltip";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        changeItems: (params) => dispatch(ActionTypes.changeItems(params)),
        changeMeta: (params) => dispatch(ActionTypes.changeMeta(params)),
    };
};

class Configurator extends Component {

    constructor(props) {
        super(props);

        if (!LinkService.isContainerAllowed("Configurator", props.configuration)) {
            this.props.redirectTo(
                LinkService.getPathForContainer(props.language.locale, "LineInput")
            );
        }

        this.state = {
            selectedItem: "1",
            showChangeModal: false,
            selectedChangeModalAttributes: {},
            changeModalMode: null,
            showMetaModal: false,
            metaProjectName: "",
            metaSupervisor: "",
        };

    }

    changeValue(nextValue) {
        this.setState({value: nextValue})
    }

    changeMetaValue(name, value) {
        let newState = {...this.state};
        newState[name] = value.trim();
        this.setState(newState);
    }

    redirectToResult(applyMetaValues) {
        if (applyMetaValues) {
            this.props.changeMeta({
                name: "projectName",
                value: this.state.metaProjectName,
            });
            this.props.changeMeta({
                name: "supervisor",
                value: this.state.metaSupervisor,
            });
        }
        this.props.redirectTo(
            LinkService.getPathForContainer(this.props.language.locale, "Result")
        );
    }

    findAllCorners() {
        const {items} = this.props.configuration;
        let indexes = [];

        items.forEach((item, index) => {
            if (item.meta.type === "corner") {
                indexes.push(index);
            }
        });

        return indexes;
    }

    findSymmetryItem(key) {
        const {items} = this.props.configuration;
        let concatenatedItems = items.concat(items);
        concatenatedItems.splice(items.length, 1); // remove duplicate inline supply in the middle
        const itemsCountHalf = (items.length - 1) / 2;

        const type = items[key].meta.type;
        let symmetryItemKey = 0;

        if (type === "line") {
            let distance = 0;
            let cornersWalked = 0;

            concatenatedItems.forEach((item, index) => {
                if (index >= key) {
                    if (item.meta.type === "corner") {
                        cornersWalked++;
                    }
                    if (cornersWalked === 2) {
                        symmetryItemKey = (index + distance) % (items.length - 1);
                        cornersWalked++;
                    }
                    if (cornersWalked === 0) {
                        distance++;
                    }
                }
            });
        }

        if (type === "corner") {
            if (Number.parseInt(key) < itemsCountHalf) {
                symmetryItemKey = Number.parseInt(key) + itemsCountHalf;
            } else {
                symmetryItemKey = Number.parseInt(key) - itemsCountHalf;
            }
        }

        return symmetryItemKey;
    }

    handleSelectItem = (key) => {
        const {selectedItem} = this.state;

        if (key !== selectedItem) {
            this.setState({selectedItem: key});
        } else {
            this.setState({selectedItem: null, showChangeModal: false});
        }
    };

    handleRemoveItem = (key) => {
        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;
        let {items} = {...configuration};

        if (attributes.form === "Karree") {
            let indexes;
            const symmetryItemKey = this.findSymmetryItem(key);

            if (symmetryItemKey < key) {
                indexes = [key, symmetryItemKey];
            } else {
                indexes = [symmetryItemKey, key];
            }

            indexes.forEach((e, i) => {
                items.splice(e, 1);
            });
        } else {
            items.splice(Number.parseInt(key), 1);
        }

        items = ProductService.recalculatePositions(items);
        this.props.changeItems(items);

        this.setState({selectedItem: null});
    };

    handleShowChangeModalDialog = (key) => {
        let {items} = {...this.props.configuration},
            selectedItem = items[key];

        let selectedChangeModalAttributes = [];
        selectedChangeModalAttributes["opticsSimple"] = selectedItem.article.opticsSimple;
        selectedChangeModalAttributes["length"] = selectedItem.article.length;
        selectedChangeModalAttributes["spots"] = selectedItem.meta?.spots ?? {};

        this.setState({selectedItem: key, showChangeModal: true, selectedChangeModalAttributes: selectedChangeModalAttributes, changeModalMode: 'replace'});
    };

    handleHideChangeModalDialog = () => {
        this.setState({selectedItem: null, showChangeModal: false, selectedChangeModalAttributes: {}, changeModalMode: 'replace'});
    };

    getIndexesOfChangeItems = key => {

        if (!key) {
            return [];
        }
        key = Number.parseInt(key);

        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;
        let {items} = {...configuration};
        const {selectedChangeModalAttributes} = this.state;

        let indexes = [key];

        if (attributes.form === "Karree") {

            if (items[key].meta.type === "corner") {
                indexes = this.findAllCorners();
            }
            else {
                indexes.push(this.findSymmetryItem(key));
            }
        }

        return indexes;
    };

    getDefaultItem = specialItem => {
        const {configuration} = this.props, selectedAttributes = configuration.selectedAttributes;
        if (specialItem.opticsSimple === selectedAttributes.opticsSimple) {
            return specialItem;
        }

        let lines = ProductService.filterProducts(this.props.products.lines, [
            {
                field: "opticsSimple",
                value: selectedAttributes.opticsSimple,
            }, {
                field: "mountingType",
                value: selectedAttributes.mountingType,
            }, {
                field: "dimmable",
                value: selectedAttributes.dimmable,
            }, {
                field: "output",
                value: selectedAttributes.output,
            }, {
                field: "color",
                value: selectedAttributes.color,
            }, {
                field: "length",
                value: specialItem.length,
            }
        ]);

        return lines[0] ? lines[0] : false;

    };

    handleChangeItem = (key, newItem) => {
        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;
        let {items} = {...configuration};
        const {selectedChangeModalAttributes} = this.state;

        if (attributes.form === "Karree") {
            let indexes;

            if (items[key].meta.type === "corner") {
                indexes = this.findAllCorners();
                indexes.forEach((e, i) => {
                    items[e].meta.spots = {};
                    items[e].article = newItem;
                });
            }
            else {
                const defaultItem = this.getDefaultItem(newItem),
                    symmetryItemKey = this.findSymmetryItem(key),
                    symmetryItem = items[symmetryItemKey];

                if (symmetryItem.article.length !== newItem.length) {
                    items[symmetryItemKey].article = defaultItem;
                    items[symmetryItemKey].meta.spots = {};
                }
                else if (symmetryItem.article.opticsSimple === defaultItem.opticsSimple) {
                    items[symmetryItemKey].article = defaultItem;
                    items[symmetryItemKey].meta.spots = {};
                }
                items[key].article = newItem;
                items[key].meta.spots = newItem.isLuminaireWithPowerRail ? selectedChangeModalAttributes.spots : {};
            }

        } else {
            items[key].meta.spots = newItem.isLuminaireWithPowerRail ? selectedChangeModalAttributes.spots : {};
            items[key].article = newItem;
        }

        items = ProductService.recalculatePositions(items);
        this.props.changeItems(items);

        this.setState({showChangeModal: false, selectedChangeModalAttributes: {}, changeModalMode: null});
    };

    handleInsertItem = (key, item, position) => {
        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;
        const {selectedChangeModalAttributes} = this.state;
        let {items} = {...configuration}, indexes = false,
            newItemKey = Number.parseInt(key);

        let index = position === 'before' ?  Number.parseInt(key) : Number.parseInt(key) + 1;

        if (attributes.form === "Karree") {
            let symmetryItemKey = this.findSymmetryItem(key),
                defaultItem = this.getDefaultItem(item),
                articles;

            if (position === 'before') {
                symmetryItemKey += 1
            }
            else {
                newItemKey += 1;
            }
            if (symmetryItemKey < key) {
                indexes = [newItemKey, symmetryItemKey];
                articles = [Number.parseInt(key), symmetryItemKey];
            } else {
                indexes = [symmetryItemKey, newItemKey];
                articles = [symmetryItemKey, Number.parseInt(key)];
            }

            indexes.forEach((e, i) => {
                let originalItem = _.cloneDeep(items[articles[i]]),
                    newItem = ProductService.getFlatItem(defaultItem, "line", 0, 0, originalItem.meta.orientation, {});
                if (e !== symmetryItemKey) {
                    newItem.article = item;
                    newItem.meta.spots = item.isLuminaireWithPowerRail ? selectedChangeModalAttributes.spots : {};
                }

                items.splice(e, 0, newItem);
            });
        } else {
            let newItem = _.cloneDeep(items[key]);
            newItem.article = item;
            newItem.meta.spots = item.isLuminaireWithPowerRail ? selectedChangeModalAttributes.spots : {};

            items.splice(index, 0, newItem);
        }

        items = ProductService.recalculatePositions(items);
        this.props.changeItems(items);

        this.setState({selectedItem: indexes ? newItemKey : index, showChangeModal: false, selectedChangeModalAttributes: {}, changeModalMode: null});
    };

    handleShowInsertModalDialog = (key, position) => {
        this.setState({selectedItem: key, showChangeModal: true, selectedChangeModalAttributes: {
                "spots": {}
            }, changeModalMode: position});
    };

    handleChangeSelectedChangeModalAttribute = (key, value) => {
        let {selectedChangeModalAttributes} = this.state;
        selectedChangeModalAttributes[key] = value;
        this.setState({selectedChangeModalAttributes: selectedChangeModalAttributes});
    };

    handleChangeSpot = (spot, count) => {
        const {configuration} = this.props;
        const {items} = configuration;
        const {selectedChangeModalAttributes} = this.state;

        let spots = selectedChangeModalAttributes.spots;

        if (count === 0) {
            delete spots[spot.articleNumber];
        }
        else {
            if (spots[spot.articleNumber]) {
                spots[spot.articleNumber].meta.qty = count;
            }
            else {
                spots[spot.articleNumber] = {
                    article: spot,
                    meta: {
                        qty: count,
                    }
                }
            }
        }

        this.handleChangeSelectedChangeModalAttribute("spots", spots);
    };

    renderChangeModal() {
        const {configuration, products} = this.props;
        const {items, selectedAttributes} = configuration;
        const {selectedItem, selectedChangeModalAttributes, changeModalMode} = this.state;

        const item = items[selectedItem];

        let itemsToFilter = products.lines;
        let itemFilter = [
            {
                field: "mountingType",
                value: selectedAttributes.mountingType,
            }, {
                field: "dimmable",
                value: selectedAttributes.dimmable,
            }, {
                field: "output",
                value: selectedAttributes.output,
            }, {
                field: "color",
                value: selectedAttributes.color,
            }
        ];

        if (item.meta.type === "corner" && changeModalMode === 'replace') {
            itemsToFilter = products.corners;
            itemFilter.push({
                    field: "type",
                    value: "Ecke " + (item.meta.direction === "left" ? "links" : "rechts"),
                }
            );
        }

        const alternativeItems = ProductService.filterProducts(itemsToFilter, itemFilter);

        let optics = {};
        alternativeItems.forEach(function (product) {
            const value = product["opticsSimple"];
            if (value && !optics[value]) {
                optics[value] = {
                    value: value,
                    image: ProductService.getImageLink(product, false),
                    label: <>{i18n.t('value.opticsSimple.' + value)} <Tooltip languageKey={'productSelection.' + value} /></>
                };
            }
        });

        const lengths = ProductService.getIndividualsOfField(alternativeItems, 'length', [
            {
                field: "opticsSimple",
                value: selectedChangeModalAttributes.opticsSimple,
            }
        ]);
        lengths.sort(function(a, b) {
            return a - b;
        });

        if (lengths.length === 1 && lengths[0] !== selectedChangeModalAttributes.length) {
            this.handleChangeSelectedChangeModalAttribute('length', lengths[0]);
        }

        const resultingItems = ProductService.filterProducts(alternativeItems, [
            {
                field: "opticsSimple",
                value: selectedChangeModalAttributes.opticsSimple
            },
            {
                field: "length",
                value: selectedChangeModalAttributes.length
            }
        ]);

        let availableSpots = null, rail = null,
            usedRailLength = ItemService.getCompleteRequiredSpotLength(selectedChangeModalAttributes.spots ?? {}),
            usedRailWeight = ItemService.getCompleteRequiredSpotWeight(selectedChangeModalAttributes.spots ?? {});
        if (selectedChangeModalAttributes.opticsSimple === "Stromschiene") {
            rail = resultingItems[0];

            availableSpots = ProductService.filterProducts(products.railMounts, [
                {
                    field: "dimmable",
                    value: selectedAttributes.dimmable,
                }, {
                    field: "color",
                    value: selectedAttributes.color,
                }
            ]);


        }

        const getRailIllustrationContent = (spot) => {
            let content = [];
            for (let i = 0; i < spot.meta.qty; i++) {
                content.push(<RailIllustrationItem style={{width: spot.article.requiresLength / 4}} />   );
            }
            return content;
        };
        return (
            <Modal isVisible={true}>
                {optics && (
                    <div>
                        <Legend>{i18n.t('title.lightEngine')}</Legend>
                        <SelectionRow>
                            {Object.entries(optics).map(([value, optic]) => (
                                <SelectImage small label={optic.label} imageName={optic.image} key={optic.value}
                                             active={selectedChangeModalAttributes.opticsSimple === optic.value}
                                             onClick={() => this.handleChangeSelectedChangeModalAttribute('opticsSimple', optic.value)}
                                />
                            ))}
                        </SelectionRow>
                    </div>
                )}
                {selectedChangeModalAttributes.opticsSimple && lengths && (
                    <div>
                        <Legend>{i18n.t('title.lengthMeasure')}</Legend>
                        {lengths.map((value) => (
                            <OptionItem active={selectedChangeModalAttributes.length === value} onClick={() => this.handleChangeSelectedChangeModalAttribute('length', value)}>{value} mm</OptionItem>
                        ))}
                    </div>
                )}


                {availableSpots && rail && (
                    <div style={{marginTop: 60}}>
                        <Legend>{i18n.t('title.spotSelection')}</Legend>
                        <div style={{marginBottom: 20, display: "flex", alignItems: "center"}}>
                            <RailIllustrationElement style={{width: rail.length / 4}}>
                                <RailIllustration style={{width: rail.usableLength / 4}}>
                                    {Object.entries(selectedChangeModalAttributes.spots).map(([articleNumber, spot]) => (
                                        getRailIllustrationContent(spot)
                                    ))}
                                </RailIllustration>
                            </RailIllustrationElement>
                            <div>{usedRailLength} / {rail.usableLength} mm, <NumberFormat value={usedRailWeight} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} suffix={" kg"} /> / <NumberFormat value={rail.maxWeight} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} suffix={" kg"} /></div>
                        </div>
                        <div>
                            {availableSpots.map(spot => (
                                <SpotSelector spot={spot}
                                              onCountChange={(spot, count) => this.handleChangeSpot(spot, count)}
                                              isIncreaseAllowed={usedRailLength + spot.requiresLength < rail.usableLength && usedRailWeight + spot.weight < rail.maxWeight}
                                              count={selectedChangeModalAttributes.spots[spot.articleNumber]?.meta.qty ?? 0} />
                            ))}
                        </div>
                    </div>
                )}


                <hr style={{margin: "20px 0"}} />
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px"}}>
                    <div>
                        {(resultingItems && resultingItems.length === 1) && (
                            <>
                                <strong>{resultingItems[0].name}</strong><br />
                                <ModalArtNo>{i18n.t('title.articleNumberShort')} {resultingItems[0].articleNumber}</ModalArtNo>
                            </>
                        )}
                    </div>
                    <div>
                        <TextLink onClick={this.handleHideChangeModalDialog} label={i18n.t('action.cancel')} />
                        {changeModalMode === 'replace' ? (
                            <TextLink onClick={() => this.handleChangeItem(this.state.selectedItem, resultingItems[0])} label={i18n.t('action.apply')} primary disabled={!resultingItems || resultingItems.length !== 1} />
                        ) : (
                            <TextLink onClick={() => this.handleInsertItem(this.state.selectedItem, resultingItems[0], changeModalMode)} label={i18n.t('action.insert')} primary disabled={!resultingItems || resultingItems.length !== 1} />
                        )}
                    </div>
                </div>
            </Modal>
        );
    }

    render() {
        const {configuration} = this.props;
        const {showChangeModal, showMetaModal, selectedItem} = this.state;

        return (
            <Screen beforeNextFunction={() => {
                if (configuration.meta.supervisor === "" && configuration.meta.projectName === "") {
                    this.setState({...this.state, showMetaModal: true});
                    return false;
                }
                return true;
            }}>

                <Controls />

                {showChangeModal && this.renderChangeModal()}


                <Modal isVisible={showMetaModal} small>

                    <ModalClose onClick={() => this.setState({...this.state, showMetaModal: false})}>
                        <FontAwesomeIcon icon={["fal", "times"]} />
                    </ModalClose>

                    <Legend style={{marginBottom: 10}}>{i18n.t("configurator.saveTitle")}</Legend>
                    <p style={{margin: "0 auto 30px", color: "#999999"}}>{i18n.t("configurator.saveInfo")}</p>

                    <Label>{i18n.t("field.projectName.title")}</Label>
                    <Input placeholder={i18n.t("field.projectName.placeholder")} onInput={(event) => this.changeMetaValue("metaProjectName", event.target.value)} />

                    <Label>{i18n.t("field.supervisor.title")}</Label>
                    <Input placeholder={i18n.t("field.supervisor.placeholder")} onInput={(event) => this.changeMetaValue("metaSupervisor", event.target.value)} />

                    <div style={{textAlign: "right", marginTop: 30}}>
                        <TextLink light label={i18n.t("action.saveWithoutInfo")} onClick={() => this.redirectToResult()} />
                        <Button primary filled wide label={i18n.t("action.save")} onClick={() => this.redirectToResult(true)} style={{marginBottom: 0}} />
                    </div>

                </Modal>

                <CanvasContainer>
                    <Canvas controls={true} height={"100%"} showDimensions={true}
                        selectedItem={selectedItem} activeItems={this.getIndexesOfChangeItems(selectedItem)} items={configuration.items}
                        color={configuration.selectedAttributes.color} mountingType={configuration.selectedAttributes.mountingType}
                        handleItemSelect={(key) => this.handleSelectItem(key)}
                        handleItemRemove={(key) => this.handleRemoveItem(key)}
                        handleItemChange={(key) => this.handleShowChangeModalDialog(key)}
                        handleItemInsert={(key, position) => this.handleShowInsertModalDialog(key, position)}
                        handleItemClose={() => this.setState({selectedItem: null, showChangeModal: false})}
                    />
                </CanvasContainer>

            </Screen>
        )
    }
}

const CanvasContainer = styled.div`
    position: relative;
    flex: 1 0 0;
    margin: 0 0 20px;
`;
const ModalClose = styled.div`
    float: right;
    padding: 20px;
    margin: -20px -20px 0 0;
    cursor: pointer;
    color: #999999;
    font-size: 20px;
    :hover {
        color: #444444;
    }
`;
const ModalArtNo = styled.span `
    font-size: 12px;
    line-height: 14px;
    color: #444444;
`;
const OptionItem = styled.div`
    display: inline-block;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid ${props => props.active ? "#e45200" : "#DDDDDD"};
    color: ${props => props.active ? "#e45200" : "#999999"};
    margin: 0 2px 4px 0;
    cursor: pointer;
    
    :hover {
        color: #E45200;
        border-color: #E45200;
    }
`;
const RailIllustrationElement = styled.div`
    position: relative;
    margin-right: 20px;
    padding: 3px 0;
    box-sizing: border-box;
    border: 1px rgba(153, 153, 153, 0.8) solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;
const RailIllustration = styled.div`
    position: relative;
    height: 20px;
    padding: 3px;
    box-sizing: border-box;
    border: 1px rgba(153, 153, 153, 0.5) dashed;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;
const RailIllustrationItem = styled.div`
    height: 100%;
    border: 1px solid #999999;
    box-sizing: border-box;
`;

const SelectionRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 60px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Configurator);

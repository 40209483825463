import React, { Component } from 'react';
import styled from 'styled-components';

import TooltipTrigger from 'react-popper-tooltip';


class SpotSelectorTooltip extends Component {

    render() {
        const {
            children,
            content,
            ...props
        } = this.props;

        const TooltipContent = ({arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement}) => (
            <Element {...getTooltipProps({
                ref: tooltipRef,
                className: 'tooltip-container'
            })}>
                <Content>{content}</Content>
                <TooltipArrow {...getArrowProps({
                    ref: arrowRef,
                    'data-placement': placement
                })} />
            </Element>
        );
        const Trigger = ({getTriggerProps, triggerRef}) => (
            <div {...getTriggerProps({
                ref: triggerRef,
            })}>
                {children}
            </div>
        );

        return (
            <Container {...props}>
                <TooltipTrigger placement="top-start" trigger="hover" tooltip={TooltipContent}>
                    {Trigger}
                </TooltipTrigger>
            </Container>
        );
    }

}


const Container = styled.span`
    position: relative;
`;

const Element = styled.div`
    width: auto;
    max-width: 200px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    //transition: opacity .3s ease-in;
`;
const Content = styled.div` 
    width: 100%;
    font-size: 12px;
    line-height: 17px;
    color: #ECECEC;
    background-color: #343434;
    padding: 14px 15px;
    margin-left: -20px;
    box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, .3);
`;
const TooltipArrow = styled.div`
  height: 16px;
  position: relative;
  width: 20px;
  margin-bottom: 0;
  ::after {
      content: '';
      position: absolute;
      top: 0;
      margin: auto;
      border-style: solid;
      border-color: #343434 transparent transparent transparent;
      border-width: 10px 10px 0 10px;
  }
`;


export default SpotSelectorTooltip;

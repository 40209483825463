import * as ProductService from '../services/ProductService'

export const LOAD_CONFIGURATION_REQUEST = 'LOAD_CONFIGURATION_REQUEST';
export const LOAD_CONFIGURATION_SUCCESS = 'LOAD_CONFIGURATION_SUCCESS';
export const LOAD_CONFIGURATION_FAILURE = 'LOAD_CONFIGURATION_FAILURE';

export function loadConfigurationRequest() {
    return {
        type: LOAD_CONFIGURATION_REQUEST,
    }
}

export function loadConfigurationSuccess(payload) {
    console.log("invoke success");
    return {
        type: LOAD_CONFIGURATION_SUCCESS,
        payload
    }
}

export function loadConfigurationFailure(payload) {
    console.log("invoke failure");
    return {
        type: LOAD_CONFIGURATION_FAILURE,
        payload
    }
}

export function loadConfiguration(configurationKey) {
    return (dispatch, getState) => {
        const { products } = getState();

        if (!products?.products) {
            dispatch(loadConfigurationFailure(null));
            return;
        }

        dispatch(loadConfigurationRequest());

        ProductService.loadConfiguration(configurationKey)
            .then(response => {

                if (response?.error === false && response?.configuration) {

                    let configuration = ProductService.migrateConfiguration(
                        JSON.parse(response.configuration),
                        products.products
                    );

                    dispatch(loadConfigurationSuccess({
                        configurationKey: configurationKey,
                        configuration: configuration,
                        products: products,
                    }));
                }
                else {
                    dispatch(loadConfigurationFailure(null));
                }

            });
            // .catch(error => {
            //     console.log("False2");
            //     dispatch(loadConfigurationFailure(error));
            // });
    }
}

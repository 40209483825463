import React, { Component } from 'react';

import styled from 'styled-components';


import * as SvgService from '../../services/SvgService';
import ItemModal from "./ItemModal";

class Line extends Component {

    handleOnClick = (event) => {
        if (this.props.onClick) {
            this.props.onClick(this.props.itemKey, event);
        }
    };

    handleOnRemove = () => {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.itemKey);
        }
    };

    handleOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.props.itemKey);
        }
    };

    handleOnInsert = (position) => {
        if (this.props.onInsert) {
            this.props.onInsert(this.props.itemKey, position);
        }
    };

    handleOnClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const {
            item,
            stroke,
            article,
            spotlightCount,
            showRemove,
            active,
            orientation,
            x,
            y,
            length,
            width,
            isLast,
            onClick,
        } = this.props;

        let rotation = (orientation - 90 + 360) % 360;
        let translation;

        let isVertical = false;

        switch (rotation) {
            case 90:
                translation = "0, -"+width;
                isVertical = true;
                break;
            case 180:
                translation = "-"+length+", -"+width;
                break;
            case 270:
                translation = "-"+length+", 0";
                isVertical = true;
                break;
            default:
                translation = "0, 0";
        }

        let labelTransformation = "";
        if (rotation === 90 || rotation === 180) {
            labelTransformation = "rotate(180, " + (length * .5) + ", " + (width * 4) + ")";
        }

        let spotRotation = 360 - rotation, spotX = 0, spotY = 0;
        switch (spotRotation) {
            case 90:
                spotX = -20;
                spotY = -20;
                break;
            case 180:
                spotX = -20;
                break;
            case 270:

                break;
            default:
                spotY = -20;
        }

        return (
            <g transform={"translate(" + x + ", " + y + ")"} width={length} height={length}>

                <g transform={"rotate("+rotation+") translate("+translation+")"} style={{cursor: onClick && "pointer"}}>
                    <rect
                        onClick={this.handleOnClick}
                        x={0}
                        y={0}
                        width={length}
                        height={width}
                        fill="#ffffff"
                        stroke={stroke} />

                    {/* suspension for pendulum mount  */}
                    {article.mountingType === "Pendel-Lichtbandmontage" && <>
                        <line
                            x1={width * 1.25}
                            y1={width * .1}
                            x2={width * 1.25}
                            y2={width * .9}
                            stroke={stroke}
                            stroke-opacity=".5"
                            />
                        <path
                            d={SvgService.describeArc(0, width * .5, width * .2, 0, 180)}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />

                        <line
                            x1={length - width * 1.25}
                            y1={width * .1}
                            x2={length - width * 1.25}
                            y2={width * .9}
                            stroke={stroke}
                            stroke-opacity=".5"
                            />
                        {isLast ?
                            <circle
                                cx={length - (width * .8)}
                                cy={width * .5}
                                r={width * .2}
                                fill="transparent"
                                stroke={stroke}
                                stroke-opacity=".5"
                            /> :
                            <path
                                d={SvgService.describeArc(length, width * .5, width * .2, 180, 360)}
                                fill="transparent"
                                stroke={stroke}
                                stroke-opacity=".5"
                            />
                        }
                    </>}

                    {/* wall mounts */}
                    {article.mountingType === "Wand-Lichtbandmontage" && <>
                        <rect onClick={this.handleOnClick}
                              x={width * 1.25}
                              y={width * -.4}
                              width={width}
                              height={width * .4}
                              fill="transparent" stroke={stroke} stroke-opacity=".5"
                        />
                        <line x1={width * 1.25} x2={width * 1.25 + width}
                              y1={width * -.35} y2={width * -.35}
                              stroke={stroke} stroke-opacity={.5}
                        />

                        <rect onClick={this.handleOnClick}
                              x={length - (width * 1.25 + width)}
                              y={width * -.4}
                              width={width}
                              height={width * .4}
                              fill="transparent" stroke={stroke} stroke-opacity=".5"
                        />
                        <line x1={length - (width * 1.25 + width)} x2={length - (width * 1.25)}
                              y1={width * -.35} y2={width * -.35}
                              stroke={stroke} stroke-opacity={.5}
                        />
                    </>}


                    {/* Accessories by optics */}
                    {article.optics === "Stromschiene|Diffusor opal satiniert" &&
                        <rect onClick={this.handleOnClick}
                              x={width * 2}
                              y={width * .25}
                              width={length - (width * 4)}
                              height={width - width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .25 + "," + width * .25} stroke-dashoffset={width * -.125} stroke-opacity=".5"
                        />
                    }
                    {article.name.substr(0, 16) === "serio-HITES/1700" && <>
                        <rect onClick={this.handleOnClick}
                              x={length * .12}
                              y={width * .25}
                              width={length * .09}
                              height={width - width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .25 + "," + width * .25} stroke-dashoffset={width * -.1} stroke-opacity=".5"
                        />
                        <rect onClick={this.handleOnClick}
                              x={length * .455}
                              y={width * .25}
                              width={length * .09}
                              height={width - width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .25 + "," + width * .25} stroke-dashoffset={width * -.1} stroke-opacity=".5"
                        />
                        <rect onClick={this.handleOnClick}
                              x={length * .79}
                              y={width * .25}
                              width={length * .09}
                              height={width - width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .25 + "," + width * .25} stroke-dashoffset={width * -.1} stroke-opacity=".5"
                        />
                    </>}
                    {article.name.substr(0, 16) === "serio-HITES/1100" && <>
                        <rect onClick={this.handleOnClick}
                              x={length * .18}
                              y={width * .25}
                              width={length * .13}
                              height={width - width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .25 + "," + width * .25} stroke-dashoffset={width * -.1} stroke-opacity=".5"
                        />
                        <rect onClick={this.handleOnClick}
                              x={length * .69}
                              y={width * .25}
                              width={length * .13}
                              height={width - width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .25 + "," + width * .25} stroke-dashoffset={width * -.1} stroke-opacity=".5"
                        />
                    </>}
                    {article.name.substr(0, 15) === "serio-HITS/0800" && <>
                        <circle
                            cx={width * 2.5}
                            cy={width * .5}
                            r={width * .4}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <circle
                            cx={width * 2.5}
                            cy={width * .5}
                            r={width * .25}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".2"
                        />

                        <circle
                            cx={length / 2}
                            cy={width * .5}
                            r={width * .4}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <circle
                            cx={length / 2}
                            cy={width * .5}
                            r={width * .25}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".2"
                        />

                        <circle
                            cx={length - width * 2.5}
                            cy={width * .5}
                            r={width * .4}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <circle
                            cx={length - width * 2.5}
                            cy={width * .5}
                            r={width * .25}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".2"
                        />
                    </>}
                    {article.name.substr(0, 16) === "serio-HITOS/2800" && <>
                        <rect onclick={this.handleOnClick}
                              x={.05 * length}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={.25 * length}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length * .5 - width * 2.25}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length - width * 4.5 - length * .25}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length - width * 4.5 - length * .05}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                    </>}
                    {article.name.substr(0, 16) === "serio-HITOS/2200" && <>
                        <rect onclick={this.handleOnClick}
                              x={.06 * length}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={.31 * length}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length - width * 4.5 - length * .31}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length - width * 4.5 - length * .06}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                    </>}
                    {article.name.substr(0, 16) === "serio-HITOS/1700" && <>
                        <rect onclick={this.handleOnClick}
                              x={.085 * length}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length * .5 - width * 2.25}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length - width * 4.5 - length * .085}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                    </>}
                    {article.name.substr(0, 16) === "serio-HITOS/1100" && <>
                        <rect onclick={this.handleOnClick}
                              x={.12 * length}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                        <rect onclick={this.handleOnClick}
                              x={length - width * 4.5 - length * .12}
                              y={.25 * width}
                              width={width * 4.5}
                              height={width * .5}
                              fill="transparent"
                              stroke={stroke} stroke-dasharray={width * .15 + "," + width * .15}
                              stroke-dashoffset={width * -.11} stroke-opacity=".5"
                        />
                    </>}
    
    
                    {/* Lines for Length information */}
                    {article?.length && <g>
                        <line x1={0} y1={width * 2} x2={length} y2={width * 2}
                              stroke="#DDDDDD" stroke-width="1" />
                        <line x1={0} y1={width * 1.6} x2={0} y2={width * 2.4}
                              stroke="#DDDDDD" stroke-width="1" />
                        <line x1={length} y1={width * 1.6} x2={length} y2={width * 2.4}
                              stroke="#DDDDDD" stroke-width="1" />
                        <Label className={"canvas-label"} transform={labelTransformation} x={length * .5} y={width * 2.8}>{article.length}</Label>
                    </g>}
    
                    {/* Spotlight information */}
                    {spotlightCount > 0 &&
                        <g transform={"translate(" + width + ", " + width * -1 + ")"}>

                            <svg width={20} height={20} viewBox="0 0 22 24" x={spotX} y={spotY} version="1.1" xmlns="http://www.w3.org/2000/svg" style={{cursor: "default", transform: "rotate(" + spotRotation + "deg)"}}>
                                {spotlightCount === 1 &&
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Group" stroke="#8F9198">
                                            <path d="M11.2120145,0.5 L11.7119993,8.00489402 L18.0987491,11.2242787 C18.4686323,11.4107171 18.7279296,11.7294302 18.8481465,12.0940045 C18.9683634,12.4585788 18.9494999,12.8690143 18.7650445,13.2350057 L18.7650445,13.2350057 L15.3545117,19.8567465 C15.1660174,20.2227097 14.8476506,20.4785087 14.4845079,20.5964953 C14.1213653,20.7144819 13.7134468,20.6946562 13.3458491,20.5093697 L13.3458491,20.5093697 L1.32527985,14.4504417 C0.955396633,14.2640033 0.696099324,13.9452902 0.575882421,13.5807159 C0.455665518,13.2161416 0.474529022,12.8057061 0.658984396,12.4397147 L0.658984396,12.4397147 L4.06951717,5.81797385 C4.25179018,5.4640895 4.5554954,5.21319795 4.9037184,5.09035422 C5.50659706,4.87767482 6.24297594,5.04824464 6.71317259,5.73408386 L6.71317259,5.73408386 L6.71201446,1 L11.2120145,0.5 Z" id="Combined-Shape"></path>
                                            <rect id="Rectangle-Copy-38" transform="translate(9.212014, 6.500000) rotate(-360.000000) translate(-9.212014, -6.500000) " x="6.71201446" y="0.5" width="5" height="12" rx="1"></rect>
                                        </g>
                                    </g>
                                }
                                {spotlightCount > 1 &&
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M11.2120145,1.5 L11.7119993,9.00489402 L18.0987491,12.2242787 C18.4686323,12.4107171 18.7279296,12.7294302 18.8481465,13.0940045 C18.9683634,13.4585788 18.9494999,13.8690143 18.7650445,14.2350057 L18.7650445,14.2350057 L15.3545117,20.8567465 C15.1660174,21.2227097 14.8476506,21.4785087 14.4845079,21.5964953 C14.1213653,21.7144819 13.7134468,21.6946562 13.3458491,21.5093697 L13.3458491,21.5093697 L1.32527985,15.4504417 C0.955396633,15.2640033 0.696099324,14.9452902 0.575882421,14.5807159 C0.455665518,14.2161416 0.474529022,13.8057061 0.658984396,13.4397147 L0.658984396,13.4397147 L4.06951717,6.81797385 C4.25179018,6.4640895 4.5554954,6.21319795 4.9037184,6.09035422 C5.50659706,5.87767482 6.24297594,6.04824464 6.71317259,6.73408386 L6.71317259,6.73408386 L6.71201446,2 L11.2120145,1.5 Z" id="Combined-Shape" stroke="#8F9198"></path>
                                        <polygon id="+" fill="#E45200" fill-rule="nonzero" points="18.8370145 8.28125 18.8370145 4.6875 22.0088895 4.6875 22.0088895 3.3203125 18.8370145 3.3203125 18.8370145 0 17.3838895 0 17.3838895 3.3203125 14.2120145 3.3203125 14.2120145 4.6875 17.3838895 4.6875 17.3838895 8.28125"></polygon>
                                        <rect id="Rectangle-Copy-38" stroke="#8F9198" transform="translate(9.212014, 7.500000) rotate(-360.000000) translate(-9.212014, -7.500000) " x="6.71201446" y="1.5" width="5" height="12" rx="1"></rect>
                                    </g>
                                }
                                </svg>

                        </g>
                    }
    
    
                    {/* Buttons when active */}
                    {active && (
                        <>
                            <g onClick={() => this.handleOnInsert('before')} style={{cursor: "pointer"}}>
                                <circle cx={0} cy={width / 2} r="10"
                                        fill="#999999" stroke="#979797" stroke-width="1"
                                />
                                <line x1="-6" y1={width / 2} x2={6} y2={width / 2}
                                      stroke="#ffffff" stroke-width="1"
                                />
                                <line x1="0" y1={width / 2 - 6} x2="0" y2={width / 2 + 6}
                                      stroke="#ffffff" stroke-width="1"
                                />
                            </g>
                            <g onClick={() => this.handleOnInsert('after')} style={{cursor: "pointer"}}>
                                <circle cx={length} cy={width / 2} r="10"
                                        fill="#999999" stroke="#979797" stroke-width="1"
                                />
                                <line x1={length - 6} y1={width / 2} x2={length + 6} y2={width / 2}
                                      stroke="#ffffff" stroke-width="1"
                                />
                                <line x1={length} y1={width / 2 - 6} x2={length} y2={width / 2 + 6}
                                      stroke="#ffffff" stroke-width="1"
                                />
                            </g>
                        </>
                    )}

                </g>

                {active && (
                    <foreignObject x={0} y={0} width={length < 480 || isVertical ? 480 : length} height="160" transform={"translate(" + (isVertical ? -480 : length < 480 ? (length - 480) * .5 : 0) + ", " + (isVertical ? 0 : -160) + ")"}>
                        <ItemModal
                            article={article} spotlightCount={spotlightCount} active={active}
                            showRemove={showRemove} isVertical={isVertical}
                            onChange={() => this.handleOnChange()} onRemove={() => this.handleOnRemove()} onClose={() => this.handleOnClose()}
                        />
                    </foreignObject>
                )}

            </g>
        );
    }
}

const Label = styled.text`
    fill: #666;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-anchor: middle;
    dominant-baseline: hanging;
`;

export default Line;

import React, { PureComponent } from 'react';
import styled from 'styled-components';


class Button extends PureComponent {
    render() {

        const {
            label,
            block,
            filled,
            disabled,
            onClick,
            wide,
            ...props
        } = this.props;


        const Element = styled.div`
            display: ${block ? "block" : "inline-block"};
            border: 1px solid ${disabled ? "#DDDDDD" : "#E45200"};
            padding: 14px ${wide ? 48 : 28}px;
            margin: 0 0 8px 0;
            text-align: center;
            color: ${filled ? "#FFFFFF" : (disabled ? "#DDDDDD" : "#E45200")};
            background-color: ${filled ? "#E45200" : "transparent"};
            opacity: ${filled && disabled ? .5 : 1};
            font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif; 
            font-size: 16px; 
            line-height: 19px;
            text-transform: ${filled ? "uppercase" : "normal"};
            cursor: ${disabled ? "not-allowed" : "pointer"};
            a {
                color: inherit;
            }
        `;


        return (
            <Element onClick={disabled ? null : onClick} {...props}>{label}</Element>
        );
    }
}


export default Button;

import React, { PureComponent } from 'react';
import styled from 'styled-components';

import logo from "../assets/Images/logo_tagline.svg";


class Header extends PureComponent {
    render() {

        const {label} = this.props;

        return (
            <Container>
                <Logo src={logo} title="Lichtwerk" alt="Lichtwerk Logo" />
                {label && <Label>{label}</Label>}
            </Container>
        );
    }
}


const Container = styled.header`
    position: fixed;
    z-index: 10;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const Logo = styled.img`
    display: block;
    height: 64px;
    width: auto;
    margin: 0 auto;
`;
const Label = styled.div`
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    color: #999999;	
    font-size: 16px;
    line-height: 19px;
    margin: 20px 0 0;
    text-transform: uppercase;
    text-align: center;
`;


export default Header;

import React, { Component } from 'react';
import {connect} from 'react-redux';
import { push } from 'connected-react-router'

import styled from 'styled-components';
import i18n from "i18n-js";

import Legend from "../components/Legend";
import SelectImage from "../components/SelectImage";
import Tooltip from "../components/Tooltip";
import Screen from "../components/Screen";

import * as LinkService from "../services/LinkService";
import * as ActionTypes from "../actions";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products.products,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeSelectedAttribute: (params) => dispatch(ActionTypes.changeSelectedAttribute(params)),
        changeLayoutPowerSupplyLocation: (params) => dispatch(ActionTypes.changeLayoutPowerSupplyLocation(params)),
        changeLayoutRotation: (params) => dispatch(ActionTypes.changeLayoutRotation(params)),
        redirectTo: (path) => dispatch(push(path)),
    };
};

class ProductSelection extends Component {

    handleChangeMountingType = (newValue) => {
        if (newValue === "Wand-Lichtbandmontage") {
            this.props.changeSelectedAttribute({
                attribute: "form",
                value: "Linear",
            });
            // This one is a bit tricky, because the power supply of a wallmounted part is allways right (looking to the wall),
            //  we change the power supply and rotate. With that we make sure that the line input view looks like the wall is above the lane
            this.props.changeLayoutPowerSupplyLocation(true);
            this.props.changeLayoutRotation(180);
        }
        this.props.changeSelectedAttribute({
            attribute: "mountingType",
            value: newValue,
        });
    };

    handleChangeOptics = (newValue) => {
        this.props.changeSelectedAttribute({
            attribute: "opticsSimple",
            value: newValue,
        });

        this.redirectToNext();
    };

    redirectToNext = () => {
        this.props.redirectTo(
            LinkService.getPathForContainer(this.props.language.locale, this.props.configuration.selectedAttributes.mountingType === "Wand-Lichtbandmontage" ? "LineInput" : "FormSelection")
        );
    };

    getOpticsImage = (opticsSimple, color) => {
        return "lichttechnik/" + opticsSimple + "_" + color + "_Ausstrahlung.jpeg"
    };

    getOpticsLabel = (opticsSimple) => {
        return (<>{i18n.t('value.opticsSimple.' + opticsSimple)} <Tooltip languageKey={'productSelection.' + opticsSimple} /></>);
    };

    render() {

        const {configuration, products} = this.props;
        const attributes = configuration.selectedAttributes;

        return (
            <Screen>

                {products === false ? <div style={{marginTop: 60}}><p>Waiting for products to load...</p></div> : <>

                    <Legend>{i18n.t('title.mountingType')}</Legend>

                    <SelectionRow>

                        <SelectImage label={i18n.t("value.mountingType." + "Pendel-Lichtbandmontage")} imageName={"montageart/decke-schema.jpg"}
                                     active={attributes.mountingType === "Pendel-Lichtbandmontage"}
                                     onClick={() => this.handleChangeMountingType("Pendel-Lichtbandmontage")} />
                        <SelectImage label={i18n.t("value.mountingType." + "Wand-Lichtbandmontage")} imageName={"montageart/wand-schema.jpg"}
                                     active={attributes.mountingType === "Wand-Lichtbandmontage"}
                                     onClick={() => this.handleChangeMountingType("Wand-Lichtbandmontage")} />

                    </SelectionRow>

                    {attributes.mountingType !== false &&
                        <div>
                            <Legend>{i18n.t('title.lightEngine')} <Tooltip /></Legend>

                            <SelectionRow>

                                {attributes.mountingType === "Wand-Lichtbandmontage" && <>
                                    <SelectImage label={this.getOpticsLabel("Fresnel")}
                                                 imageName={this.getOpticsImage("Fresnel", attributes.color)}
                                                 onClick={() => this.handleChangeOptics("Fresnel")}  active={attributes.opticsSimple === "Fresnel"}
                                    />
                                </>}

                                {attributes.mountingType === "Pendel-Lichtbandmontage" && <>
                                    <SelectImage label={this.getOpticsLabel("Opal")}
                                                 imageName={this.getOpticsImage("Opal", attributes.color)}
                                                 onClick={() => this.handleChangeOptics("Opal")}  active={attributes.opticsSimple === "Opal"}
                                    />
                                    <SelectImage label={this.getOpticsLabel("Office")}
                                                 imageName={this.getOpticsImage("Office", attributes.color)}
                                                 onClick={() => this.handleChangeOptics("Office")}
                                                 active={attributes.opticsSimple === "Office"}
                                    />
                                </>}

                            </SelectionRow>
                        </div>
                    }

                </>}

            </Screen>

        )
    }
}

const SelectionRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 60px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelection);

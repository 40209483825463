import * as ProductService from '../services/ProductService'

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export function fetchProductsRequest() {
    return {
        type: FETCH_PRODUCTS_REQUEST
    }
}

export function fetchProductsSuccess(payload) {
    return {
        type: FETCH_PRODUCTS_SUCCESS,
        payload
    }
}

export function fetchProductsFailure(payload) {
    return {
        type: FETCH_PRODUCTS_FAILURE,
        payload
    }
}

export function fetchProducts() {
    return (dispatch) => {
        dispatch(fetchProductsRequest());

        ProductService.fetchProducts()
            .then(response => {
                dispatch(fetchProductsSuccess(response));
            })
            .catch(error => {
                dispatch(fetchProductsFailure(error));
            });
    }
}

import axios from 'axios'
import handleError from './handleError'
import HashSum from "hash-sum";
import i18n from 'i18n-js';

import * as Const from "../Const";
import * as ProductService from "./ProductService";
import * as ItemService from "./ItemService";


export const DOWNLOAD_URL = Const.API_URL + '?type=2000&query=download';
export const DOWNLOAD_MULTIPLE_URL = Const.API_URL + '?type=2000&query=downloadMultiple';


export const getReluxData = (configuration) => {

    let data = {
        article_number: configuration.meta.acronym ? configuration.meta.acronym : (configuration.configurationKey ? configuration.configurationKey : "lichtwerk-serio"),
        product_name: configuration.meta.projectName ? configuration.meta.projectName : "lichtwerk serio",
        description: "",
        assembly: []
    }, translateX = false, translateY = false, orientation = false, minX = 0, minY = 0, maxY = 0, offsetX = 0, offsetY = 0;

    Object.entries(configuration.items).map(([key, item]) => {
        if (item.article.reluxIdentifier) {

            if (!translateX || !translateY || !orientation) {
                if (item.meta.orientation % 180 === 0) {
                    orientation = 0;
                    translateX = 0;
                    translateY = item.article.length / 2;
                }
                else {
                    orientation = 90;
                    translateX = item.article.length / 2;
                    translateY = 0;
                }
            }

            let x = item.meta.position.x + translateX + offsetX,
                y = item.meta.position.y + translateY + offsetY,
                transformation = {
                    x: x,
                    y: y,
                    orientation: orientation,
                };

            if (item.meta.type === "corner") {
                let width = item.article.width;
                transformation.orientation = item.meta.orientation;
                if (item.meta.direction === "left") {
                    if (transformation.orientation === 0 || transformation.orientation === 180) {
                        transformation.orientation = (transformation.orientation + 270) % 360;
                    }
                    else if (transformation.orientation === 90 || transformation.orientation === 270) {
                        transformation.orientation = (transformation.orientation + 90) % 360;
                    }

                    switch (transformation.orientation) {
                        case 0:
                            transformation.x = x - item.article.length / 2 + width / 2;
                            offsetX += width / 2;
                            offsetY -= width / 2;
                            break;
                        case 90:
                            offsetX -= width / 2;
                            transformation.y = y + item.article.length / 2 - width / 2;
                            offsetY += width / 2;
                            break;
                        case 180:
                            transformation.x = x + item.article.length / 2 - width / 2;
                            offsetX += width / 2;
                            transformation.y = y + item.article.length - width;
                            offsetY -= width / 2;
                            break;
                        case 270:
                            transformation.x = x + item.article.length - width;
                            offsetX -= width / 2;
                            transformation.y = y - item.article.length / 2 + width / 2;
                            offsetY += width / 2;
                            break;
                    }
                }
                else if (item.meta.direction === "right") {
                    if (transformation.orientation === 90 || transformation.orientation === 270) {
                        transformation.orientation = (transformation.orientation + 180) % 360;
                    }

                    switch (transformation.orientation) {
                        case 0:
                            offsetX -= width / 2;
                            transformation.y = y - item.article.length / 2 + width / 2;
                            offsetY += width / 2;
                            break;
                        case 90:
                            transformation.x = x - item.article.length / 2 + width / 2;
                            offsetX += width / 2;
                            transformation.y = y + item.article.length - width;
                            offsetY -= width / 2;
                            break;
                        case 180:
                            transformation.x = x + item.article.length - width;
                            offsetX -= width / 2;
                            transformation.y = y + item.article.length / 2 - width / 2;
                            offsetY += width / 2;
                            break;
                        case 270:
                            transformation.x = x + item.article.length / 2 - width / 2;
                            offsetX += width / 2;
                            offsetY -= width / 2;
                            break;
                    }
                }
            }

            minX = Math.min(minX, transformation.x);
            minY = Math.min(minY, transformation.y);
            maxY = Math.max(maxY, transformation.y);

            data.assembly.push({
                article_number: item.article.reluxIdentifier,
                transformation: transformation
            });

        }
    });

    minX = Math.abs(minX);
    minY = Math.abs(minY);
    maxY = Math.abs(maxY);

    data.assembly.forEach(item => {
        item.transformation = {
            x: item.transformation.x + minX,
            y: maxY - (item.transformation.y + minY),
            orientation: item.transformation.orientation,
        };
    });


    data.assembly.forEach(item => {
        item.transformation = [
            { "position.x": item.transformation.x / 1000 },
            { "position.y": item.transformation.y / 1000 },
            { "rotation.z": item.transformation.orientation },
        ]
    });

    return data;
};

export const getTenderTextData = (configuration) => {
    return {
        language: i18n.locale,
        result: configuration.result,
        dimensions: ItemService.getDimensions(configuration.items),
        lines: ItemService.getArticleListPerLine(configuration.items),
        articleList: getArticleList(configuration),
    };
};

const getArticleList = (configuration) => {

    const flatArticleList = ItemService.getArticleList(configuration.items, configuration.result.diffusors);

    let articleList = {
        data: {
            lines: [],
            accessories: [],
        },
        productImages: {},
    };

    flatArticleList.forEach(item => {

        if (item.meta.type === "line" || item.meta.type === "corner") {
            articleList.data.lines.push(item);
        }
        else {
            articleList.data.accessories.push(item);
        }

        if (!articleList.productImages[item.article.articleNumber]) {
            articleList.productImages[item.article.articleNumber] = ProductService.getImageLink(item.article, true);
        }
    });

    return articleList;
};

export const getSummaryData = (configuration, canvas) => {

    return {
        canvas: canvas,
        result: configuration.result,
        dimensions: ItemService.getDimensions(configuration.items),
        lines: ItemService.getArticleListPerLine(configuration.items),
        articleList: getArticleList(configuration),
        structuralArticleList: ItemService.getStructuralArticleList(configuration.items),
    };
};

export const getArticleListData = (configuration, canvas) => {

    return {
        articleList: getArticleList(configuration),
    };
};

export const download = (filetype, configuration, additionalConfiguration) => {

    let data = reduceConfigurationData(configuration),
        additionalData = false,
        hash = ProductService.getConfigurationHash(configuration);

    switch (filetype) {
        case "relux":
            additionalData = getReluxData(data);
            break;
        case "tenderText":
            additionalData = getTenderTextData(configuration);
            break;
        case "summary":
            additionalData = getSummaryData(configuration, additionalConfiguration);
            break;
        case "articleList":
            additionalData = getArticleListData(configuration);
            break;
        case "dxf":
            additionalData = {
                totalDimension: ItemService.getTotalDimension(configuration.items)
            };
            break;
        default:
            additionalData = additionalConfiguration ?? false;
            break;
    }

    return new Promise((resolve, reject) => {

        axios.post(DOWNLOAD_URL, {
            language: i18n.locale,
            configuration: data,
            additionalData: additionalData,
        }, {
            params: {
                filetype: filetype,
                hash: hash,
            },
        })
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const downloadMultiple = (filetypes, configuration, additionalConfiguration) => {

    let data = reduceConfigurationData(configuration),
        additionalData = {},
        hash = ProductService.getConfigurationHash(configuration),
        filetypesCSV = "";

    filetypes.forEach(filetype => {
        filetypesCSV += filetype + ";";

        switch (filetype) {
            case "relux":
                additionalData["relux"] = getReluxData(data);
                break;
            case "tenderText":
                additionalData["tenderText"] = getTenderTextData(configuration);
                break;
            case "summary":
                additionalData["summary"] = getSummaryData(configuration, additionalConfiguration["summary"]);
                break;
            case "articleList":
                additionalData["articleList"] = getArticleListData(configuration);
                break;
            case "dxf":
                additionalData["dxf"] = {
                    totalDimension: ItemService.getTotalDimension(configuration.items)
                };
                break;
        }

    });

    return new Promise((resolve, reject) => {

        axios.post(DOWNLOAD_MULTIPLE_URL, {
            language: i18n.locale,
            configuration: data,
            additionalData: additionalData,
        }, {
            params: {
                filetypes: filetypesCSV,
                filetypesHash: HashSum(filetypesCSV),
                hash: hash,
            },
        })
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const reduceConfigurationData = (configuration) => {
    return {
        configurationKey: configuration.configurationKey,
        selectedAttributes: {
            ...configuration.selectedAttributes,
        },
        layout: {
            ...configuration.layout,
            lineLengths: false,
        },
        items: {
            ...configuration.items,
        },
        meta: {
            ...configuration.meta,
        }
    };
};


export function downloadLink(link) {
    window.open(link);
}

export function downloadData(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}





export function getInternalArticleList(articleList, configuration, dimensions) {
    let csvString = "serio Konfiguration " + configuration.configurationKey + ", " + configuration.selectedAttributes.form + ", ";

    Object.entries(dimensions).forEach(function([lane, length]) {
        csvString += "S" + lane + " " + length + " mm";
        if (lane != Object.keys(dimensions).length) {
            csvString += " x ";
        }
    });
    csvString += ", bestehend aus:;; \n;\n";

    Object.entries(articleList).forEach(function([i, item]) {
        csvString += item.article.name + ";" + item.article.articleNumber + ";" + item.qty + "\n";
        if ((Number(i) + 1) % 10 === 0) {
            csvString += ";\n";
        }
    });

    return encodeURI(csvString);
}
import * as ActionTypes from '../actions';

import * as ProductService from '../services/ProductService';


const initialState = {
    products: false,
    lines: false,
    corners: false,
    ends: false,
    inlineSupplies: false,
    diffusors: false,
    railMounts: false,
    isLoading: false,
    isOwner: false,
};

const Products = (state = initialState, action) => {
    switch(action.type) {
        case ActionTypes.FETCH_PRODUCTS_REQUEST:
            return { ...state, isLoading: true };

        case ActionTypes.FETCH_PRODUCTS_SUCCESS:
            const mappedProducts = Object.entries(action.payload.products).map(el => el[1]);

            const lines = ProductService.filterProducts(mappedProducts, [{
                field: "type",
                value: "Linie",
            }]);

            let corners = ProductService.filterProducts(mappedProducts, [{
                field: "type",
                value: "Ecke rechts",
            }]).concat(
                ProductService.filterProducts(mappedProducts, [{
                    field: "type",
                    value: "Ecke links",
                }])
            );

            const ends = ProductService.filterProducts(mappedProducts, [{
                field: "type",
                value: "Endkappe",
            }]);
            
            const inlineSupplies = ProductService.filterProducts(mappedProducts, [{
                field: "type",
                value: "Einspeisung",
            }]);

            const diffusors = ProductService.filterProducts(mappedProducts, [{
                field: "type",
                value: "Endlosdiffusor",
            }]);

            const railMounts = ProductService.filterProducts(mappedProducts, [{
                field: "type",
                value: "Leuchte für serio-HITD",
            }]);
            let isOwner = false;
            if (action.payload.isOwner === true) {
                isOwner = true;
            }

            return {
                ...state,
                isLoading: false,
                products: mappedProducts,
                lines: lines,
                corners: corners,
                ends: ends,
                inlineSupplies: inlineSupplies,
                diffusors: diffusors,
                railMounts: railMounts,
                isOwner: isOwner,
            };

        case ActionTypes.FETCH_PRODUCTS_FAILURE:
            return { ...state, isLoading: false };


        default:
            return state;
    }
};

export default Products;

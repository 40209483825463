import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";

import styled from "styled-components";

import * as ActionTypes from "../actions";
import * as LinkService from "../services/LinkService";

import Header from "../components/Header";
import * as DownloadService from "../services/DownloadService";
import * as ItemService from "../services/ItemService";
import TextLink from "../components/TextLink";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        loadConfiguration: (params) => dispatch(ActionTypes.loadConfiguration(params)),
    };
};

class DownloadArticleList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            configurationKey: props.match.params.configurationKey,
            isLoading: false,
            autoDownloadDone: false,
        }
    }

    performInternalArticleListDownload(articleList, configuration, dimensions) {
        let csvString = DownloadService.getInternalArticleList(articleList, configuration, dimensions);
        DownloadService.downloadData("data:text/csv," + csvString, "Artikelliste-" + configuration.configurationKey + ".csv");
    }

    componentDidUpdate() {
        const {configuration, products} = this.props,
            articleList = ItemService.getArticleList(configuration.items, configuration.result.diffusors);

        if (this.props.products.products && !this.state.isLoading) {
            this.props.loadConfiguration(this.state.configurationKey);
            this.setState({
                ...this.state,
                isLoading: true,
            });
        }

        // We assume loading the configuration was successful once the items array is rehydrated
        // ToDo: this could provoke errors, so maybe find a better way?
        if (!this.state.autoDownloadDone && configuration.items.length) {
            this.performInternalArticleListDownload(articleList, configuration, ItemService.getDimensions(configuration.items));
            this.setState({
                ...this.state,
                autoDownloadDone: true,
            });
        }
    };

    render() {

        const {configurationKey, autoDownloadDone} = this.state,
            {configuration, products} = this.props,
            articleList = ItemService.getArticleList(configuration.items, configuration.result.diffusors);

        return (
            <Container className="Configurator">

                <Header />

                {autoDownloadDone ?
                    <LoadingContainer>
                        Artikelliste geladen. <br />
                        <TextLink style={{marginTop: 20}}
                                  onClick={() => this.performInternalArticleListDownload(articleList, configuration, ItemService.getDimensions(configuration.items))} label={"Herunterladen"}
                        />
                        <TextLink style={{marginRight: 0, marginTop: 20}} primary
                                  onClick={() => this.props.redirectTo(LinkService.getPathForContainer(this.props.language.locale, "Result"))} label={"Zur Konfiguration"}
                        />
                    </LoadingContainer> :
                    <LoadingContainer>Lade CSV-Artikelliste für <strong>{configurationKey}</strong>...</LoadingContainer>
                }

            </Container>
        )
    }
}


const Container = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
`;
const LoadingContainer = styled.div`
    margin-top: 120px;
    text-align: center;
`;

export default connect(mapStateToProps, mapDispatchToProps)(DownloadArticleList);

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";

import styled from "styled-components";
import i18n from "i18n-js";
import cloneDeep from 'clone-deep';

import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import * as ActionTypes from "../actions";
import * as DownloadService from "../services/DownloadService";
import * as ProductService from "../services/ProductService";
import * as ItemService from "../services/ItemService";
import * as LinkService from "../services/LinkService";
import * as Const from "../Const";

import Backlink from "../components/Backlink";
import Badge from "../components/Badge";
import Button from "../components/Button";
import Canvas from "../components/Canvas";
import Screen from "../components/Screen";
import WebsiteImage from "../components/WebsiteImage";
import TextLink from "../components/TextLink";
import Label from "../components/Label";
import Input from "../components/Input";
import Modal from "../components/Modal";
import Tooltip from "../components/Tooltip";
import LoadingIndicator from "../components/LoadingIndicator";


const mapStateToProps = (state) => {
    return {
        isOwner: state.products.isOwner,
        configuration: state.configuration,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        changeDownloadStatus: (payload) => dispatch(ActionTypes.changeDownloadStatus(payload)),
        download: (filetype, configuration, additionalData) => dispatch(ActionTypes.download(filetype, configuration, additionalData)),
        downloadMultiple: (filetypes, configuration, additionalData) => dispatch(ActionTypes.downloadMultiple(filetypes, configuration, additionalData)),
        saveConfiguration: (configuration) => dispatch(ActionTypes.saveConfiguration(configuration)),
        copyConfiguration: (configuration) => dispatch(ActionTypes.saveConfigurationSilent(configuration)),
        changeMeta: (props) => dispatch(ActionTypes.changeMeta(props)),
    };
};

class Result extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkCopied: false,
            meta: {...props.configuration.meta},
            tabIndex: 'downloads',
            selectedDownloads: {
                summary: true,
            },
            modalArticle: false,
            copyRequested: false,
        };

        if (!LinkService.isContainerAllowed("Result", props.configuration)) {
            this.linkBack();
        }

        if (LinkService.isContainerAllowed("Result", props.configuration) && this.props.configuration.configurationKey === false) {
            this.saveConfiguration();
        }

        this.canvasRef = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.configuration.meta !== this.props.configuration.meta) {
            this.setState({
                ...this.state,
                meta: {...this.props.configuration.meta}
            });
        }

        if (this.props.configuration.configurationKeyCopied && this.state.copyRequested) {
            window.open(
                LinkService.getShortlink(this.props.language.locale, this.props.configuration.configurationKeyCopied)
            );
            this.setState({
                ...this.state,
                copyRequested: false,
            });
        }
    }

    saveConfiguration() {
        this.props.saveConfiguration(this.props.configuration);
    }

    changeMetaLocal(name, value) {
        let newState = {...this.state};
        newState.meta[name] = value;
        this.setState(newState);
    }

    changeMetaGlobal(name, value) {
        this.props.changeMeta({
            name: name,
            value: value.trim(),
        });
    }

    linkBack() {
        this.props.redirectTo(
            LinkService.getPathForContainer(this.props.language.locale, "Configurator")
        );
    }

    onLinkCopy() {
        this.setState({linkCopied: true});
        setTimeout(function() {
                this.setState({linkCopied: false});
            }.bind(this),
            3000
        );
    }

    changeTabIndex(newTabIndex) {
        this.setState({
            ...this.state,
            tabIndex: newTabIndex,
        });
    }


    getFileKeyPart(prefix = "_", suffix = "") {
        if (this.props.configuration.meta.acronym) {
            return prefix + this.props.configuration.meta.acronym + suffix;
        }
        else if (this.props.configuration.configurationKey) {
            return prefix + this.props.configuration.configurationKey + suffix;
        }
        return "";
    }

    performDownload(filetype) {
        let download = this.downloads[filetype];
        if (!download) return false;

        let additionalData = false;
        if (this.downloads[filetype]?.additionalData) {
            additionalData = this.downloads[filetype].additionalData();
        }

        this.props.download(filetype, this.props.configuration, additionalData);
    }

    performArticleDownload(articleNumber, fileType) {
        let additionalData = {
            articleNumber: articleNumber,
            fileType: fileType,
            language: i18n.locale,
        };

        this.props.download("articleFile", this.props.configuration, additionalData);
    }

    performMultipleDownloads() {
        if (this.countSelectedDownloads() === 1) {
            let result = this;
            Object.entries(this.state.selectedDownloads).forEach(function([key, download]) {
                if (download) {
                    result.performDownload(key);
                }
            });
            return;
        }

        let downloads = [];
        Object.entries(this.state.selectedDownloads).forEach(function([key, isActive]) {
            if (isActive) {
                downloads.push(key);
            }
        });


        let additionalData = {};
        downloads.forEach(filetype => {
            if (this.downloads[filetype]?.additionalData) {
                additionalData[filetype] = this.downloads[filetype].additionalData();
            }
        });

        this.props.downloadMultiple(downloads, this.props.configuration, additionalData);

    }

    performInternalArticleListDownload(articleList, configuration, dimensions) {
        let csvString = DownloadService.getInternalArticleList(articleList, configuration, dimensions);
        DownloadService.downloadData("data:text/csv," + csvString, "Artikelliste-" + configuration.configurationKey + ".csv");
    }

    copyConfiguration() {
        let configuration = cloneDeep(this.props.configuration);
        configuration.meta.projectName = configuration.meta.projectName ? configuration.meta.projectName + " (copied)" : "Copy of " + this.props.configuration.configurationKey;

        this.props.copyConfiguration(configuration);
        this.setState({
            ...this.state,
            copyRequested: true,
        });
    }

    changeSelectedDownloads(key, newValue) {
        let newState = {...this.state};
        newState.selectedDownloads[key] = newValue;
        this.setState(newState);
    }

    countSelectedDownloads() {

        let selectedCount = 0;
        Object.entries(this.state.selectedDownloads).forEach(function([key, download]) {
            if (download) {
                selectedCount++;
            }
        });
        return selectedCount;
    }

    changeAllSelectedDownloads() {

        let newValue = !(Object.entries(this.downloads).length === this.countSelectedDownloads()),
            newSelectedDownloads = {};
        Object.entries(this.downloads).forEach(function([key, download]) {
            newSelectedDownloads[key] = newValue;
        });
        this.setState({
            ...this.state,
            selectedDownloads: newSelectedDownloads,
        });
    }

    downloads = {
        summary: {
            format: "pdf",
            icon: "file-pdf",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_{i18n.t('filepart.summary')}.pdf</>},
            additionalData: () => { return this.canvasRef?.current?.ViewerDOM?.outerHTML; }
        },
        dxf: {
            format: "dxf",
            icon: "file",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_{i18n.t('filepart.drawing')}.dxf</>},
        },
        relux: {
            format: "rolfz",
            icon: "file",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_{i18n.t('filepart.drawing')}.rolfz</>},
        },
        articleList: {
            format: "pdf",
            icon: "file-pdf",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_{i18n.t('filepart.articleList')}.pdf</>},
        },
        tenderText: {
            format: "txt",
            icon: "file-alt",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_{i18n.t('filepart.tenderText')}.txt</>},
        },
        // productFamily: {
        //     format: "pdf",
        //     icon: "file-pdf",
        //     filename: () => {return "produktfamilie.pdf"},
        // },
        mountingInstructions: {
            format: "pdf",
            icon: "file-pdf",
            filename: (configuration) => {
                switch (configuration.selectedAttributes.mountingType) {
                    case "Pendel-Lichtbandmontage":
                        return "MA_serio-HIT.pdf";
                    case "Wand-Lichtbandmontage":
                        return "MA_serio-W.pdf";
                }
                return <>MA_serio.pdf</>},
        },
        articleFilesLDT: {
            format: "ldt",
            icon: "file-archive",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_LDT.zip</>},
            isArticlePackage: true,
        },
        articleFilesIES: {
            format: "ies",
            icon: "file-archive",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_IES.zip</>},
            isArticlePackage: true,
        },
        // articleFilesGAEB: {
        //     format: "gaeb",
        //     icon: "file-archive",
        //     filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_GAEB.zip</>},
        //     isArticlePackage: true,
        // },

        articleFilesDatasheet: {
            format: "pdf",
            icon: "file-archive",
            filename: configuration => {return <>{i18n.t('filepart.general')}{this.getFileKeyPart()}_{i18n.t('filepart.datasheets')}.zip</>},
            isArticlePackage: true,
        },
    };

    render() {

        const {configuration} = this.props,
            {linkCopied, meta, tabIndex, selectedDownloads, modalArticle} = this.state,
            articleList = ItemService.getArticleList(configuration.items, configuration.result.diffusors),
            selectedDownloadCount = this.countSelectedDownloads();

        return (
            <Screen pager={false}>
                <div>

                    <p>
                        <Backlink onClick={() => this.linkBack()}>{i18n.t('action.back')}</Backlink>
                    </p>

                    <Section style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>

                        <h2 style={{margin: 0}}>
                            {i18n.t('title.lightConfiguration')}&nbsp;
                            <span style={{color: "#E45200"}}>{configuration.isSaving ? i18n.t("result.saving") : configuration.configurationKey}</span>
                        </h2>

                        <div>
                            <TextLink light onClick={() => this.performDownload("summary")} label={<><FontAwesomeIcon icon={["fal", "download"]} /> {i18n.t("result.downloadSummary")}</>} />
                            <CopyToClipboard
                                onCopy={() => this.onLinkCopy()}
                                text={LinkService.getShortlink(this.props.language.locale, configuration.configurationKey)}>
                                <TextLink light primary={linkCopied} label={linkCopied ?
                                    <><FontAwesomeIcon icon={["fal", "check"]} /> {i18n.t("result.linkCopied")}</> :
                                    <><FontAwesomeIcon icon={["fal", "link"]} /> {i18n.t("result.linkCopy")}</>} />
                            </CopyToClipboard>
                        </div>

                    </Section>

                    <Section style={{display: "flex", alignItems: "flex-start"}}>

                        <div style={{flex: "1 1 auto"}}>
                            <Label>{i18n.t("field.projectName.title")}</Label>
                            <Input placeholder={i18n.t("field.projectName.placeholder")} value={meta.projectName} onInput={(event) => this.changeMetaLocal("projectName", event.target.value)} onBlur={(event) => this.changeMetaGlobal("projectName", event.target.value)} />
                        </div>

                        <div style={{flex: "0 1 100px", margin: "0 20px"}}>
                            <Label>{i18n.t("field.acronym.title")}</Label>
                            <Input placeholder={i18n.t("field.acronym.placeholder")} value={meta.acronym} onInput={(event) => this.changeMetaLocal("acronym", event.target.value)} onBlur={(event) => this.changeMetaGlobal("acronym", event.target.value)} />
                        </div>

                        <div style={{flex: "1 1 auto"}}>
                            <Label>{i18n.t("field.supervisor.title")}</Label>
                            <Input placeholder={i18n.t("field.supervisor.placeholder")} value={meta.supervisor} onInput={(event) => this.changeMetaLocal("supervisor", event.target.value)} onBlur={(event) => this.changeMetaGlobal("supervisor", event.target.value)} />
                        </div>

                        <FormSave style={{flex: "0 0 auto"}} onClick={() => this.saveConfiguration()}>
                            <FontAwesomeIcon icon={["fal", "save"]} />
                        </FormSave>

                    </Section>

                    <Section>
                        <Canvas height={350} items={configuration.items}
                                color={configuration.selectedAttributes.color} mountingType={configuration.selectedAttributes.mountingType}
                        />
                    </Section>

                    <Section style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
                        <Button onClick={() => this.linkBack()} label={<><FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "chevron-left"]} /> {i18n.t("result.editConfiguration")}</>} />
                        <div>
                            <Button onClick={() => this.copyConfiguration()} label={<>{i18n.t("result.editCopy")} <FontAwesomeIcon style={{marginLeft: 8}} icon={["fal", "external-link-alt"]} /></>} style={{marginRight: 12}} />
                            <Button disabled={configuration.configurationHash === ProductService.getConfigurationHash(configuration)} onClick={() => this.saveConfiguration()} label={<><FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "save"]} /> {configuration.isSaving ? i18n.t("result.saving") : i18n.t("result.save")}</>} filled />
                        </div>
                    </Section>


                    <Section>

                        <TabHeader>
                            <Tab onClick={() => this.changeTabIndex("downloads")} active={tabIndex === "downloads"}>{i18n.t("title.downloads")}</Tab>
                            <Tab onClick={() => this.changeTabIndex("articleList")} active={tabIndex === "articleList"}>{i18n.t("title.articleList")}</Tab>
                        </TabHeader>

                        <TabContent>

                            {tabIndex === "downloads" && <>
                                <h2>{i18n.t("title.downloads")} <Tooltip languageKey={"downloads"} /></h2>
                                <div className="table-responsive">
                                    <table className="table table-fixed">

                                        <thead>
                                        <tr>
                                            <th style={{width: 60}} onClick={() => this.changeAllSelectedDownloads()}>
                                                {selectedDownloadCount === Object.entries(this.downloads).length && <Checkbox active><FontAwesomeIcon icon={["fal", "check"]} /></Checkbox>}
                                                {selectedDownloadCount > 0 && selectedDownloadCount !== Object.entries(this.downloads).length && <Checkbox><FontAwesomeIcon icon={["fal", "check"]} /></Checkbox>}
                                                {selectedDownloadCount === 0 && <Checkbox>&nbsp;</Checkbox>}
                                            </th>
                                            <th style={{width: 340}}>{i18n.t("result.title")}</th>
                                            <th style={{width: 100}}>{i18n.t("result.format")}</th>
                                            <th>{i18n.t("result.file")}</th>
                                            <th style={{width: 60}}>&nbsp;</th>
                                        </tr>
                                        </thead>

                                        <tbody>

                                        {Object.entries(this.downloads).map(([key, item]) => (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td onClick={() => this.changeSelectedDownloads(key, !selectedDownloads[key])}>
                                                        <Checkbox active={!!selectedDownloads[key]}>
                                                            {!!selectedDownloads[key] && <FontAwesomeIcon icon={["fal", "check"]} />}
                                                        </Checkbox>
                                                    </td>
                                                    <td><strong>{i18n.t('filetype.' + key)}</strong></td>
                                                    <td>{item.format.toUpperCase()}</td>
                                                    <td>
                                                        <FontAwesomeIcon style={{marginRight: 8}} icon={["fal", item.icon]} /> {item.filename(configuration)}
                                                        {item.format === "dxf" && <Badge label={i18n.t("result.new")} />}
                                                        {item.isArticlePackage && <><br />
                                                            <span onClick={() => this.changeTabIndex("articleList")} style={{display: "inline-block", marginTop: 10, paddingLeft: 24, cursor: "pointer", color: "#E45200"}}>
                                                                {i18n.t("result.singleDownloads")} <FontAwesomeIcon style={{fontSize: 12, marginLeft: 4}} icon={["fal", "chevron-right"]} />
                                                            </span>
                                                        </>}
                                                    </td>
                                                    <td><Button style={{padding: "8px 10px", margin: "-4px 0 0", fontSize: 15}} onClick={() => this.performDownload(key)} label={<FontAwesomeIcon icon={["fal", "file-download"]} />} /></td>
                                                </tr>
                                            </React.Fragment>
                                        ))}

                                        {/*Downloads and information only accessible for owner and devs*/}
                                        {this.props.isOwner && <>

                                            <tr>
                                                <td>
                                                    <Checkbox active={false} disabled={true} />
                                                </td>
                                                <td><strong>SAP Artikelliste</strong> <Badge label={"Intern"} /></td>
                                                <td>CSV</td>
                                                <td><FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "file-csv"]} /> Artikelliste-{configuration.configurationKey}.csv</td>
                                                <td><Button style={{padding: "8px 10px", margin: "-4px 0 0", fontSize: 15}} onClick={() => this.performInternalArticleListDownload(articleList, configuration, ItemService.getDimensions(configuration.items))} label={<FontAwesomeIcon icon={["fal", "file-download"]} />} /></td>
                                            </tr>

                                        </>}

                                        </tbody>


                                    </table>
                                </div>

                                <div style={{textAlign: "right"}}>
                                    <Button disabled={selectedDownloadCount === 0} primary filled
                                            onClick={() => this.performMultipleDownloads()}
                                            label={<>
                                                <FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "file-archive"]} />
                                                {i18n.t("result.downloadFiles")}
                                            </>}
                                    />
                                </div>

                            </>}

                            {tabIndex === "articleList" && <>
                                <h2>{i18n.t("title.articleList")}</h2>
                                <div class="table-responsive">
                                    <table class="table table-fixed">
                                        <thead>
                                        <tr>
                                            <th style={{width: 200}}>{i18n.t('result.article')}</th>
                                            <th>&nbsp;</th>
                                            <th style={{width: 120}}>{i18n.t('result.price')}</th>
                                            <th style={{width: 120}}>{i18n.t('result.sum')}</th>
                                            <th style={{width: 80}}>{i18n.t('result.files')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {articleList.map(item => (
                                            <tr>

                                                <td>
                                                    <ArticleImage>
                                                        <WebsiteImage
                                                            name={ProductService.getImageLink(item.article, false)}
                                                            absolute={false}/>
                                                    </ArticleImage>
                                                </td>

                                                <td>
                                                    <p>
                                                        <strong>{item.article.name}</strong> <br/>
                                                        {i18n.t('title.articleNumberShort')}: {item.article.articleNumber}
                                                    </p>
                                                    <p>
                                                        {i18n.t('title.length')}: {item.article.length} mm <br/>
                                                        {i18n.t('title.quantity')}: {item.qty}
                                                    </p>
                                                </td>

                                                <td>
                                                    <NumberFormat value={item.article.price} displayType={'text'}
                                                                  decimalSeparator={","} thousandSeparator={'.'}
                                                                  suffix={' €'} fixedDecimalScale={true}
                                                                  decimalScale={2}/>
                                                </td>

                                                <td>
                                                    <strong>
                                                        <NumberFormat value={item.article.price * item.qty}
                                                                      displayType={'text'} decimalSeparator={","}
                                                                      thousandSeparator={'.'} suffix={' €'}
                                                                      fixedDecimalScale={true} decimalScale={2}/>
                                                    </strong>
                                                </td>

                                                <td><Button style={{padding: "8px 10px", margin: "-4px 0 0", fontSize: 15}} onClick={() => this.setState({...this.state, modalArticle: item.article})} label={<FontAwesomeIcon icon={["fal", "file-export"]} />} /></td>

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>}

                        </TabContent>
                    </Section>


                    <Modal isVisible={!!modalArticle} small>
                        <h3>{i18n.t('result.downloadsFor')} {modalArticle?.name}</h3>
                        <p style={{marginBottom: 24}}>{i18n.t('title.articleNumberShort')}: {modalArticle?.articleNumber}</p>

                        <ModalArticle onClick={() => this.performArticleDownload(modalArticle?.articleNumber, "articleFileDatasheet")}>
                            <FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "file-pdf"]} />{i18n.t('filetype.articleFileDatasheet')} (.pdf)
                        </ModalArticle>
                        <ModalArticle onClick={() => this.performArticleDownload(modalArticle?.articleNumber, "articleFileIES")}>
                            <FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "file"]} />{i18n.t('filetype.articleFileIES')} (.ies)
                        </ModalArticle>
                        <ModalArticle onClick={() => this.performArticleDownload(modalArticle?.articleNumber, "articleFileLDT")}>
                            <FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "file"]} />{i18n.t('filetype.articleFileLDT')} (.ldt)
                        </ModalArticle>
                        {/*<ModalArticle onClick={() => this.performArticleDownload(modalArticle?.articleNumber, "articleFileGAEB")}>*/}
                            {/*<FontAwesomeIcon style={{marginRight: 8}} icon={["fal", "file"]} />{i18n.t('filetype.articleFileGAEB')} (.gaeb)*/}
                        {/*</ModalArticle>*/}

                        <p style={{textAlign: "right", marginBottom: 0}}>
                            <Button label={i18n.t('action.close')} style={{marginBottom: 0}} onClick={() => this.setState({...this.state, modalArticle: false})} />
                        </p>

                    </Modal>

                    <div style={{visibility: "hidden", opacity: 0, position: "absolute", top: 0, left: 0}}>
                        <Canvas canvasRef={this.canvasRef} width={1024} height={800}
                                items={configuration.items}
                                color={configuration.selectedAttributes.color} mountingType={configuration.selectedAttributes.mountingType}
                        />
                    </div>

                    {configuration.meta.downloadStatus === Const.DOWNLOAD_STATUS_SENDING && <LoadingIndicator isVisible={true} info={i18n.t("result.downloadProcessingMessage")} />}

                    <Modal small isVisible={configuration.meta.downloadStatus === Const.DOWNLOAD_STATUS_ERROR}>
                        <p>
                            {i18n.t("result.downloadErrorMessage")}
                        </p>
                        <Button label={i18n.t("action.close")} onClick={() => this.props.changeDownloadStatus({newState: Const.DOWNLOAD_STATUS_NONE})} />
                    </Modal>

                </div>
            </Screen>
        )
    }
}

const Section = styled.div`
    display: block;
    margin-bottom: 24px;
`;
const Article = styled.div`
    display: block;
    padding-bottom: 4px;
    border-bottom: 1px solid hsla(0, 0%, 0%, .05);
    margin-bottom: 18px;
`;
const ArticleImage = styled.span`
    display: inline-block;
    width: 180px;
    height: auto;
`;
const FormSave = styled.div`
    align-self: flex-end;
    padding: 8px;
    margin: 0 0 16px 8px;
    font-size: 16px;
    color: #e45200;
    cursor: pointer;
`;

const TabHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: 1px solid #DDDDDD;
`;
const Tab = styled.div`
    color: ${props => props.active ? "#E45200" : "#999999"};
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    padding: 16px 20px;
    cursor: pointer;
    border-bottom: 1px solid ${props => props.active ? "#E45200" : "transparent"};
    margin-bottom: -1px;
`;
const TabContent = styled.div`
    display: block;
    padding: 40px 16px;
`;
const Checkbox = styled.div`
    display: inline-block;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    width: 18px;
    height: 18px;
    color: ${props => props.active ? "white" : "#DDDDDD"};
    background-color: ${props => props.active ? "#E45200" : "transparent"};
    border: 1px solid;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    padding: 2px 0 0;
    opacity: ${props => props.disabled ? .6 : 1};
    box-sizing: border-box;
`;

const ModalArticle = styled.div`
    cursor: pointer;
    margin-bottom: 12px;
    :hover {
        color: #E45200;
    }
`;

export default connect(mapStateToProps, mapDispatchToProps)(Result);

import React, { PureComponent } from 'react';
import styled from 'styled-components';


class Legend extends PureComponent {
    render() {
        const {
            children,
            ...props
        } = this.props;

        return (
            <Element {...props}>{children}</Element>
        );
    }
}


const Element = styled.div`
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 100;
    margin-bottom: 20px;
`;


export default Legend;

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";

import styled from "styled-components";

import Legend from "../components/Legend";
import Screen from "../components/Screen";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
    };
};

class Debug extends Component {

    getLineIllustrationContent = item => {
        let content = [];
        for (let i = 0; i < item.qty; i++) {
            content.push(<div key={item.id} style={{height: 10, border: "1px solid", borderColor: (item.line.length <= 1000 ? "red" : "black"), marginRight: 4, flex: "0 0 " + (item.line.length / 20) + "px"}} />);
        }
        return content;
    };


    render() {

        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;

        return (
            <Screen>

                <Legend>Debug</Legend>

                {configuration.availableLines && Object.entries(configuration.availableLines).map(([key, line]) => (
                    <div>
                        {line.length} mm, {line.price} EUR ({Object.entries(line.items).map(([i, item]) => (
                            <>{item.qty}x {item.line.length}{line.items.length-1 != i ? ", " : ""}</>
                        ))})
                        <div style={{display: "flex", overflowX: "show", marginBottom: 12}}>
                            {Object.entries(line.items).map(([i, item]) => (
                                <>{this.getLineIllustrationContent(item)}</>
                            ))}
                        </div>
                    </div>
                ))}


            </Screen>
        )
    }
}

const SelectionRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Debug);

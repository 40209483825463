import React, { PureComponent } from 'react';

import styled from 'styled-components';


class SelectColor extends PureComponent {

    render() {

        const {
            backgroundColor,
            backgroundImage,
            label,
            active,
            ...props
        } = this.props;


        const Container = styled.div`
            
        `;
        const Element = styled.div`
            display: inline-block;
            border: 2px solid ${active ? "#e45200" : "#DDDDDD"};
            border-radius: 50%;
            background: ${backgroundColor ? backgroundColor : "transparent"} ${backgroundImage} center no-repeat;
            cursor: pointer;
            width: 26px;
            height: 26px;
            margin: 0 4px 0 0;
        `;


        return (
            <Container {...props}>
                <Element />
            </Container>
        );
    }

}


export default SelectColor;

import * as ProductService from '../services/ProductService'

export const SAVE_CONFIGURATION_REQUEST = 'SAVE_CONFIGURATION_REQUEST';
export const SAVE_CONFIGURATION_SUCCESS = 'SAVE_CONFIGURATION_SUCCESS';
export const SAVE_CONFIGURATION_FAILURE = 'SAVE_CONFIGURATION_FAILURE';
export const SAVE_CONFIGURATION_SILENT_REQUEST = 'SAVE_CONFIGURATION_SILENT_REQUEST';
export const SAVE_CONFIGURATION_SILENT_SUCCESS = 'SAVE_CONFIGURATION_SILENT_SUCCESS';
export const SAVE_CONFIGURATION_SILENT_FAILURE = 'SAVE_CONFIGURATION_SILENT_FAILURE';

export function saveConfigurationRequest() {
    return {
        type: SAVE_CONFIGURATION_REQUEST,
    }
}

export function saveConfigurationSuccess(payload) {
    return {
        type: SAVE_CONFIGURATION_SUCCESS,
        payload
    }
}

export function saveConfigurationFailure(payload) {
    return {
        type: SAVE_CONFIGURATION_FAILURE,
        payload
    }
}

export function saveConfiguration(configuration) {
    return (dispatch) => {
        dispatch(saveConfigurationRequest());
        const configurationHash = ProductService.getConfigurationHash(configuration);

        ProductService.saveConfiguration(configuration)
            .then(response => {

                if (response.status === 200 && response.data.error === false && response.data?.configurationKey) {
                    dispatch(saveConfigurationSuccess({
                        configurationKey: response.data.configurationKey,
                        configurationHash: configurationHash,
                    }));
                }
                else {
                    dispatch(saveConfigurationFailure());
                }

            })
            .catch(error => {
                dispatch(saveConfigurationFailure());
            });
    }
}


export function saveConfigurationSilentRequest() {
    return {
        type: SAVE_CONFIGURATION_SILENT_REQUEST
    };
}

export function saveConfigurationSilentSuccess(payload) {
    return {
        type: SAVE_CONFIGURATION_SILENT_SUCCESS,
        payload
    }
}

export function saveConfigurationSilentFailure(payload) {
    return {
        type: SAVE_CONFIGURATION_SILENT_FAILURE,
        payload
    }
}

export function saveConfigurationSilent(configuration) {
    return (dispatch) => {
        dispatch(saveConfigurationSilentRequest());
        const configurationHash = ProductService.getConfigurationHash(configuration);

        ProductService.saveConfiguration(configuration, true)
            .then(response => {

                if (response.status === 200 && response.data.error === false && response.data?.configurationKey) {
                    dispatch(saveConfigurationSilentSuccess({
                        configurationKey: response.data.configurationKey,
                        configurationHash: configurationHash,
                    }));
                }
                else {
                    dispatch(saveConfigurationSilentFailure());
                }

            })
            .catch(error => {
                dispatch(saveConfigurationSilentFailure());
            });
    }
}

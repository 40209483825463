import React, { PureComponent } from 'react';

import "./selectOutput.css";
import mergeClassNames from 'classnames';

import High from "../assets/Icons/high.svg";
import HighActive from "../assets/Icons/highActive.svg";
import Low from "../assets/Icons/low.svg";
import LowActive from "../assets/Icons/lowActive.svg";
import LowDisabled from "../assets/Icons/lowDisabled.svg";


class SelectOutput extends PureComponent {

    render() {

        const {
            onClickLow,
            onClickHigh,
            value,
            locked,
        } = this.props;

        return (
            <div className={"selectOutput"}>
                <div className={mergeClassNames({
                    'item': true,
                    'active': value === "LOW",
                    'disabled': locked
                })} onClick={locked ? null : onClickLow}>
                    Low
                    <img alt="LOW" src={locked ? LowDisabled : value === "LOW" ? LowActive : Low} className={"icon"} />
                </div>
                <div className={mergeClassNames({
                    'item': true,
                    'active': value === "HI"
                })} onClick={locked ? null : onClickHigh}>
                    High
                    <img alt="HI" src={value === "HI" ? HighActive : High} className={"icon"} />
                </div>
            </div>
        );
    }

}


export default SelectOutput;

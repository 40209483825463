import DownloadArticleList from "../containers/DownloadArticleList";

const pathSegmentsByLanguage = {
    de: {
        ProductSelection: "produkt",
        FormSelection: "form",
        LineInput: "abmessung",
        Configurator: "konfigurator",
        Result: "ergebnis",
        Debug: "debug",
        LoadConfiguration: "lade",
        DownloadArticleList: "downloadCSV",
    },
    en: {
        ProductSelection: "product",
        FormSelection: "form",
        LineInput: "dimensions",
        Configurator: "configurator",
        Result: "result",
        Debug: "debug",
        LoadConfiguration: "load",
        DownloadArticleList: "downloadCSV",
    },
};


export const getPathForContainer = (language, container, abs = false, additional = false) => {
    let path = "/" + language + "/";

    switch (container) {
        case "ProductSelection":
        case "FormSelection":
        case "LineInput":
        case "Configurator":
        case "Result":
        case "Debug":
        case "LoadConfiguration":
        case "DownloadArticleList":
            path += pathSegmentsByLanguage[language][container] + "/";
            break;

        case "Home":
        default:
            break;
    }

    if (abs) {
        path = getBaseHost() + path;
    }

    if (additional) {
        path += additional;
    }

    return path;
};


export const getContainerFromPath = (path) => {
    let parts = path.split("/"),
        container = "Home";

    if (parts.length < 3 && parts.length > 4) {
        return container;
    }

    Object.entries(pathSegmentsByLanguage[parts[1]]).map(item => {
        if (item[1] === parts[2]) {
            container = item[0];
        }
    });

    return container;

};


export const getLanguageFromPath = (path) => {
    let parts = path.split("/");

    if (parts.length < 2 && parts[1].length !== 2) {
        return null;
    }

    return parts[1];

};


/*
 * @ToDo: Implement shortlink generation
 */
export const getShortlink = (language, configurationKey) => {
    return getPathForContainer(language, "LoadConfiguration", true, configurationKey);
};

export const getBaseHost = () => {
    return window?.location ? window.location.protocol + "//" + window.location.host : "/";
};


export const isContainerAllowed = (container, configuration) => {

    switch (container) {

        case "ProductSelection":
            return true;

        case "FormSelection":
            return configuration.selectedAttributes.mountingType !== false && configuration.selectedAttributes.opticsSimple !== false;

        case "LineInput":
            return configuration.selectedAttributes.form !== false;

        case "Configurator":
            return isFormFinalized(configuration) && configuration.items.length > 0;

        case "Result":
            return configuration.items.length > 0;
            
        default:
            return true;
    }
};



export const isFormFinalized = (configuration) => {
    const lineLengths = configuration.layout.lineLengths;

    switch (configuration.selectedAttributes.form) {
        case "Linear":
            return lineLengths[0]?.length > 0;
        case "L-Form":
            return lineLengths[0]?.length > 0 && lineLengths[1]?.length > 0;
        case "U-Form":
        case "S-Form":
            return lineLengths[0]?.length > 0 && lineLengths[1]?.length > 0 && lineLengths[2]?.length > 0;
        case "Karree":
            return lineLengths[0]?.length > 0 && lineLengths[1]?.length > 0;
        default:
            return false;
    }
};

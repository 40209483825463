import React, { PureComponent } from 'react';
import styled from 'styled-components';


class TextLink extends PureComponent {
    render() {

        const {
            label,
            primary,
            light,
            disabled,
            onClick,
            ...props
        } = this.props;


        const Element = styled.span`
            display: inline-block;
            padding: 10px 0;
            margin: -10px 24px -10px 0;
            color: ${primary ? "#E45200" : "#999999"};
            opacity: ${disabled ? .5 : 1};
            font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
            font-weight: ${light ? 400 :600}; 
            font-size: 14px; 
            line-height: 16px;
            text-transform: uppercase;
            cursor: ${disabled ? "not-allowed" : "pointer"};
            a {
                color: inherit;
            }
        `;


        return (
            <Element onClick={disabled ? null : onClick} {...props}>{label}</Element>
        );
    }
}


export default TextLink;

import React, { PureComponent } from 'react';
import styled from 'styled-components';


class Kicker extends PureComponent {
    render() {
        const {
            children,
            ...props
        } = this.props;

        return (
            <Element {...props}>{children}</Element>
        );
    }
}


const Element = styled.div`
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    color: #999999;	
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
    text-transform: uppercase;
`;


export default Kicker;

import React, { Component } from 'react';
import {connect} from 'react-redux';

import styled from 'styled-components';

import Header from './Header';
import Pager from './Pager';
import StatusBar from "./StatusBar";


const mapStateToProps = (state) => {
    return {
        isLoading: state.isLoading,
    };
};

class Screen extends Component {

    render() {

        const {
            children,
            beforeNextFunction,
        } = this.props;
        let {
            pager,
        } = this.props;

        if (pager === undefined) {
            pager = true;
        }

        return (
            <Container className="Configurator">

                <Header/>

                {this.props.isLoading ?

                    <LoadingContainer>Lade...</LoadingContainer>
                    :

                    <ContentRow>

                        <Content>
                            {pager && <Pager/>}

                            {children}
                        </Content>

                        <Sidebar>
                            <StatusBar beforeNextFunction={beforeNextFunction} />
                        </Sidebar>

                    </ContentRow>

                }

            </Container>
        );
    }
}

const Container = styled.div`
    position: relative;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
`;
const ContentRow = styled.div`
    height: calc(100% - 90px);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;
const Content = styled.div`
    flex: 1 1 auto;
    max-height: 100%;
    
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
`;
const Sidebar = styled.div`
    flex: 0 0 340px;
    overflow-y: auto;
    max-height: 100%;
    
    display: flex;
    flex-direction: column;
    padding: 20px 30px 0;
    box-sizing: border-box;
    border-left: 1px solid #DDDDDD;
`;

const LoadingContainer = styled.div`
    margin: 120px auto 0;
    text-align: center;
    flex: 0 0 auto;
`;

export default connect(mapStateToProps)(Screen);

import React, { PureComponent } from 'react';

import styled from 'styled-components';


class SelectForm extends PureComponent {

    render() {

        const {
            image,
            label,
            active,
            flipX,
            flipY,
            ...props
        } = this.props;


        const Container = styled.div`
            width: 33%;
        `;
        const Element = styled.div`
            position: relative;
            margin: 0 5px 5px;
            border: 1px solid ${active ? "#e45200" : "transparent"};
            cursor: pointer;
            :hover {
                border-color: #e45200;
            }
        `;
        const Label = styled.div`
            padding: 10px 12px;
            font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: ${active ? "#e45200" : "#444444"};
            ${Container}:hover & {
                color: #e45200;
            }
        `;
        const ImageContainer = styled.div`
            position: relative;
            height: 0;
            padding-bottom: 40%;
            margin: 20px 10px;
        `;
        const Image = styled.img`
            display: block;
            position: absolute;
            top: 0;
            left: 10%;
            width: 80%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            transform: scaleX(1) scaleY(1);
            
            ${Container}:hover & {
                transform: scaleX(${flipX ? -1 : 1}) scaleY(${flipY ? -1 : 1});
            }
        `;


        return (
            <Container>
                <Element {...props}>

                    <Label>{label}</Label>
                    <ImageContainer>
                        <Image src={image} />
                    </ImageContainer>

                </Element>
            </Container>
        );
    }

}


export default SelectForm;

import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';


class Backlink extends PureComponent {
    render() {

        const {
            label,
            ...props
        } = this.props;

        return (
            <Element {...props}>
                <Icon>
                    <FontAwesomeIcon color={"#C0C0C0"} icon={["fal", "angle-left"]} />
                </Icon> {label ? label : "Zurück"}
            </Element>
        );
    }
}

const Element = styled.span`
    display: inline-block;
    color: #C0C0C0;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    padding: .75rem 1rem;
    margin: 0 -1rem 1rem;
`;
const Icon = styled.span`
    font-size: 16px;
    line-height: 16px;
    margin-right: .2em;
    vertical-align: -2px;
`;


export default Backlink;

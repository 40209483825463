import React, { Component } from 'react';

class End extends Component {

    render() {
        const {
            article,
            orientation,
            x,
            y,
            stroke,
            width,
        } = this.props;

        let rotation = (orientation - 90 + 360) % 360;
        let translation;

        let isVertical = false;

        switch (rotation) {
            case 90:
                translation = "0, -" + 2 * width;
                isVertical = true;
                break;
            case 180:
                translation = width + ", 0";
                break;
            case 270:
                translation = "0, " + width;
                isVertical = true;
                break;
            default:
                translation = -2 * width + ", 0";
        }

        return (
            <g transform={"translate(" + x + ", " + y + ")"} width={width} height={width}>
                <g transform={"translate(" + translation + ")"}>
                    {/* This is the font awesome solid bolt icon, but it needs to be inline and scaled down to match the configurators dimensions */}
                    <svg version="1.1" id="IconBolt" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} height={width} viewBox="0 0 50 80">
                        <path d="M46.2,25h-18l6.7-20.3C35.5,2.3,33.7,0,31.2,0H8.7C6.9,0,5.3,1.4,5,3.2L0,40.8C-0.3,43,1.5,45,3.7,45h18.5l-7.2,30.4
        c-0.6,2.4,1.2,4.6,3.6,4.6c1.3,0,2.6-0.7,3.2-1.9l27.5-47.5C50.9,28.1,49.1,25,46.2,25z" fill={stroke} />
                    </svg>
                </g>
            </g>
        );
    }
}

export default End;

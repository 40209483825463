export const CHANGE_LAYOUT_FLIP_X = 'CHANGE_LAYOUT_FLIP_X';
export const CHANGE_LAYOUT_FLIP_Y = 'CHANGE_LAYOUT_FLIP_Y';
export const CHANGE_LAYOUT_ROTATION = 'CHANGE_LAYOUT_ROTATION';
export const CHANGE_LAYOUT_POWERSUPPLYLOCATION = 'CHANGE_LAYOUT_POWERSUPPLYLOCATION';
export const CHANGE_LAYOUT_LINE_LENGTHS = 'CHANGE_LAYOUT_LINE_LENGTHS';


export function changeLayoutFlipX(payload) {
    return {
        type: CHANGE_LAYOUT_FLIP_X,
        payload
    }
}

export function changeLayoutFlipY(payload) {
    return {
        type: CHANGE_LAYOUT_FLIP_Y,
        payload
    }
}

export function changeLayoutRotation(payload) {
    return {
        type: CHANGE_LAYOUT_ROTATION,
        payload
    }
}

export function changeLayoutPowerSupplyLocation(payload) {
    return {
        type: CHANGE_LAYOUT_POWERSUPPLYLOCATION,
        payload
    }
}

export function changeLayoutLineLengths(payload) {
    return {
        type: CHANGE_LAYOUT_LINE_LENGTHS,
        payload
    }
}

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import Configuration from './Configuration'
import Products from './Products'
import Language from './Language'

const rootReducer = (history) => combineReducers({
    configuration: Configuration,
    products: Products,
    language: Language,
    router: connectRouter(history),
});

export default rootReducer

import React, { PureComponent } from 'react';
import styled from 'styled-components';


class Input extends PureComponent {
    render() {
        const {
            type,
            defaultValue,
            ...props
        } = this.props;

        return (
            <Element type={type ?? "text"} defaultValue={defaultValue} {...props} />
        );
    }
}


const Element = styled.input`
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 1.2;
    padding: 4px 0;
    margin: 0 auto 16px;
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #DDDDDD;
    :active, :focus {
        border-bottom-color: #e45200;
    } 
    ::placeholder {
        color: #C0C0C0;
        font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    }
`;


export default Input;

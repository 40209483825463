import * as ActionTypes from '../actions'
import * as ProductService from '../services/ProductService';
import * as ItemService from '../services/ItemService';

const initialState = {
    configurationKey: false,
    configurationHash: false,
    isSaving: false,
    selectedAttributes: {
        mountingType: false,
        lightEngine: "Obsolete",
        opticsSimple: false,
        form: false,
        output: "LOW", // possible Options: HI, LOW
        color: "aen", // possible Options: aen, vw, sw
        dimmable: "ET", // possible Options: ET, DALI
    },
    availableLines: false,
    availableDiffusors: false,
    layout: {
        powerSupplyLocation: false,
        flipX: false,
        flipY: false,
        rotation: 0,
        lineLengths: {}
    },
    items: [],
    result: {
        isValid: false,
        price: false,
        power: false,
        weight: false,
        luminousFlux: false,
        powerSuppliesCount: false,
        diffusors: false,
        width: false,
        height: false,
    },
    meta: {
        projectName: "",
        supervisor: "",
        acronym: "",
        inquiryStatus: 0,
        downloadStatus: 0,
    }
};

const isFormValid = (state) => {
    switch (state.selectedAttributes.form) {
        case "Linear":
            return state.layout.lineLengths[0]?.length > 0;
        case "L-Form":
            return state.layout.lineLengths[0]?.length > 0 && state.layout.lineLengths[1]?.length > 0;
        case "U-Form":
        case "S-Form":
            return state.layout.lineLengths[0]?.length > 0 && state.layout.lineLengths[1]?.length > 0 && state.layout.lineLengths[2]?.length > 0;
        case "Karree":
            return state.layout.lineLengths[0]?.length > 0 && state.layout.lineLengths[1]?.length > 0;
        default:
            return false;
    }
};

const calculateResults = (state) => {

    let diffusorsItems = [];

    if (state.items && state.items.length && state.availableDiffusors && isFormValid(state)) {

        diffusorsItems = ProductService.getDiffusorsForItems(state.items, state.availableDiffusors);

    }

    if (state.items && state.items.length) {

        const articleList = ItemService.getArticleList(state.items, diffusorsItems);
        const totalDimensions = ItemService.getTotalDimension(state.items);
        return {
            ...state,
            result: {
                isValid: true,
                diffusors: diffusorsItems.length ? diffusorsItems : false,
                price: ProductService.reduceArticleListParameter(articleList, "price"),
                power: ProductService.reduceArticleListParameter(articleList, "systemPower"),
                weight: ProductService.reduceArticleListParameter(articleList, "weight"),
                luminousFlux: ProductService.reduceArticleListParameter(articleList, "luminousFlux"),
                powerSuppliesCount: ProductService.reduceArticleListParameter(articleList, "powerSuppliesCount"),
                width: totalDimensions.width,
                height: totalDimensions.height,
            }
        };
    }

    return {
        ...state,
        result: {
            isValid: false,
            diffusors: false,
            price: false,
            power: false,
            luminousFlux: false,
            powerSuppliesCount: false,
        }
    };
};

const filterProductsAndLines = (state, products) => {
    if (products &&
        state.selectedAttributes.mountingType &&
        state.selectedAttributes.opticsSimple &&
        state.selectedAttributes.output &&
        state.selectedAttributes.color &&
        state.selectedAttributes.dimmable
    ) {
        let lines = ProductService.filterProducts(products.lines, [{
            field: "mountingType",
            value: state.selectedAttributes.mountingType,
        }, {
            field: "opticsSimple",
            value: state.selectedAttributes.opticsSimple,
        }, {
            field: "dimmable",
            value: state.selectedAttributes.dimmable,
        }, {
            field: "output",
            value: state.selectedAttributes.output,
        }, {
            field: "color",
            value: state.selectedAttributes.color,
        }]);
        state.availableLines = ProductService.getAvailableLanes(lines, state.selectedAttributes.form !== "Linear");
        state.availableDiffusors = products.diffusors;
    }
    else {
        state.availableLines = false;
        state.availableDiffusors = false;
    }

    return state;
};


const Configuration = (state = initialState, action) => {
    let newState = false;
    switch(action.type) {

        case ActionTypes.CHANGE_META:
            newState = {...state};

            if (newState.meta[action.payload.name] !== undefined) {
                newState.meta[action.payload.name] = action.payload.value;
            }

            return newState;

        case ActionTypes.CHANGE_SELECTED_ATTRIBUTE:
            newState = {...state};

            if (newState.selectedAttributes[action.payload.attribute] !== undefined) {
                newState.selectedAttributes[action.payload.attribute] = action.payload.value;
            }

            if (action.payload.attribute === "mountingType") {
                newState.selectedAttributes.opticsSimple = false;
            }

            if (action.payload.attribute === "form") {
                newState.layout = {
                    ...initialState.layout
                };
                newState.items = initialState.items;
                newState.result = {
                    ...initialState.result
                };
            }

            newState = filterProductsAndLines(newState, action.products);

            return newState;


        case ActionTypes.CHANGE_LAYOUT_POWERSUPPLYLOCATION:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    powerSupplyLocation: action.payload
                },
            };
        case ActionTypes.CHANGE_LAYOUT_FLIP_X:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    flipX: action.payload
                },
            };
        case ActionTypes.CHANGE_LAYOUT_FLIP_Y:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    flipY: action.payload
                },
            };
        case ActionTypes.CHANGE_LAYOUT_ROTATION:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    rotation: action.payload
                },
            };
        case ActionTypes.CHANGE_LAYOUT_LINE_LENGTHS:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    lineLengths: action.payload
                },
            };

        case ActionTypes.CHANGE_ITEMS:
            newState = {
                ...state,
                items: action.payload,
            };

            newState = calculateResults(newState);
            return newState;


        case ActionTypes.DOWNLOAD_STATUS_CHANGE:
            newState = {
                ...state,
                meta: {
                    ...state.meta,
                    downloadStatus: action.payload.newStatus,
                }
            };
            return newState;


        case ActionTypes.SAVE_CONFIGURATION_REQUEST:
            return {
                ...state,
                isSaving: true,
            };
        case ActionTypes.SAVE_CONFIGURATION_FAILURE:
            return {
                ...state,
                isSaving: false,
            };
        case ActionTypes.SAVE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                configurationKey: action.payload.configurationKey,
                configurationHash: action.payload.configurationHash,
                isSaving: false,
            };

        case ActionTypes.SAVE_CONFIGURATION_SILENT_REQUEST:
            return {
                ...state,
                configurationKeyCopied: false,
            };
        case ActionTypes.SAVE_CONFIGURATION_SILENT_FAILURE:
            return {
                ...state,
                configurationKeyCopied: null,
            };
        case ActionTypes.SAVE_CONFIGURATION_SILENT_SUCCESS:
            return {
                ...state,
                configurationKeyCopied: action.payload.configurationKey,
            };


        case ActionTypes.LOAD_CONFIGURATION_SUCCESS:
            newState = {
                ...state,
                ...action.payload.configuration,
                configurationKey: action.payload.configurationKey,
            };

            newState = filterProductsAndLines(newState, action.payload.products);
            newState = calculateResults(newState);
            newState.configurationHash = ProductService.getConfigurationHash(newState);

            return newState;

        case ActionTypes.LOAD_CONFIGURATION_FAILURE:
            return state;

        case ActionTypes.CHANGE_INQUIRY_STATUS:
            newState = {
                ...state,
                meta: {
                    ...state.meta,
                    inquiryStatus: action.payload.newStatus,
                }
            };
            return newState;

        default:
            return state;
    }
};

export default Configuration;

import React, { Component } from 'react';
import {connect} from 'react-redux';

import styled from "styled-components";
import i18n from "i18n-js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../../assets/Images/logo_tagline.svg";

import Modal from "../../components/Modal";
import Button from "../../components/Button";
import TextLink from "../../components/TextLink";
import Legend from "../../components/Legend";
import Label from "../../components/Label";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";

import * as ActionTypes from "../../actions";
import * as ItemService from "../../services/ItemService";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        sendInquiry: (params) => dispatch(ActionTypes.sendInquiry(params)),
    };
};

class Inquiry extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            phone: "",
            note: "",
            projectName: this.props.configuration.meta.projectName ?? "",
            company: "",
            street: "",
            city: "",
            country: i18n.t('inquiry.field.country.defaultValue'),
            requestType: {
                technical: false,
                offer: false,
                salesForce: false,
            },
            dataProtection: false,
        }
    }

    componentDidUpdate() {

    };

    sendInquiry() {
        this.props.sendInquiry({
            formData: this.state,
            configuration: this.props.configuration,
            dimensions: ItemService.getDimensions(this.props.configuration.items),
            articleList: ItemService.getArticleList(this.props.configuration.items, this.props.configuration.result.diffusors),
            language: this.props.language.locale,
        });
    }

    onInputValueChange(key, value) {
        let newState = {
            ...this.state
        };
        newState[key] = value;
        this.setState(newState);
    }

    onRequestTypeChange(key, value) {
        let newState = {
            ...this.state
        };
        newState.requestType[key] = value;
        this.setState(newState);
    }

    isFormValid() {
        const state = this.state;
        let isValid = true;

        if (state.name === "" || state.email === "" || state.company === "" || state.projectName === "" || state.street === "" || state.city === "" || state.country === "") {
            isValid = false;
        }
        else if (!state.requestType.technical && !state.requestType.offer && !state.requestType.salesForce) {
            isValid = false;
        }
        else if (!state.dataProtection) {
            isValid = false;
        }

        return isValid;
    }


    render() {

        let {
            onClose,
        } = this.props;

        const state = this.state,
            isValid = this.isFormValid();

        return (
            <Modal medium isVisible={true}>

                <Logo src={logo} title="Lichtwerk" alt="Lichtwerk Logo" />

                <Legend style={{marginBottom: 36}}>{i18n.t('inquiry.title')}</Legend>

                <Label>{i18n.t('inquiry.field.name.label')}*</Label>
                <Input required value={state.name} onInput={(event) => this.onInputValueChange("name", event.target.value)} placeholder={i18n.t('inquiry.field.name.placeholder')} />

                <Row>
                    <Col>
                        <Label>{i18n.t('inquiry.field.email.label')}*</Label>
                        <Input required type={"email"} onInput={(event) => this.onInputValueChange("email", event.target.value)} placeholder={i18n.t('inquiry.field.email.placeholder')} />
                    </Col>
                    <Col>
                        <Label>{i18n.t('inquiry.field.phone.label')}</Label>
                        <Input type={"phone"} onInput={(event) => this.onInputValueChange("phone", event.target.value)} placeholder={i18n.t('inquiry.field.phone.placeholder')} />
                    </Col>
                </Row>

                <Label style={{marginTop: 24}}>{i18n.t('inquiry.field.projectName.label')}*</Label>
                <Input onInput={(event) => this.onInputValueChange("projectName", event.target.value)} placeholder={i18n.t('inquiry.field.projectName.placeholder')} defaultValue={this.state.projectName} />

                <Label>{i18n.t('inquiry.field.note.label')}</Label>
                <TextArea placeholder={i18n.t('inquiry.field.note.placeholder')}
                          onInput={(event) => this.onInputValueChange("note", event.target.value)}
                />

                <Row style={{marginTop: 32}}>
                    <Col>
                        <Label>{i18n.t('inquiry.field.company.label')}*</Label>
                        <Input required onInput={(event) => this.onInputValueChange("company", event.target.value)} placeholder={i18n.t('inquiry.field.company.placeholder')} />
                    </Col>
                    <Col>
                        <Label>{i18n.t('inquiry.field.street.label')}*</Label>
                        <Input required onInput={(event) => this.onInputValueChange("street", event.target.value)} placeholder={i18n.t('inquiry.field.street.placeholder')} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>{i18n.t('inquiry.field.city.label')}*</Label>
                        <Input required onInput={(event) => this.onInputValueChange("city", event.target.value)} placeholder={i18n.t('inquiry.field.city.placeholder')} />
                    </Col>
                    <Col>
                        <Label>{i18n.t('inquiry.field.country.label')}*</Label>
                        <Input required onInput={(event) => this.onInputValueChange("country", event.target.value)} defaultValue={i18n.t('inquiry.field.country.defaultValue')} placeholder={this.state.country} />
                    </Col>
                </Row>

                <Label style={{marginTop: 32, marginBottom: 16}}>{i18n.t('inquiry.field.requestType.label')}*</Label>
                <Row>
                    <Col onClick={() => this.onRequestTypeChange("technical", !state.requestType.technical)}>
                        <Checkbox active={state.requestType.technical}><FontAwesomeIcon icon={["fal", "check"]} /></Checkbox>
                        <CheckboxLabel>{i18n.t('inquiry.field.requestType.option.technical')}</CheckboxLabel>
                    </Col>
                    <Col onClick={() => this.onRequestTypeChange("offer", !state.requestType.offer)}>
                        <Checkbox active={state.requestType.offer}><FontAwesomeIcon icon={["fal", "check"]} /></Checkbox>
                        <CheckboxLabel>{i18n.t('inquiry.field.requestType.option.offer')}</CheckboxLabel>
                    </Col>
                    <Col onClick={() => this.onRequestTypeChange("salesForce", !state.requestType.salesForce)}>
                        <Checkbox active={state.requestType.salesForce}><FontAwesomeIcon icon={["fal", "check"]} /></Checkbox>
                        <CheckboxLabel>{i18n.t('inquiry.field.requestType.option.salesForce')}</CheckboxLabel>
                    </Col>
                </Row>

                <Label style={{marginTop: 24}}>{i18n.t('inquiry.field.dataProtection.label')}*</Label>
                <Checkbox onClick={() => this.onInputValueChange("dataProtection", !state.dataProtection)} active={state.dataProtection}><FontAwesomeIcon icon={["fal", "check"]}/></Checkbox>
                <CheckboxLabel onClick={() => this.onInputValueChange("dataProtection", !state.dataProtection)}>{i18n.t('inquiry.field.dataProtection.info')}*</CheckboxLabel>
                <div style={{paddingLeft: 28, fontSize: 14}}>
                    <a href={i18n.t('footer.dataProtection.link')} target="_blank" rel="noopener noreferrer">{i18n.t('inquiry.field.dataProtection.openDataProtection')}</a>
                </div>


                <Row style={{alignItems: "flex-end", marginTop: 32}}>
                    <Col>
                        <TextLink onClick={onClose} label={i18n.t('action.cancel')} style={{marginBottom: 8}} />
                    </Col>
                    <Col style={{textAlign: "right"}}>
                        <p style={{margin: "0 0 8px", fontSize: ".75rem"}}>* {i18n.t("inquiry.requiredInfo")}</p>
                        <Button onClick={() => this.sendInquiry()} label={i18n.t('action.request')} disabled={!isValid} primary wide filled />
                    </Col>
                </Row>

            </Modal>
        )
    }
}

const Logo = styled.img`
    float: right;
    height: 32px;
    width: auto;
`;

const Row = styled.div`
    display: flex;
    margin: 0 -15px;
    flex-wrap: wrap;
`;
const Col = styled.div`
    flex: 0 1 50%;
    width: 50%;
    padding: 0 15px;
    box-sizing: border-box;
`;

const Checkbox = styled.div`
    display: inline-block;
    cursor: pointer;
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
    color: ${props => props.active ? "white" : "transparent"};
    background-color: ${props => props.active ? "#E45200" : "transparent"};
    border: 1px solid ${props => props.active ? "#E45200" : "#999999"};
    text-align: center;
    font-size: 12px;
    line-height: 1;
    padding: 2px 0 0;
    box-sizing: border-box;
    margin-right: 8px;
`;
const CheckboxLabel = styled.div`
    display: inline-block;
    cursor: pointer;
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 23px;
`;


export default connect(mapStateToProps, mapDispatchToProps)(Inquiry);

import React, { PureComponent } from 'react';

class SimpleLine extends PureComponent {
    render() {
        const {
            orientation,
            x,
            y,
            length,
            width
        } = this.props;

        let rotation = (orientation - 90 + 360) % 360;
        let translation;

        switch (rotation) {
            case 90:
                translation = "0, -"+width;
                break;
            case 180:
                translation = "-"+length+", -"+width;
                break;
            case 270:
                translation = "-"+length+", 0";
                break;
            default:
                translation = "0, 0";
        }

        return (
            <svg x={x} y={y}>
                <g transform={"rotate("+rotation+") translate("+translation+")"}>
                    <rect
                        x={0}
                        y={0}
                        width={length}
                        height={width}
                        fill="#888888" />
                </g>
            </svg>
        );
    }
}

export default SimpleLine;

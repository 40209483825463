import axios from "axios";

import * as Const from "../Const";


export const fetchLanguage = (language) => {
    let url = Const.ASSET_URL + "fileadmin/serio/sprache/" + language +".json";

    return new Promise((resolve, reject) => {

        axios.get(url, {
            cache: false
        })
            .then(response => { resolve(response) })
            .catch(error => { reject(error) });

    });
};

import React, { Component } from 'react';

class CanvasMiniature extends Component {

    render() {
        return (
            <div>

            </div>
        )
    }
}

export default (CanvasMiniature);

import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import cloneDeep from 'clone-deep'
import styled from 'styled-components';
import i18n from 'i18n-js';

import TooltipTrigger from 'react-popper-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class FormLengthInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            valueInitial: props.value ? props.value : 0,
            valueSelected: props.value ? props.value : false,
            valueCurrent: props.value ? props.value : false,
            prevLength: false,
            nextLength: false,
            availableLines: props.availableLines,
            showTooltip: false,
        };

    }

    componentDidMount() {

    };

    componentDidUpdate(prevProps) {
        if (prevProps.availableLines !== this.props.availableLines) {
            this.setState({
                ...this.state,
                availableLines: this.props.availableLines
            });
        }
    }

    getSiblingLengths = () => {
        let prevLength = false,
            nextLength = false,
            isExactMatch = false,
            availableLines = cloneDeep(this.state.availableLines);

        if (this.state.valueCurrent !== false && availableLines !== false) {
            availableLines.forEach((line) => {

                if (!line)
                    return;

                if (line.length < this.state.valueCurrent && (prevLength === false || line.length > prevLength)) {
                    prevLength = line.length;
                }

                if (line.length === this.state.valueCurrent) {
                    isExactMatch = true;
                }

                if (line.length > this.state.valueCurrent && (nextLength === false || line.length < nextLength)) {
                    nextLength = line.length;
                }

            });
        }

        return {
            prevLength: prevLength,
            isExactMatch: isExactMatch,
            nextLength: nextLength,
        }
    };


    onValueChange = (newValue) => {

        newValue = newValue.floatValue;

        // Don't change the value if new and selected values are the same
        // This prevents unnecessary value changes after the value was changed via click inside the tooltip
        if (newValue === this.state.valueSelected) {
            return false;
        }

        let matchesAvailableLine = false;
        this.state.availableLines.forEach((line) => {
            if (line.length === newValue) {
                matchesAvailableLine = true;
                return false;
            }
        });

        if (matchesAvailableLine) {
            this.setState({
                ...this.state,
                valueCurrent: newValue,
                valueSelected: newValue,
                showTooltip: true,
            });
        }
        else {
            this.setState({
                ...this.state,
                valueCurrent: newValue,
                showTooltip: true,
            });
        }

        this.props.onValueChange(newValue);
    };

    onValueSelected = (newValue) => {
        this.setState({
            ...this.state,
            valueCurrent: newValue,
            valueSelected: newValue,
            showTooltip: false,
        });

        if (this.props.onValueChange) {
            this.props.onValueChange(newValue);
        }
    };

    handleFocus = (event) => {
        event.target.select();

        this.setState({
            ...this.state,
            showTooltip: true
        });
    };


    render() {
        const {valueSelected, valueCurrent, showTooltip} = this.state;
        const {placeholder, laneNumber, autofocus} = this.props;
        const {prevLength, isExactMatch, nextLength} = this.getSiblingLengths();

        const TooltipContent = ({arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement}) => (
            <Element {...getTooltipProps({
                ref: tooltipRef,
                className: 'tooltip-container'
            })}>
                <Content>

                    <Close onClick={() => this.setState({...this.state, showTooltip: false})}>
                        <FontAwesomeIcon color={"#999999"} icon={["fal", "times"]} />
                    </Close>

                    <Heading>{i18n.t("title.lengthMeasure")} {laneNumber}</Heading>
                    <div>
                        {prevLength && <NumberFormat value={prevLength} displayType={'text'}
                            thousandSeparator={"."} decimalSeparator={","} decimalScale={0} suffix={" mm"}
                            renderText={(formattedValue) => <Length onClick={() => this.onValueSelected(prevLength)}>{formattedValue}</Length>}
                        />}
                        {valueSelected === valueCurrent && <NumberFormat value={valueSelected} displayType={'text'}
                            thousandSeparator={"."} decimalSeparator={","} decimalScale={0} suffix={" mm"}
                            renderText={(formattedValue) => <LengthActive onClick={() => this.onValueSelected(valueCurrent)}>{formattedValue}</LengthActive>}
                        />}
                        {valueSelected !== valueCurrent && isExactMatch && <NumberFormat value={valueCurrent} displayType={'text'}
                            thousandSeparator={"."} decimalSeparator={","} decimalScale={0} suffix={" mm"}
                            renderText={(formattedValue) => <Length onClick={() => this.onValueSelected(valueCurrent)}>{formattedValue}</Length>}
                        />}
                        {nextLength && <NumberFormat value={nextLength} displayType={'text'}
                            thousandSeparator={"."} decimalSeparator={","} decimalScale={0} suffix={" mm"}
                            renderText={(formattedValue) => <Length onClick={() => this.onValueSelected(nextLength)}>{formattedValue}</Length>}
                        />}
                    </div>

                    <TooltipArrow {...getArrowProps({
                        ref: arrowRef,
                        'data-placement': placement
                    })} />
                </Content>
            </Element>
        );
        const Trigger = ({getTriggerProps, triggerRef}) => (
            <InputWrap {...getTriggerProps({
                ref: triggerRef,
            })}>

                <NumberFormat value={this.state.valueCurrent} placeholder={placeholder} displayType={'input'}
                              thousandSeparator={"."} decimalSeparator={","} decimalScale={0} suffix={" mm"}
                              onValueChange={(value) => this.onValueChange(value)}
                              onFocus={this.handleFocus} autoFocus={!!autofocus}
                />

                <StatusIcon>
                    {valueCurrent > 0 && valueSelected === valueCurrent ?
                        <FontAwesomeIcon color={"#e45200"} icon={["fal", "check"]} /> :
                        <FontAwesomeIcon color={"#999999"} icon={["fal", "pen"]} />
                    }
                </StatusIcon>

            </InputWrap>
        );

        return (
            <Container>

                {laneNumber && <LaneInformation>{i18n.t("title.laneShort")}{laneNumber}</LaneInformation>}

                <TooltipTrigger placement="top-start" trigger="none" tooltipShown={showTooltip && (prevLength || nextLength)} tooltip={TooltipContent}>
                    {Trigger}
                </TooltipTrigger>

            </Container>
        );
    }

}


const Container = styled.span`
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
`;

const Element = styled.div`
    z-index: 110;
    width: 260px;
    display: flex;
    flex-direction: column;
    //transition: opacity .3s ease-in;
`;
const Content = styled.div`
    background-color: #FFFFFF;
    padding: 12px 16px;
    position: relative;
    bottom: 14px;
    left: -65px;
    filter: drop-shadow(0 2px 6px hsla(0, 0%, 0%, 0.1));
`;
const TooltipArrow = styled.div`
    position: absolute;
    bottom: -12px;
    left: 50% !important;
    transform: translateX(-50%);
    height: 12px;
    width: 16px;
    ::after {
        content: '';
        position: absolute;
        top: 0;
        margin: auto;
        border-style: solid;
        border-color: #FFFFFF transparent transparent transparent;
        border-width: 10px 10px 0 10px;
    }
`;

const Heading = styled.div`
    color: #444444;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 14px;
`;
const Length = styled.span`
    display: inline-block;
    color: #999999;
    font-size: 11px;
    line-height: 11px;
    padding: 6px 8px;
    border: 1px solid #DDDDDD;
    cursor: pointer;
    margin: 0 2px 2px 0;
    :hover {
        color: #E45200;
        border-color: #E45200;
    }
`;
const LengthActive = styled(Length)`
    color: #E45200;
    border-color: #E45200;
    cursor: default;
`;
const Close = styled.span`
    float: right;
    font-size: 14px;
    padding: 8px;
    margin: -8px;
    cursor: pointer;
`;

const StatusIcon = styled.span `
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 14px;
    line-height: 1;
    pointer-events: none;
`;

const LaneInformation = styled.div`
    margin-right: 8px;
    font-weight: bold;
    color: #C0C0C0;
`;

const InputWrap = styled.div`
    position: relative;
    width: 140px;
    input {
        width: 100%;
        box-sizing: border-box;
        background-color: #F5F5F5;
        padding: 6px 32px 5px 12px;
        border: none;
        border-bottom: 1px solid #f5f5f5;
        outline: none;
        color: #999999;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        :focus {
            border-bottom-color: #e45200;
            + ${StatusIcon} svg {
                color: #e45200 !important;
            }
        }
    }
`;



export default FormLengthInput;

import * as LanguageService from '../services/LanguageService'

export const FETCH_LANGUAGE_REQUEST = 'FETCH_LANGUAGE_REQUEST';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
export const FETCH_LANGUAGE_FAILURE = 'FETCH_LANGUAGE_FAILURE';

export function fetchLanguageRequest() {
    return {
        type: FETCH_LANGUAGE_REQUEST,
    }
}

export function fetchLanguageSuccess(payload) {
    return {
        type: FETCH_LANGUAGE_SUCCESS,
        payload
    }
}

export function fetchLanguageFailure(payload) {
    return {
        type: FETCH_LANGUAGE_FAILURE,
        payload
    }
}

export function fetchLanguage(language) {
    return (dispatch) => {
        dispatch(fetchLanguageRequest());

        LanguageService.fetchLanguage(language)
            .then(response => {

                if (response.status === 200 && response.data) {
                    dispatch(fetchLanguageSuccess({
                        language: language,
                        data: response.data,
                    }));
                }
                else {
                    dispatch(fetchLanguageFailure({
                        language: language,
                        data: null,
                    }));
                }

            })
            .catch(error => {
                dispatch(fetchLanguageFailure({
                    language: language,
                    data: false,
                }));
            });
    }
}

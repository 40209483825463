import React, {Component} from 'react';
import { Route, Redirect } from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'

import * as ActionTypes from "./actions";
import * as LinkService from "./services/LinkService";

import Home from "./containers/Home";
import ProductSelection from "./containers/ProductSelection";
import FormSelection from "./containers/FormSelection";
import LineInput from "./containers/LineInput";
import DownloadArticleList from "./containers/DownloadArticleList";
import LoadConfiguration from "./containers/LoadConfiguration";
import Configurator from "./containers/Configurator";
import Result from "./containers/Result";
import Debug from "./containers/Debug";

import Splashscreen from "./components/Splashscreen";

import './assets/StyleSheets/fonts.css';
import './assets/StyleSheets/main.css';

import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowCircleRight, faTimes, faAngleLeft, faCheck, faChevronLeft, faChevronRight, faExternalLinkAlt, faArrowToBottom, faFileExport, faFile, faFileCsv, faFilePdf, faFileDownload, faFileAlt, faFileArchive, faDownload, faInfoCircle, faPlus, faLink, faMinus, faBinoculars, faUndo, faRedo, faHandPointer, faMousePointer, faGlobe, faSave, faPen } from '@fortawesome/pro-light-svg-icons';
import { faBolt, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';

library.add( faArrowCircleRight, faTimes, faAngleLeft, faCheck, faChevronLeft, faChevronRight, faExternalLinkAlt, faArrowToBottom, faFileExport, faFile, faFileCsv, faFilePdf, faFileDownload, faFileAlt, faFileArchive, faDownload, faInfoCircle, faPlus, faLink, faMinus, faBinoculars, faUndo, faRedo, faHandPointer, faMousePointer, faGlobe, faSave, faPen );
library.add( faBolt, faTrashAlt );



const mapStateToProps = (state) => {
    return {
        language: state.language,
        location: state.router.location,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchLanguage: (params) => dispatch(ActionTypes.fetchLanguage(params)),
        changeLanguage: (params) => dispatch(ActionTypes.changeLanguage(params)),
    };
};


class App extends Component {

    propTypes = {
        history: PropTypes.object,
    };

    constructor(props) {
        super(props);

        props.fetchLanguage("de");
        props.fetchLanguage("en");

        const initLanguage = LinkService.getLanguageFromPath(this.props.location.pathname);
        if (initLanguage && (initLanguage === "de" || initLanguage === "en")) {
            this.props.changeLanguage(initLanguage);
        }
    }

    render() {

        const {history, language} = this.props;

        let label = false;
        if (language.translations.de === null || language.translations.en === null) {
            label = "Error while loading languages, please try again.";
        }

        return (

            !language.translations.de || !language.translations.en ?
                <Splashscreen label={label} />
                :
                <ConnectedRouter history={history}>

                    <Route path={"/"} exact>
                        <Redirect from={"/"} to={LinkService.getPathForContainer(language.locale, "Home")} />
                    </Route>

                    <Route path={LinkService.getPathForContainer("de", "LoadConfiguration", false, ":configurationKey")} exact component={LoadConfiguration} />
                    <Route path={LinkService.getPathForContainer("en", "LoadConfiguration", false, ":configurationKey")} exact component={LoadConfiguration} />

                    <Route path={LinkService.getPathForContainer("de", "DownloadArticleList", false, ":configurationKey")} exact component={DownloadArticleList} />
                    <Route path={LinkService.getPathForContainer("en", "DownloadArticleList", false, ":configurationKey")} exact component={DownloadArticleList} />

                    <Route path={LinkService.getPathForContainer("de", "Home")} exact component={Home} />
                    <Route path={LinkService.getPathForContainer("en", "Home")} exact component={Home} />

                    <Route path={LinkService.getPathForContainer("de", "ProductSelection")} exact component={ProductSelection} />
                    <Route path={LinkService.getPathForContainer("en", "ProductSelection")} exact component={ProductSelection} />

                    <Route path={LinkService.getPathForContainer("de", "FormSelection")} exact component={FormSelection} />
                    <Route path={LinkService.getPathForContainer("en", "FormSelection")} exact component={FormSelection} />

                    <Route path={LinkService.getPathForContainer("de", "LineInput")} exact component={LineInput} />
                    <Route path={LinkService.getPathForContainer("en", "LineInput")} exact component={LineInput} />

                    <Route path={LinkService.getPathForContainer("de", "Configurator")} exact component={Configurator} />
                    <Route path={LinkService.getPathForContainer("en", "Configurator")} exact component={Configurator} />

                    <Route path={LinkService.getPathForContainer("de", "Result")} exact component={Result} />
                    <Route path={LinkService.getPathForContainer("en", "Result")} exact component={Result} />

                    <Route path={LinkService.getPathForContainer("de", "Debug")} exact component={Debug} />
                    <Route path={LinkService.getPathForContainer("en", "Debug")} exact component={Debug} />

                    {/*<Redirect from="/" to={LinkService.getPathForContainer(language.locale, "Home")} exact />*/}

                </ConnectedRouter>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

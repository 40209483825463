import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';


class LoadingIndicator extends PureComponent {
    render() {
        const {
            isVisible,
            info,
        } = this.props;

        return (
            <>
            {isVisible && <Container>
                <Content>

                    <SpinContainer>
                        <Square />
                    </SpinContainer>

                    <p>{info}</p>

                </Content>
            </Container>}
            </>
        );
    }
}


const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(5px);
`;
const Content = styled.div`
    background-color: white;
    text-align: center;
    width: 100%;
    max-width: 320px;
    overflow-y: auto;
    margin: 60px;
    padding: 20px 24px;
`;

const SpinContainer = styled.div`
    margin: 10px auto 16px;
`;


const SquareRotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(90deg);
    }
`;
const Square = styled.div`
    width: 18px;
    height: 18px;
    border: 1px solid #e45200;
    margin: 0 auto;
    animation: ${SquareRotation} .55s cubic-bezier(0.860, 0.000, 0.070, 1.000) 0s infinite;
`;

export default LoadingIndicator;

import React, { Component } from 'react';
import * as SvgService from "../../services/SvgService";

import ItemModal from "./ItemModal";
import styled from "styled-components";

class Corner extends Component {

    handleOnClick = (event) => {
        if (this.props.onClick) {
            this.props.onClick(this.props.itemKey, event);
        }
    };

    handleOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.props.itemKey);
        }
    };

    handleOnInsert = (position) => {
        if (this.props.onInsert) {
            this.props.onInsert(this.props.itemKey, position);
        }
    };

    handleOnClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const {
            item,
            stroke,
            article,
            active,
            orientation,
            direction,
            x,
            y,
            length,
            width,
            onClick
        } = this.props;

        let rotation = (orientation + 360) % 360;
        let translation;

        let isVertical = false;

        switch (rotation) {
            case 90:
                translation = "0, -"+length;
                isVertical = true;
                break;
            case 180:
                translation = "-"+length+", -"+length;
                break;
            case 270:
                translation = "-"+length+", 0";
                isVertical = true;
                break;
            default:
                translation = "0, 0";
        }

        let labelTransformation = "";
        if (rotation === 90 || rotation === 180) {
            labelTransformation = "rotate(180, " + (length * .5) + ", " + (width * -3) + ")";
        }
        let label2Transformation = "rotate(90, " + (length + width * 4.2) + ", " + (length * .5 - width) + ")";
        if (direction === "left" && (rotation === 0 || rotation === 90)) {
            label2Transformation = "rotate(270, " + (length + width * 3) + ", " + (length * .5 - width * -.2) + ")";
        }
        else if (direction === "right" && (rotation === 180 || rotation === 270)) {
            label2Transformation = "rotate(90, " + (width * -4.2) + ", " + (length * .5 - width) + ")";
        }
        else if (direction === "right" && (rotation === 0 || rotation === 90)) {
            label2Transformation = "rotate(270, " + (width * -2) + ", " + (length * .5 - width) + ")";
        }

        const fill = "#ffffff";

        return (
            <g transform={"translate(" + x + ", " + y + ")"} width={length} height={length}>

                <g transform={"rotate("+rotation+") translate("+translation+")"} style={{cursor: onClick && "pointer"}}>
                    {direction === "left" ?
                        <polygon
                            onClick={this.handleOnClick}
                            fill={fill} stroke={stroke}
                            points={"0,0 "
                             + length + ",0 "
                             + length + "," + length + " "
                             + (length - width) + "," + length + " "
                             + (length - width) + "," + width + " "
                             + "0," + width}
                        /> :
                        <polygon
                            onClick={this.handleOnClick}
                            fill={fill} stroke={stroke}
                            points={"0,0 "
                             + length + ",0 "
                             + length + "," + width + " "
                             + width + "," + width + " "
                             + width + "," + length + " "
                             + "0," + length}
                        />
                    }
    
                    {/* suspension point in corner */}
                    {direction === "left" ? <>
                        <line
                            onClick={this.handleOnClick}
                            x1={length}
                            y1={0}
                            x2={length - width}
                            y2={width}
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <circle
                            onClick={this.handleOnClick}
                            cx={length - width * .5}
                            cy={width * .5}
                            r={width * .2}
                            fill="white"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                    </> : <>
                        <line
                            onClick={this.handleOnClick}
                            x1={0}
                            y1={0}
                            x2={width}
                            y2={width}
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <circle
                            onClick={this.handleOnClick}
                            cx={width * .5}
                            cy={width * .5}
                            r={width * .2}
                            fill="white"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                    </>}
    
                    {/* suspension point on start and end of corner */}
                    {direction === "left" ? <>
                        <line
                            onClick={this.handleOnClick}
                            x1={width * 1.25}
                            y1={width * .1}
                            x2={width * 1.25}
                            y2={width * .9}
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <path
                            onClick={this.handleOnClick}
                            d={SvgService.describeArc(0, width * .5, width * .2, 0, 180)}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />

                        <line
                            onClick={this.handleOnClick}
                            x1={length - width * .1}
                            y1={length - width * 1.25}
                            x2={length - width * .9}
                            y2={length - width * 1.25}
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <path
                            onClick={this.handleOnClick}
                            d={SvgService.describeArc(length - (width * .5), length, width * .2, 270, 90)}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        }

                    </> : <>
                        <line
                            onClick={this.handleOnClick}
                            x1={width * .1}
                            y1={length - width * 1.25}
                            x2={width * .9}
                            y2={length - width * 1.25}
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <path
                            onClick={this.handleOnClick}
                            d={SvgService.describeArc(width * .5, length, width * .2, 270, 90)}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />

                        <line
                            onClick={this.handleOnClick}
                            x1={length - width * 1.25}
                            y1={width * .1}
                            x2={length - width * 1.25}
                            y2={width * .9}
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                        <path
                            onClick={this.handleOnClick}
                            d={SvgService.describeArc(length, width * .5, width * .2, 180, 360)}
                            fill="transparent"
                            stroke={stroke}
                            stroke-opacity=".5"
                        />
                    </>}
    
    
                    {/* Lines for Length information */}
                    {article?.length && <g>

                        <line x1={0} y1={width * -1} x2={length} y2={width * -1}
                              stroke="#DDDDDD" stroke-width="1" />
                        <line x1={0} y1={width * -.6} x2={0} y2={width * -1.4}
                              stroke="#DDDDDD" stroke-width="1" />
                        <line x1={length} y1={width * -.6} x2={length} y2={width * -1.4}
                              stroke="#DDDDDD" stroke-width="1" />
                        <Label className={"canvas-label"} transform={labelTransformation} x={length * .5} y={width * -4.2}>{article.length}</Label>

                        {direction === "left" ?
                            <>
                                <line x1={length + width} y1={0} x2={length + width} y2={length}
                                      stroke="#DDDDDD" stroke-width="1" />
                                <line x1={length + width * .6} y1={0} x2={length + width * 1.4} y2={0}
                                      stroke="#DDDDDD" stroke-width="1" />
                                <line x1={length + width * .6} y1={length} x2={length + width * 1.4} y2={length}
                                      stroke="#DDDDDD" stroke-width="1" />
                                <Label className={"canvas-label"} transform={label2Transformation} x={length + width * 4.2} y={length * .5 - width}>{article.length}</Label>
                            </> :
                            <>
                                <line x1={-width} y1={0} x2={-width} y2={length}
                                      stroke="#DDDDDD" stroke-width="1" />
                                <line x1={-width * .6} y1={0} x2={-width * 1.4} y2={0}
                                      stroke="#DDDDDD" stroke-width="1" />
                                <line x1={-width * .6} y1={length} x2={-width * 1.4} y2={length}
                                      stroke="#DDDDDD" stroke-width="1" />
                                <Label className={"canvas-label"} transform={label2Transformation} x={-width * 3} y={2 * width}>{article.length}</Label>
                            </>
                        }
                    </g>}
    
    
                    {/* Buttons when active */}
                    {false && (
                        <>
                            <g onClick={() => this.handleOnInsert('before')} style={{cursor: "pointer"}}>
                                <circle cx={0} cy={width / 2} r="10"
                                        fill="#999999" stroke="#979797" stroke-width="1"
                                />
                                <line x1="-6" y1={width / 2} x2={6} y2={width / 2}
                                      stroke="#ffffff" stroke-width="1"
                                />
                                <line x1="0" y1={width / 2 - 6} x2="0" y2={width / 2 + 6}
                                      stroke="#ffffff" stroke-width="1"
                                />
                            </g>
                            <g onClick={() => this.handleOnInsert('after')} style={{cursor: "pointer"}}>
                                <circle cx={length - width / 2} cy={length} r="10"
                                        fill="#999999" stroke="#979797" stroke-width="1"
                                />
                                <line x1={length - 6 - width / 2} y1={length} x2={length + 6 - width / 2} y2={length}
                                      stroke="#ffffff" stroke-width="1"
                                />
                                <line x1={length - width / 2} y1={length - 6} x2={length - width / 2} y2={length + 6}
                                      stroke="#ffffff" stroke-width="1"
                                />
                            </g>
                        </>
                    )}

                </g>

                {active && (
                    <foreignObject x={0} y={0} width={length < 480 || isVertical ? 480 : length} height="160" transform={"translate(" + (isVertical ? -480 : length < 480 ? (length - 480) * .5 : 0) + ", " + (isVertical ? 0 : -160) + ")"}>
                        <ItemModal
                            article={article} active={active}
                            showRemove={false} isVertical={isVertical}
                            onChange={() => this.handleOnChange()} onClose={() => this.handleOnClose()}
                        />
                    </foreignObject>
                )}

            </g>
        );
    }
}

const Label = styled.text`
    fill: #666;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-anchor: middle;
    dominant-baseline: hanging;
`;

export default Corner;

import React, { Component } from 'react';
import styled from 'styled-components';

class LaneLabel extends Component {

    handleOnClick = (event) => {
        if (this.props.onClick) {
            this.props.onClick(this.props.itemKey, event);
        }
    };

    handleOnRemove = () => {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.itemKey);
        }
    };

    handleOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.props.itemKey);
        }
    };

    handleOnInsert = (position) => {
        if (this.props.onInsert) {
            this.props.onInsert(this.props.itemKey, position);
        }
    };

    handleOnClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const {
            laneNumber,
            orientation,
            x,
            y,
        } = this.props;

        return (
            <g transform={"translate(" + x + ", " + y + ")"}>
                <Label className={"canvas-label"}>S{laneNumber}</Label>
            </g>
        );
    }
}

const Label = styled.text`
    font-weight: bold;
    fill: #C0C0C0;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-anchor: middle;
    dominant-baseline: hanging;
`;

export default LaneLabel;

import axios from 'axios'
import handleError from './handleError'
import cloneDeep from 'clone-deep';
import HashSum from "hash-sum";

import * as DownloadService from "./DownloadService";
import * as Const from "../Const";


export const MIN_LINE_LENGTH = 0;
export const MAX_LINE_LENGTH = 20000;
export const NEAREST_NEIGHBOR_THRESHOLD = 20;

export const FETCH_PRODUCTS_URL = Const.API_URL + '?type=2000&query=products';

export const SAVE_CONFIGURATION_URL = Const.API_URL + '?type=2000&query=save';
export const LOAD_CONFIGURATION_URL = Const.API_URL + '?type=2000&query=load';


export const fetchProducts = () => {
    return new Promise((resolve, reject) => {

        axios.get(FETCH_PRODUCTS_URL)
            .then(response => { resolve(response.data) })
            .catch(error => { reject(handleError(error)) });
    });
};


export const filterProducts = (products, filters) => {

    return products.filter(function (product) {
        let match = true;

        filters.forEach(function (filter) {
            if (product[filter.field] !== filter.value) {
                match = false;
            }
        });

        return match;
    });

};


export const getIndividualsOfField = (products, field, filters = false) => {
    let values = [];

    if (filters !== false) {
        products = filterProducts(products, filters);
    }

    products.forEach(function (product) {
        const value = product[field];
        if (value && values.indexOf(value) === -1) {
            values.push(value);
        }
    });

    return values;
};

const getLaneData = (lines) => {
    let lane = {
        length: 0,
        price: 0.0,
        items: {}
    };

    lines.forEach(line => {
        if (lane.items[line.articleNumber]?.qty) {
            lane.items[line.articleNumber].qty++;
        }
        else {
            lane.items[line.articleNumber] = {
                qty: 1,
                line: line,
            }
        }
        lane.length += line.length;
        lane.price += line.price;
    });

    lane.items = Object.values(lane.items);

    return lane;
};

const mergeLaneData = (lane1, lane2) => {
    let newLane = {
        length: 0,
        price: 0.0,
        items: {

        }
    }, items = [...lane1.items, ...lane2.items];

    items.forEach(item => {
        if (newLane.items[item.line.articleNumber]?.qty) {
            newLane.items[item.line.articleNumber].qty += item.qty;
        }
        else {
            newLane.items[item.line.articleNumber] = {
                qty: item.qty,
                line: item.line,
            }
        }
    });

    newLane.items = Object.values(newLane.items);

    newLane.items.forEach(item => {
        newLane.length += item.line.length * item.qty;
        newLane.price += item.line.price * item.qty;
    });

    return newLane;
};

export const getAvailableLanes = (lines, allowEmptyLane) => {
    let availableLanes = [],
        fillerLines = lines;

    // For the following calculation to work, der need to be at leas 3 lines available
    if (!lines || lines.length < 4) {
        return availableLanes;
    }

    if (allowEmptyLane) {
        availableLanes.push(
            getLaneData([])
        );
    }

    // first create simple lanes just containing one or two lines
    lines.forEach(line => {

        // 1
        availableLanes.push(
            getLaneData([line])
        );

        // 1 + 1
        lines.forEach(line2 => {
            if (line.articleNumber !== line2.articleNumber) {
                availableLanes.push(
                    getLaneData([
                        line,
                        line2,
                    ])
                );
            }
        })
    });


    // Distinguish between the biggest line, second biggest and lines to fill up the rest of the lane
    fillerLines.sort((a, b) => b.length - a.length);

    let biggestLine = fillerLines[0],
        secondBiggestLine = fillerLines[1];

    fillerLines = fillerLines.filter(line => [biggestLine.articleNumber, secondBiggestLine.articleNumber].indexOf(line.articleNumber) === -1);

    // Prepare lanes only made from fillers
    let fillerLanes = [];

    fillerLines.forEach(line => {

        // 1
        fillerLanes.push(
            getLaneData([line])
        );

        // 1 + 1
        fillerLines.forEach(line2 => {
            if (line.articleNumber !== line2.articleNumber) {
                fillerLanes.push(
                    getLaneData([
                        line,
                        line2,
                    ])
                );
            }
        })
    });


    // Build as many lanes as possible containing the two biggest lines + up to two filler lines
    for (let i = 1; i * biggestLine.length < MAX_LINE_LENGTH; i++) {

        let lane = [];
        for (let j = 0; j < i; j++) {
            lane.push(biggestLine);
        }

        // lanes with biggest lines only
        let baseLane = getLaneData(lane);
        availableLanes.push(baseLane);

        // lanes with biggest lines plus filler lines
        fillerLanes.forEach(fillerLane => {
            availableLanes.push(
                mergeLaneData(
                    baseLane,
                    fillerLane,
                )
            );
        });

        // lanes with biggest and second biggest line
        for (let j = 1; baseLane.length + j * secondBiggestLine.length < MAX_LINE_LENGTH; j++) {
            let laneDual = [...lane];
            for (let k = 0; k < j; k++) {
                laneDual.push(secondBiggestLine);
            }

            let baseLaneDual = getLaneData(laneDual);
            availableLanes.push(baseLaneDual);

            // lanes with biggest and second biggest line plus filler lines
            fillerLanes.forEach(fillerLane => {
                availableLanes.push(
                    mergeLaneData(
                        baseLaneDual,
                        fillerLane,
                    )
                );
            });

        }

    }


    // Reduce duplicates to only the cheapest one of its length
    let availableLanesFiltered = {};
    availableLanes.forEach(lane => {

        if (availableLanesFiltered[lane.length] !== undefined || lane.length > MAX_LINE_LENGTH) {
            return true;
        }

        let bestMatch = lane;
        availableLanes.forEach(lane2 => {
            if (lane2.length === lane.length && lane2.price < lane.price) {
                bestMatch = lane2;
            }
        });

       availableLanesFiltered[lane.length] = bestMatch;

    });

    availableLanes = Object.values(availableLanesFiltered);

    // Sort by full length, min to max
    availableLanes.sort((a, b) => a.length - b.length);
    availableLanes.forEach(lane => {
        lane.items.sort((a, b) => b.line.length - a.line.length)
    });

    // Reduce similarly long lanes to the one that is cheaper
    availableLanesFiltered = [];
    let currentLength = 0;

    availableLanes.forEach(baseLane => {

        if (baseLane.length < currentLength) {
            return true;
        }

        let matchingLanes = availableLanes.filter(lane => lane.length >= baseLane.length && lane.length < baseLane.length + NEAREST_NEIGHBOR_THRESHOLD),
            bestLane = baseLane;

        if (matchingLanes.length > 0) {
            matchingLanes.forEach(matchingLane => {
                if (matchingLane.price < bestLane.price) {
                    bestLane = matchingLane;
                }
            });
        }

        availableLanesFiltered.push(bestLane);
        currentLength = baseLane.length + NEAREST_NEIGHBOR_THRESHOLD;

    });


    availableLanes = availableLanesFiltered;

    return availableLanes;

};

export const getDiffusorsForItems = (items, diffusors) => {
    let diffusorLanes = [],
        currentLane = 0,
        getEmptyDiffusorLane = (length) => {
            return {
                length: length,
                diffusor: false,
            }
        };

    // get length of the individual lanes that need a diffusor
    items.forEach((item, i) => {
        if (item.article.optics?.indexOf("Endlosdiffusor") !== -1) {
            if (item.meta.type === "corner") {
                currentLane += item.article.length;
                diffusorLanes.push(
                    getEmptyDiffusorLane(currentLane)
                );
                currentLane = item.article.length;
            }
            else if (item.meta.type === "line") {
                currentLane += item.article.length;
            }
        }
        else if (currentLane !== 0) {
            diffusorLanes.push(
                getEmptyDiffusorLane(currentLane)
            );
            currentLane = 0;
        }
    });
    if (currentLane !== 0) {
        diffusorLanes.push(
            getEmptyDiffusorLane(currentLane)
        );
        currentLane = 0;
    }

    // find the fitting diffusor for each lane
    diffusors.sort((a, b) => a.length - b.length);
    for (let i = 0; i < diffusorLanes.length; i++) {
        diffusors.forEach(diffusor => {
            if (!diffusorLanes[i].diffusor && diffusorLanes[i].length < diffusor.length) {
                diffusorLanes[i].diffusor = diffusor;
            }
        });
    }

    let optimized = false;
    do {

        optimized = false;
        for (let i = 0; i < diffusorLanes.length; i++) {
            for (let j = 0; j < diffusorLanes.length; j++) {
                if (i !== j) {
                    diffusors.forEach(diffusor => {
                        if (!optimized && diffusorLanes[i].length + diffusorLanes[j].length < diffusor.length) {
                            diffusorLanes[i].length = diffusorLanes[i].length + diffusorLanes[j].length;
                            diffusorLanes[i].diffusor = diffusor;
                            diffusorLanes.splice(j, 1);
                            i = diffusorLanes.length;
                            j = diffusorLanes.length;
                            optimized = true;
                        }
                    });
                }
            }
        }

    } while(optimized);


    let diffusorList = [];
    diffusorLanes.forEach(lane => {
        diffusorList.push(lane.diffusor);
    });

    return diffusorList;
};


// ToDo: Respect corner direction, for now this isn't necessary since every corner is the same width
export const getOffsetForLine =
    (availableLines, selectedAttributes, corners = false, ends = false,
     countEnd = 0, countCorner = 0) => {

    let end = false,
        corner = false;
    availableLines = cloneDeep(availableLines);

    if (ends) {
        end = getEnd(ends, selectedAttributes);
    }

    if (corners) {
        corner = getCorner(corners, "left", selectedAttributes);
    }

    for (let i = 0; i < availableLines.length; i++) {
        if (corner) {
            availableLines[i].length += corner.length * countCorner;
        }
        if (end) {
            availableLines[i].length += end.length * countEnd;
        }
    }

    Object.entries(availableLines).map(([key, line]) => {
        if (line.length > MAX_LINE_LENGTH) {
            delete availableLines[key];
        }
    });

    return availableLines;

};

export const getInlineSupply = (supplies, selectedAttributes) => {
    let filters = [{
        field: "dimmable",
        value: selectedAttributes.dimmable,
    }];

    let supply = filterProducts(supplies, filters);

    if (supply.length === 0) {
        console.log("WARNING: No matching supply was found. ");
        supply = false;
    }
    else {
        if (supply.length > 1) {
            console.log("WARNING: More then one matching supply was found. ");
            console.log(supply);
        }
        supply = supply[0];
    }

    return supply;
}

export const getEnd = (ends, selectedAttributes) => {
    let filters = [{
        field: "mountingType",
        value: selectedAttributes.mountingType,
    }, {
        field: "color",
        value: selectedAttributes.color,
    }];

    // Only pendulum mount has electrical components, not wall mount
    if (selectedAttributes.mountingType === "Pendel-Lichtbandmontage") {
        filters.push({
            field: "dimmable",
            value: selectedAttributes.dimmable,
        });
    }

    let end = filterProducts(ends, filters);

    if (end.length === 0) {
        console.log("WARNING: No matching end was found. ");
        end = false;
    }
    else {
        if (end.length > 1) {
            console.log("WARNING: More then one matching end was found. ");
            console.log(end);
        }
        end = end[0];
    }

    return end;
};

export const getCorner = (corners, cornerDirection, selectedAttributes) => {
    let corner = filterProducts(corners, [{
        field: "type",
        value: "Ecke " + (cornerDirection === "left" ? "links" : "rechts"),
    }, {
        field: "mountingType",
        value: selectedAttributes.mountingType,
    }, {
        field: "opticsSimple",
        value: selectedAttributes.opticsSimple,
    }, {
        field: "dimmable",
        value: selectedAttributes.dimmable,
    }, {
        field: "output",
        value: selectedAttributes.output,
    }, {
        field: "color",
        value: selectedAttributes.color,
    }]);

    // @ToDo: Remove this line if possible
    // Only 90° corners are valid atm, this is more of a fallback
    corner = corner.filter(corner => corner.name.indexOf("/135°") === -1);

    if (corner.length === 0) {
        console.log("WARNING: No matching corner was found. ");
        corner = false;
    }
    else {
        if (corner.length > 1) {
            console.log("WARNING: More then one matching corner was found. ");
            console.log(corner);
        }
        corner = corner[0];
    }

    return corner;
};

export const remapItemsForSelectedAttributes = (items, selectedAttributes, products) => {
    let filteredProducts;

    items.map((item) => {
        switch (item.meta.type) {
            case 'line':
                filteredProducts = filterProducts(products.lines, [{
                    field: "length",
                    value: item.article.length,
                }, {
                    field: "opticsSimple",
                    value: item.article.opticsSimple,
                }, {
                    field: "mountingType",
                    value: selectedAttributes.mountingType,
                }, {
                    field: "dimmable",
                    value: selectedAttributes.dimmable,
                }, {
                    field: "output",
                    value: selectedAttributes.output,
                }, {
                    field: "color",
                    value: selectedAttributes.color,
                }]);
                if (filteredProducts[0]) item.article = filteredProducts[0];

                if (item.meta.spots) {
                    let newSpots = {};
                    Object.entries(item.meta.spots).map(([articleNumber, spot]) => {
                        let filteredSpot = filterProducts(products.railMounts, [{
                            field: "dimmable",
                            value: selectedAttributes.dimmable,
                        }, {
                            field: "color",
                            value: selectedAttributes.color,
                        }, {
                            field: "systemPower",
                            value: spot.article.systemPower,
                        }]);
                        if (filteredSpot[0]) {
                            newSpots[filteredSpot[0].articleNumber] = {
                                article: filteredSpot[0],
                                meta: spot.meta,
                            };
                        }
                    });
                    item.meta.spots = newSpots;
                }

                return item;
            case 'corner':
                filteredProducts = filterProducts(products.corners, [{
                    field: "length",
                    value: item.article.length,
                }, {
                    field: "type",
                    value: "Ecke " + (item.meta.direction === "left" ? "links" : "rechts"),
                }, {
                    field: "mountingType",
                    value: selectedAttributes.mountingType,
                }, {
                    field: "opticsSimple",
                    value: item.article.opticsSimple,
                }, {
                    field: "dimmable",
                    value: selectedAttributes.dimmable,
                }, {
                    field: "output",
                    value: selectedAttributes.output,
                }, {
                    field: "color",
                    value: selectedAttributes.color,
                }]);

                // @ToDo: Remove this line if possible
                // Only 90° corners are valid atm, this is more of a fallback
                filteredProducts = filteredProducts.filter(corner => corner.name.indexOf("/135°") === -1);
                if (filteredProducts[0]) item.article = filteredProducts[0];
                return item;
            case 'end':
                let filters = [{
                    field: "mountingType",
                    value: selectedAttributes.mountingType,
                }, {
                    field: "color",
                    value: selectedAttributes.color,
                }];

                // Only pendulum mount has electrical components, not wall mount
                if (selectedAttributes.mountingType === "Pendel-Lichtbandmontage") {
                    filters.push({
                        field: "dimmable",
                        value: selectedAttributes.dimmable,
                    });
                }

                filteredProducts = filterProducts(products.ends, filters);

                if (filteredProducts[0]) item.article = filteredProducts[0];
                return item;
            case 'inlineSupply':
                filteredProducts = filterProducts(products.inlineSupplies, [{
                    field: "mountingType",
                    value: selectedAttributes.mountingType,
                }, {
                    field: "dimmable",
                    value: selectedAttributes.dimmable,
                }]);
                if (filteredProducts[0]) item.article = filteredProducts[0];
                return item;
            default:
                return item;
        }
    });

    return items;
}

export const getNewPositionX = (item, orientation, positionX) => {
    switch (orientation) {
        case 90:
            return positionX + item.length;
        case 270:
            return positionX - item.length;
        default:
            return positionX;
    }
}

export const getNewPositionY = (item, orientation, positionY) => {
    switch (orientation) {
        case 180:
            return positionY + item.length;
        case 0:
            return positionY - item.length;
        default:
            return positionY;
    }
}

export const getNewPositionXBeforeCorner = (item, orientation, direction, positionX) => {
    switch (orientation) {
        case 90:
            return positionX;
        case 180:
            if (direction === 'right') return positionX - item.length + item.width;
            else return positionX;
        case 270:
            return positionX - item.length;
        case 0:
            if (direction === 'left') return positionX - item.length + item.width;
            else return positionX;
        default:
            return positionX;
    }
}

export const getNewPositionYBeforeCorner = (item, orientation, direction, positionY) => {
    switch (orientation) {
        case 90:
            if (direction === 'left') return positionY - item.length + item.width;
            else return positionY;
        case 180:
            return positionY;
        case 270:
            if (direction === 'right') return positionY - item.length + item.width;
            else return positionY;
        case 0:
            return positionY - item.length;
        default:
            return positionY;
    }
}

export const getNewPositionXAfterCorner = (item, orientation, direction, positionX) => {
    switch (orientation) {
        case 90:
            return positionX + item.length - item.width;
        case 180:
            if (direction === 'right') return positionX;
            else return positionX + item.length;
        case 270:
            return positionX;
        case 0:
            if (direction === 'left') return positionX;
            else return positionX + item.length;
        default:
            return positionX;
    }
}

export const getNewPositionYAfterCorner = (item, orientation, direction, positionY) => {
    switch (orientation) {
        case 90:
            if (direction === 'right') return positionY + item.length;
            else return positionY;
        case 180:
            return positionY + item.length - item.width;
        case 270:
            if (direction === 'left') return positionY + item.length;
            else return positionY;
        case 0:
            return positionY;
        default:
            return positionY;
    }
}

export const shiftPositions = (flattenedList, shiftX, shiftY) => {
    flattenedList.forEach((element, index) => {
        flattenedList[index].meta.position.x += shiftX;
        flattenedList[index].meta.position.y += shiftY;
    });

    return flattenedList;
}

export const recalculatePositions = (flattenedList) => {
    let positionX = 0;
    let positionY = 0;

    let minimalPositionX = 0;
    let minimalPositionY = 0;

    flattenedList.forEach((element, index) => {
        if (element.meta.type === "end" || element.meta.type === "line") {
            if (element.meta.orientation === 270 || element.meta.orientation === 0) {
                positionX = getNewPositionX(element.article, element.meta.orientation, positionX);
                positionY = getNewPositionY(element.article, element.meta.orientation, positionY);
            }

            minimalPositionX = Math.min(minimalPositionX, positionX);
            minimalPositionY = Math.min(minimalPositionY, positionY);

            flattenedList[index].meta.position.x = positionX;
            flattenedList[index].meta.position.y = positionY;

            if (element.meta.orientation === 90 || element.meta.orientation === 180) {
                positionX = getNewPositionX(element.article, element.meta.orientation, positionX);
                positionY = getNewPositionY(element.article, element.meta.orientation, positionY);
            }

            minimalPositionX = Math.min(minimalPositionX, positionX);
            minimalPositionY = Math.min(minimalPositionY, positionY);
        }
        if (element.meta.type === "corner") {
            positionX = getNewPositionXBeforeCorner(element.article, element.meta.orientation, element.meta.direction, positionX);
            positionY = getNewPositionYBeforeCorner(element.article, element.meta.orientation, element.meta.direction, positionY);

            minimalPositionX = Math.min(minimalPositionX, positionX);
            minimalPositionY = Math.min(minimalPositionY, positionY);

            flattenedList[index].meta.position.x = positionX;
            flattenedList[index].meta.position.y = positionY;

            positionX = getNewPositionXAfterCorner(element.article, element.meta.orientation, element.meta.direction, positionX);
            positionY = getNewPositionYAfterCorner(element.article, element.meta.orientation, element.meta.direction, positionY);

            minimalPositionX = Math.min(minimalPositionX, positionX);
            minimalPositionY = Math.min(minimalPositionY, positionY);
        }
    });

    minimalPositionX = Math.abs(minimalPositionX);
    minimalPositionY = Math.abs(minimalPositionY);

    flattenedList = shiftPositions(flattenedList, minimalPositionX, minimalPositionY);

    return flattenedList;
}

export const getFlatItem = (article, type, positionX, positionY, orientation, additionalMeta = {}) => {
    return {
        article: article,
        meta: {
            ...additionalMeta,
            type: type,
            userEditable: true,
            orientation: orientation,
            spots: {},
            position: { x: positionX, y: positionY }
        }
    }
};

export const reduceItemsParameter = (items, paramName, base = 0.0) => {
    return items.reduce((sum, item) => item.article[paramName] !== undefined ? sum + item.article[paramName] : sum, base);
};

export const reduceArticleParameter = (items, paramName, base = 0.0) => {
    return items.reduce((sum, item) => item[paramName] !== undefined ? sum + item[paramName] : sum, base);
};

export const reduceArticleListParameter = (items, paramName, base = 0.0) => {
    return items.reduce((sum, item) => item.article[paramName] !== undefined ? sum + item.article[paramName] * item.qty : sum, base);
};


export const getAccessoryList = (configuration) => {
    let list = {};

    // Reduce diffusors into the list
    if (configuration?.result?.diffusors) {
        configuration.result.diffusors.forEach(item => {

            if (list[item.articleNumber]) {
                list[item.articleNumber].qty++;
            }
            else {
                list[item.articleNumber] = {
                    article: item,
                    qty: 1,
                }
            }
        });
    }

    // Add spots to accessory list
    configuration.items.forEach(item => {
        if (item.meta.spots) {

            Object.entries(item.meta.spots).map(([articleNumber, spot]) => {

                if (list[spot.article.articleNumber]) {
                    list[spot.article.articleNumber].qty += spot.meta.qty;
                }
                else {
                    list[spot.article.articleNumber] = {
                        article: spot.article,
                        qty: spot.meta.qty,
                    }
                }
            });

        }
    });


    // Add end (always first of item list)
    if (configuration?.items[0] && configuration?.items[0]?.meta?.type === "end") {
        let end = configuration?.items[0];
        list[end.article.articleNumber] = {
            article: end.article,
            qty: 1,
        };
    }

    return list;

};


export const getImageLink = (article, abs) => {
    let name = getImageName(article);

    return !!name ?
        (abs ? Const.ASSET_URL + Const.ASSET_PATH : "") + "produktbilder/" + name
        : false;
};

export const getImageName = (article) => {
    return article.familyName + "_" + article.lightEngine + (article.color ? "_" + article.color : "") + ".jpeg";
};

export const getArticleByNumber = (articleNumber, products) => {
    let result = false;
    products.forEach(article => {
        if (article.articleNumber === articleNumber) {
            result = article;
        }
    });
    return result;
};



export const saveConfiguration = (configuration, forceNew = false) => {

    let reducedConfiguration = {
        selectedAttributes: {
            ...configuration.selectedAttributes
        },
        layout: {
            ...configuration.layout,
        },
        items: {
            ...configuration.items,
        },
        meta: {
            ...configuration.meta,
        }
    }, configurationKey = configuration.configurationKey;

    if (forceNew) {
        configurationKey = false;
    }

    return new Promise((resolve, reject) => {

        axios.post(SAVE_CONFIGURATION_URL, {
            configuration: reducedConfiguration,
        }, {
            params: {
                configurationKey: configurationKey,
            },
        })
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) })
    });
};


export const loadConfiguration = (configurationKey) => {

    return new Promise((resolve, reject) => {

        axios.get(LOAD_CONFIGURATION_URL, {
            params: {
                configurationKey: configurationKey,
            },
        })
            .then(response => { resolve(response.data) })
            // .catch(error => { reject(handleError(error)) })
    });
};

/**
 * While this serio configurator gets updated configuration data may change. This function adds missing data or
 * migrates parts of the configuration
 * @param configuration
 * @param products
 */
export const migrateConfiguration = (configuration, products) => {

    // Add dxfFileName field if it isn't present yet
    configuration.items.forEach((item, key) => {

        if (!item.article["dxfFileName"]) {
            let newArticle = getArticleByNumber(item.article.articleNumber, products);
            if (newArticle !== false) {
                configuration.items[key].article["dxfFileName"] = newArticle.dxfFileName;
            }
        }
    });

    return configuration

};


export const getConfigurationHash = (configuration) => {
    const reducedConfiguration = DownloadService.reduceConfigurationData(configuration);
    return HashSum(reducedConfiguration);
};

import * as ActionTypes from '../actions';

import i18n from 'i18n-js';


const initialState = {
    translations: {
        de: false,
        en: false,
    },
    locale: "de",
    fallbackLocale: "en",
    isLoading: false,
};

const checkForLoading = (state) => {
    let isLoading = false;
    Object.entries(state.translations).map((language, data) => {
        if (data === false) {
            isLoading = true;
        }});
    return isLoading;
};


i18n.locale = initialState.locale;
i18n.defaultLocale = initialState.fallbackLocale;
i18n.fallbacks = true;
i18n.translations = {
    de: {},
    en: {},
};
i18n.missingTranslation = function(scope, options) {
    let localeForTranslation = (options != null && options.locale != null) ? options.locale : i18n.currentLocale();
    let fullScope           = i18n.getFullScope(scope, options);
    let fullScopeWithLocale = [localeForTranslation, fullScope].join(i18n.defaultSeparator);

    return "[" + fullScopeWithLocale + "]";
};

const Language = (state = initialState, action) => {
    let newState = {...state};
    switch(action.type) {

        case ActionTypes.FETCH_LANGUAGE_REQUEST:
            return { ...state, isLoading: true };

        case ActionTypes.FETCH_LANGUAGE_SUCCESS:
        case ActionTypes.FETCH_LANGUAGE_FAILURE:
            newState.translations[action.payload.language] = action.payload.data;
            newState.isLoading = checkForLoading(newState);

            i18n.translations = newState.translations;

            return newState;

        case ActionTypes.CHANGE_LANGUAGE:
            i18n.locale = action.payload;
            return { ...state, locale: action.payload };


        default:
            return state;
    }
};

export default Language;

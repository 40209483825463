import React, { Component } from 'react';
import {connect} from 'react-redux';

import styled from "styled-components";

import Legend from "../../components/Legend";
import SelectColor from "../../components/SelectColor";
import SelectOutput from "../../components/SelectOutput";
import Switch from "../../components/Switch";

import * as ProductService from '../../services/ProductService';

import * as ActionTypes from "../../actions";
import i18n from "i18n-js";
import Tooltip from "../../components/Tooltip";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeSelectedAttribute: (params) => dispatch(ActionTypes.changeSelectedAttribute(params)),
        changeItems: (params) => dispatch(ActionTypes.changeItems(params)),
    };
};

class Controls extends Component {

    handleChangeColor = (newValue) => {
        this.props.changeSelectedAttribute({
            attribute: "color",
            value: newValue,
        });

        this.handleAttributesChanged();
    };

    handleChangeOutput = (newValue) => {
        this.props.changeSelectedAttribute({
            attribute: "output",
            value: newValue,
        });

        this.handleAttributesChanged();
    };

    handleChangeDimmable = (newValue) => {

        if (newValue === "DALI") {
            this.props.changeSelectedAttribute({
                attribute: "output",
                value: "HI",
            });
        }

        this.props.changeSelectedAttribute({
            attribute: "dimmable",
            value: newValue,
        });

        this.handleAttributesChanged();
    };

    handleAttributesChanged = () => {
        const {configuration, products} = this.props;

        const items = ProductService.remapItemsForSelectedAttributes(configuration.items, configuration.selectedAttributes, products);
        this.props.changeItems(items);
    }

    render() {
        const {configuration} = this.props;
        const attributes = configuration.selectedAttributes;

        return (

            <Container>

                <div>
                    <Legend style={{marginBottom: 0}}>Details</Legend>
                </div>

                <ControlsWrap>

                    <Control>
                        <Switch label={"DIM"} active={attributes.dimmable === "DALI"} onClick={() => this.handleChangeDimmable(attributes.dimmable === "DALI" ? "ET" : "DALI")} />
                    </Control>

                    <Control>
                        <SelectOutput value={attributes.output} locked={attributes.dimmable === "DALI"}
                                      onClickLow={() => this.handleChangeOutput("LOW")} onClickHigh={() => this.handleChangeOutput("HI")} />
                    </Control>

                    <Control>
                        <ColorLabel>{i18n.t('title.caseColor')} <Tooltip languageKey={"colorSelection"} showBelow /></ColorLabel>
                        <SelectColor
                            label={"Weißaluminium"} active={attributes.color === "aen"}
                            onClick={() => this.handleChangeColor("aen")}
                            backgroundColor={"#EEEBEB"} backgroundImage={"linear-gradient(270deg, #B5B5B5 0%, rgba(196,196,196,0.5) 40%, rgba(86,86,86,0.5) 100%)"}
                        />
                        <SelectColor
                            label={"Verkehrsweiß"} active={attributes.color === "vw"}
                            onClick={() => this.handleChangeColor("vw")}
                            backgroundColor={"#FFFFFF"}
                        />
                        <SelectColor
                            label={"Tiefschwarz"} active={attributes.color === "sw"}
                            onClick={() => this.handleChangeColor("sw")}
                            backgroundColor={"#1F1F1F"}
                        />
                    </Control>

                </ControlsWrap>

            </Container>

        )
    }
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;
const ControlsWrap = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
`;

const Control = styled.div`
    margin: 10px 0 10px 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const ColorLabel = styled.div`
    color: #999999;
    font-size: 15px;
    line-height: 20px;
    margin-right: 8px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Controls);

import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import WebsiteImage from "../WebsiteImage";

import * as ProductService from "../../services/ProductService";
import i18n from "i18n-js";

class ItemModal extends Component {

    render() {
        const {
            article,
            spotlightCount,
            active,
            isVertical,
            onChange,
            onRemove,
            onClose,
        } = this.props;

        return (
            active &&
            <Wrap>
                <Inner>
                    <PopOver style={isVertical ? {top: 10} : {bottom: 16}}>

                        <Image>
                            <WebsiteImage name={ProductService.getImageLink(article, false)} absolute={false} />
                        </Image>

                        <Content>

                            <Close onClick={onClose}>
                                <FontAwesomeIcon size={14} color={"#999999"} icon={["fal", "times"]} />
                            </Close>
                            <strong>{article.name}</strong><br />
                            {i18n.t('title.articleNumberShort')} {article.articleNumber}<br />
                            {article.noticeText && <Notice>{i18n.t('configurator.noticeText.' + article.noticeText)}</Notice>}
                            <Meta>
                                <div>
                                    <Fact>{i18n.t('value.opticsSimple.' + article.opticsSimple)}</Fact>
                                    <Fact>{article.length} mm</Fact>
                                    {!!spotlightCount && spotlightCount > 0 && <Fact>{i18n.t('title.' + (spotlightCount === 0 ? "spot" : "spots"))}: {spotlightCount}x</Fact>}
                                </div>

                                <Control>
                                    <a onClick={onChange}>{i18n.t('action.change')}</a>
                                    {onRemove && (
                                        <a onClick={onRemove}>
                                            <FontAwesomeIcon icon={["fas", "trash-alt"]} />
                                        </a>
                                    )}
                                </Control>
                            </Meta>
                        </Content>

                        {isVertical ? <ArrowRight /> : <ArrowBottom />}
                    </PopOver>
                </Inner>
            </Wrap>
        )
    }
}

const Wrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;
const Inner = styled.div`
    position: relative;
    height: 100%;
`;
const PopOver = styled.div`
    position: absolute;
    left: 16px;
    right: 16px;
    max-width: 460px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, .2));
    &:after {
        content: '';
        clear: both;
    }
`;

const Image = styled.div`
    flex: 0 0 auto;
    height: 120px;
    img {
        height: 100%;
        width: auto;
    }
`;
const Content = styled.div`
    flex: 1;
    padding: 12px 16px;
`;

const Fact = styled.span`
    display: inline-block;
    padding: 6px 8px;
    color: #999999;
    background-color: #F5F5F5;
    margin: 0 4px 4px 0;
    font-size: 11px;
    line-height: 13px;
`;
const Notice = styled.p`
    margin: 8px auto 12px;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
`;

const Close = styled.span`
    float: right;
    padding: 8px;
    margin: -10px;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
`;

const Meta = styled.div`
    margin: 24px 0 6px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const Control = styled.div`
    margin: 0 0 0 16px;
    display: flex;
    a {
        display: inline-block;
        color: #999999;
        cursor: pointer;
        font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 0 0 12px;
    }
`;
const ArrowBottom = styled.span`
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-8px);
    border-top: 8px solid #FFFFFF;
    border-left: 8px solid transparent; 
    border-right: 8px solid transparent; 
`;
const ArrowRight = styled.span`
    position: absolute;
    right: -16px;
    top: 24px;
    transform: translateX(-8px);
    border-left: 8px solid #FFFFFF;
    border-top: 8px solid transparent; 
    border-bottom: 8px solid transparent; 
`;


export default ItemModal;

import React, { PureComponent } from 'react';

import styled from 'styled-components';


class Switch extends PureComponent {

    render() {

        const {
            label,
            active,
            ...props
        } = this.props;


        const Container = styled.div`
            display: flex;
            align-items: center;
        `;
        const Label = styled.p`
            color: #C0C0C0;
            font-size: 15px;
            font-weight: bold;
            line-height: 20px;
            margin: 0 8px 0 0;
        `;
        const Element = styled.div`
            position: relative;
            height: 22px;
            width: 44px;
            border-radius: 100px;
            background-color: ${active ? "#E45200" : "#ECECEC"};
            box-shadow: inset 0 1px 3px 0 rgba(0,0,0,.15);
            ::before {
                content: '';
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background-color: #FFFFFF;
                box-shadow: 0 2px 6px -2px rgba(0,0,0,0.3);
                transform: ${active ? "translateX(22px)" : "translateX(0)"};
            }
        `;


        return (
            <Container {...props}>
                <Label>{label}</Label>
                <Element />
            </Container>
        );
    }

}


export default Switch;

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";

import styled from "styled-components";

import * as ActionTypes from "../actions";
import * as LinkService from "../services/LinkService";

import Header from "../components/Header";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
        loadConfiguration: (params) => dispatch(ActionTypes.loadConfiguration(params)),
    };
};

class LoadConfiguration extends Component {

    constructor(props) {
        super(props);

        this.state = {
            configurationKey: props.match.params.configurationKey,
            isLoading: false,
        }
    }

    componentDidUpdate() {

        if (this.props.products.products && !this.state.isLoading) {
            this.props.loadConfiguration(this.state.configurationKey);
            this.setState({
                ...this.state,
                isLoading: true,
            });
        }

        // We assume loading the configuration was successful once the items array is rehydrated
        // ToDo: this could provoke errors, so maybe find a better way?
        if (this.props.configuration.items.length) {
            this.props.redirectTo(
                LinkService.getPathForContainer(this.props.language.locale, "Result")
            );
        }
    };

    render() {

        const {configurationKey} = this.state;

        return (
            <Container className="Configurator">

                <Header />

                <LoadingContainer>Loading configuration <strong>{configurationKey}</strong>...</LoadingContainer>

            </Container>
        )
    }
}


const Container = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
`;
const LoadingContainer = styled.div`
    margin-top: 120px;
    text-align: center;
`;

export default connect(mapStateToProps, mapDispatchToProps)(LoadConfiguration);

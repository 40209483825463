import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from 'styled-components';
import i18n from "i18n-js";

import * as LinkService from "../services/LinkService";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const mapStateToProps = state => ({
    configuration: state.configuration,
    location: state.router.location,
    language: state.language,
});

class Pager extends Component {

    getPages() {
        return [
            {
                key: "ProductSelection",
                path: LinkService.getPathForContainer(this.props.language.locale, "ProductSelection"),
                num: 1,
                label: i18n.t('pager.productSelection'),
            },
            {
                key: "FormSelection",
                path: LinkService.isContainerAllowed("LineInput", this.props.configuration) ? LinkService.getPathForContainer(this.props.language.locale, "LineInput") : LinkService.getPathForContainer(this.props.language.locale, "FormSelection"),
                num: 2,
                label: i18n.t('pager.formSelection'),
            },
            {
                key: "Configurator",
                path: LinkService.getPathForContainer(this.props.language.locale, "Configurator"),
                num: 3,
                label: i18n.t('pager.configurator'),
            }
        ];
    }

    getActiveIndex() {
        const currentContainer = LinkService.getContainerFromPath(this.props.location.pathname);
        switch (currentContainer) {
            case "ProductSelection":
                return 1;
            case "FormSelection":
            case "LineInput":
                return 2;
            case "Configurator":
                return 3;
            case "Result":
                return 4;
            default:
                return 0;
        }
    };

    isNextAllowed(nextIndex) {
        const {
            configuration,
        } = this.props;

        if (nextIndex === 2 && LinkService.isContainerAllowed("FormSelection", configuration)) {
            return true;
        }

        if (nextIndex === 3 && LinkService.isContainerAllowed("Configurator", configuration)) {
            return true;
        }

        if (nextIndex === 4 && LinkService.isContainerAllowed("Result", configuration)) {
            return true;
        }

        return false;
    }


    render() {

        const pages = this.getPages(),
            activeIndex = this.getActiveIndex(),
            nextIndex = activeIndex + 1,
            isNextAllowed = this.isNextAllowed(nextIndex);

        return (
            <Container>

                {activeIndex > 1 ? <LineActive style={{left: "0%"}} /> : <Line style={{left: "0%"}} />}
                {activeIndex > 2 ? <LineActive style={{left: "50%"}} /> : <Line style={{left: "50%"}} />}

                {pages && Object.entries(pages).map(([key, page]) => (

                    <div key={"page-" + page.num}>

                        {page.num < activeIndex &&
                            <Link to={page.path}>
                                <ItemPrev>
                                    <Num>
                                        <FontAwesomeIcon icon={["fal", "check"]} />
                                    </Num>
                                    <Label>{page.label}</Label>
                                </ItemPrev>
                            </Link>
                        }

                        {page.num === activeIndex &&
                            <ItemActive>
                                <Num>{page.num}</Num>
                                <Label>{page.label}</Label>
                            </ItemActive>
                        }

                        {page.num === nextIndex && isNextAllowed &&
                            <Link to={page.path}>
                                <Item>
                                    <Num>{page.num}</Num>
                                    <Label>{page.label}</Label>
                                </Item>
                            </Link>
                        }
                        {(page.num > nextIndex || (page.num === nextIndex && !isNextAllowed)) &&
                            <ItemDisabled>
                                <Num>{page.num}</Num>
                                <Label>{page.label}</Label>
                            </ItemDisabled>
                        }

                    </div>

                ))}
            </Container>
        );
    }
}


const Container = styled.div`
    position: relative;
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin: 0 50px 0 22px;
    background: #fff;
`;
const Item = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    color: #DDDDDD;
    padding: 8px;
    background-color: #ffffff;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
`;
const ItemDisabled = styled(Item)`
    cursor: not-allowed;
`;
const ItemActive = styled(Item)`
    color: #e45200;
    cursor: default;
`;
const ItemPrev = styled(ItemActive)`
    cursor: pointer;
`;
const Num = styled.div`
    border: 1px solid;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin-right: 8px;
    text-align: center;
    padding-top: 3px;
    box-sizing: border-box;
`;
const Label = styled.div`
    text-transform: uppercase;
`;

const Line = styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid #DDDDDD;
    margin: 0 20px;
`;
const LineActive = styled(Line)`
    border-color: #e45200;
`;


export default connect(mapStateToProps)(Pager)

import React, { PureComponent } from 'react';
import styled from 'styled-components';


class Modal extends PureComponent {
    render() {
        const {
            isVisible,
            children,
            small,
            medium,
            ...props
        } = this.props;

        return (
            <>
            {isVisible && <ModalDialog>
                <ModalDialogContent medium={medium} small={small}>{children}</ModalDialogContent>
            </ModalDialog>}
            </>
        );
    }
}


const ModalDialog = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
`;
const ModalDialogContent = styled.div`
    background: #fff;
    width: 100%;
    max-width: ${props => (props.small ? "480px" : (props.medium ? "720px" : "960px"))};
    overflow-y: auto;
    margin: 60px;
    padding: 30px;
`;


export default Modal;

import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from "connected-react-router";

import SwiperCore, { Lazy, Autoplay } from 'swiper';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import styled from "styled-components";
import axios from "axios";

import * as LinkService from "../../services/LinkService";
import * as Const from "../../Const";
import Button from "../../components/Button";
import i18n from "i18n-js";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        language: state.language,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        redirectTo: (path) => dispatch(push(path)),
    };
};

SwiperCore.use([Lazy, Autoplay]);

class Content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: false,
        };
    }

    componentDidMount() {
        this.getContent();
    }

    getContent() {
        let url = Const.ASSET_URL + Const.ASSET_PATH + "home/content." + this.props.language.locale + ".json";

        axios.get(url)
            .then(response => {

                if (response.status === 200 && response.data) {
                    this.setState({
                        ...this.state,
                        content: response.data
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        content: null
                    });
                }

            })
            .catch(() => {
                this.setState({
                    ...this.state,
                    content: null
                });
            });
    }

    startConfiguration() {
        this.props.redirectTo(
            LinkService.getPathForContainer(this.props.language.locale, "ProductSelection")
        );
    }

    render() {

        const content = this.state.content;

        return (
            content ?
                <>

                    <Section>

                        <TeaserRow>

                            {content.teaser.map((teaser, i) => (
                                <Teaser key={teaser.title.toLowerCase().replace(' ', '-')}>
                                    <TeaserImage src={teaser.image} />
                                    <TeaserHeadline>{teaser.title}</TeaserHeadline>
                                    <TeaserContent>{teaser.content}</TeaserContent>
                                </Teaser>
                            ))}

                        </TeaserRow>

                        <div style={{marginTop: 80, textAlign: "center"}}>
                            <Button onClick={() => this.startConfiguration()} label={i18n.t('home.startLong')} filled wide />
                        </div>

                    </Section>

                    <SwiperWrap>
                        <Swiper spaceBetween={20} freeMode={true} slidesPerView={'auto'}
                                autoplay={{delay: 5000}}>
                            {content.inspiration.map((name, i) => (
                                <SwiperSlide key={i}>
                                    <SliderImage src={"https://www.lichtwerk.de/fileadmin/serio/home/" + name} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </SwiperWrap>

                    <Section small style={{textAlign: "center"}}>
                        <SectionHeadline>{content.description.headline}</SectionHeadline>
                        <TeaserText>
                            <TeaserOpenerText>{content.description.teaser}</TeaserOpenerText>
                            <p>
                                {content.description.content}
                            </p>
                        </TeaserText>
                        <Button onClick={() => this.startConfiguration()} label={i18n.t('home.startLong')} filled wide />
                    </Section>

                </>
                : null
        )
    }
}

const Section = styled.div`
    width: 100%;
    max-width: ${props => props.small ? "960px" : "1200px"};
    margin: 100px auto;
    padding: 0 15px;
    box-sizing: border-box;
`;

const SwiperWrap = styled.div`
    margin: 100px auto;
    overflow: hidden;
    .swiper-container {
        max-width: 1200px;
        overflow: visible;
    }
    .swiper-slide {
        height: 600px; 
        width: auto;
    }
`;
const SliderImage = styled.img`
    height: 100%;
    width: auto;
`;

const SectionHeadline = styled.h2`
    color: #e45200;
    font-size: 36px;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
`;

const TeaserRow = styled.div`
    display: flex;
    flex-direction: row;
`;
const Teaser = styled.div`
    display: block;
    flex: 0 1 25%;
    padding: 0 30px;
`;
const TeaserImage = styled.img`
    width: 100%;
    height: auto;
    margin-bottom: 35px;
`;
const TeaserHeadline = styled.p`
    color: #e45200;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 1.1875;
    text-transform: uppercase;
    margin-bottom: 9px;
`;
const TeaserContent = styled.p`
    color: #444444;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4;
`;

const TeaserText = styled.div`
    margin: 30px auto 40px;
    width: 100%;
    max-width: 720px;
    white-space: pre-line;
    p {
        line-height: 1.4;
        margin: 0 auto 20px;
    }
`;
const TeaserOpenerText = styled.p`
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 22px !important;
    line-height: 1.3 !important;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Content);

import * as DownloadService from '../services/DownloadService'
import * as Const from '../Const'


export const DOWNLOAD_STATUS_CHANGE = 'DOWNLOAD_STATUS_CHANGE';

export function changeDownloadStatus(payload) {
    return {
        type: DOWNLOAD_STATUS_CHANGE,
        payload,
    };
}

export function download(filetype, configuration, additionalData) {
    return (dispatch) => {
        dispatch(changeDownloadStatus({
            newStatus: Const.DOWNLOAD_STATUS_SENDING
        }));

        DownloadService.download(filetype, configuration, additionalData)
            .then(response => {
                if (response.data && response.data.error === false) {
                    DownloadService.downloadLink(response.data.link.replace('http://', 'https://'));
                    dispatch(changeDownloadStatus({
                        newStatus: Const.DOWNLOAD_STATUS_SUCCESS
                    }));
                }
                else {
                    dispatch(changeDownloadStatus({
                        newStatus: Const.DOWNLOAD_STATUS_ERROR
                    }));
                }
            })
            .catch(error => {
                dispatch(changeDownloadStatus({
                    newStatus: Const.DOWNLOAD_STATUS_ERROR
                }));
            });
    }
}


export function downloadMultiple(filetypes, configuration, additionalData) {
    return (dispatch) => {
        dispatch(changeDownloadStatus({
            newStatus: Const.DOWNLOAD_STATUS_SENDING
        }));

        DownloadService.downloadMultiple(filetypes, configuration, additionalData)
            .then(response => {
                if (response.data && response.data.error === false) {
                    DownloadService.downloadLink(response.data.link);
                    dispatch(changeDownloadStatus({
                        newStatus: Const.DOWNLOAD_STATUS_SUCCESS
                    }));
                }
                else {
                    dispatch(changeDownloadStatus({
                        newStatus: Const.DOWNLOAD_STATUS_ERROR
                    }));
                }
            })
            .catch(error => {
                dispatch(changeDownloadStatus({
                    newStatus: Const.DOWNLOAD_STATUS_ERROR
                }));
            });
    }
}

import React, { Component } from 'react';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from "styled-components";
import i18n from "i18n-js";

import {AutoSizer} from 'react-virtualized';

import * as ItemService from "../services/ItemService";

import CanvasController from "./Canvas/CanvasController";
import CanvasMiniature from "./Canvas/CanvasMiniature";

import ComponentDidMount from "../components/ComponentDidMount";

import {
    INITIAL_VALUE,
    ReactSVGPanZoom,
    TOOL_AUTO,
    TOOL_NONE,
} from 'react-svg-pan-zoom';

import Corner from "../components/Configuration/Corner";
import End from "../components/Configuration/End";
import LaneLabel from "../components/Configuration/LaneLabel";
import Line from "../components/Configuration/Line";


const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        language: state.language,
    };
};

class Canvas extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: INITIAL_VALUE,
            zoom: 10,
        };

        this.canvas = null;
        this.viewer = props.canvasRef ?? React.createRef();
        this.group = null;
    }

    changeValue(nextValue) {
        this.setState({value: nextValue})
    }

    componentDidUpdate(prevProps) {

        if (prevProps.configuration.items !== this.props.configuration.items) {
            //this.handleCenterCanvas();
        }

        // console.log(this.viewer?.ViewerDOM?.outerHTML ?? false);
    };

    handleCenterCanvas = () => {
        const boundingBox = this.group?.getBBox();

        if (boundingBox && this.viewer?.current?.props) {

            const widthRatio = boundingBox.width / this.viewer.current.props.width;
            const heightRatio = boundingBox.height / this.viewer.current.props.height;
            let ratio;

            if (widthRatio >= heightRatio) {
                ratio = widthRatio + 0.2;
            } else {
                ratio = heightRatio + 0.2;
            }

            this.setState({zoom: 10 * ratio});

            //this.viewer.fitSelection(boundingBox.x, boundingBox.y, boundingBox.width, boundingBox.height);
            this.viewer.current.setPointOnViewerCenter(boundingBox.width / 2 / ratio, boundingBox.height / 2 / ratio, 1.0);
        }
    };

    increaseZoom = () => {
        this.setState({zoom: this.state.zoom * 0.8});
    };

    decreaseZoom = () => {
        this.setState({zoom: this.state.zoom * 1.2});
    };

    static getColorCode(color) {
        let code = "#888888";
        switch (color) {
            case "aen":
                code = "#999999";
                break;
            case "vw":
                code = "#c7c7c7";
                break;
            case "sw":
                code = "#444444";
                break;
        }

        return code;
    }

    static strokeActive = '#e45200';


    static getLaneBasedArticleListWithCoordinates(items) {

        let lanes = {},
            currentArticleList = [],
            currentLane = 1,
            cornerCount = 0;

        if (items) {
            items.forEach((item, index) => {

                if (item.meta.type === "line" || item.meta.type === "corner") {
                    currentArticleList.push(item);
                }

                // write lane and start new one with the corner as initial value
                if (item.meta.type === "corner") {
                    lanes[currentLane] = currentArticleList;
                    currentArticleList = [
                        item
                    ];
                    currentLane++;
                    cornerCount++;

                    // Special case for square elements: prepend the last corner element to the first line
                    if (cornerCount === 4) {
                        lanes[1].unshift(item);
                    }
                }
                else if (items.length === index+1) {
                    lanes[currentLane] = currentArticleList;
                }
            });
        }

        return lanes;
    }

    getLaneLabelPositions() {
        const {configuration} = this.props,
            items = configuration.items,
            articleList = Canvas.getLaneBasedArticleListWithCoordinates(items);

        let positions = {},
            position;

        Object.entries(articleList).map(([lane, articles]) => {
            const lastIndex = articles.length - 1;
            position = {
                x: 0,
                y: 0,
                orientation: articles[lastIndex].meta.orientation
            };

            switch (articles[lastIndex].meta.orientation) {
                case 0:
                    position = {
                        x: articles[0].meta.position.x - articles[0].article.width * 3,
                        y: (articles[0].meta.position.y + articles[0].article.length - articles[lastIndex].meta.position.y ) / 2 + articles[lastIndex].meta.position.y,
                        orientation: articles[lastIndex].meta.orientation,
                    };

                    if (articles[0].meta.type === "corner") {
                        position.x += articles[0].article.length;
                    }
                    break;

                case 90:
                    position = {
                        x: (articles[lastIndex].meta.position.x - articles[0].meta.position.x + articles[lastIndex].article.length) / 2 + articles[0].meta.position.x,
                        y: articles[0].meta.position.y - articles[0].article.width * 3,
                        orientation: articles[lastIndex].meta.orientation,
                    };

                    if (articles[0].meta.type === "corner") {
                        position.y += articles[0].article.length - articles[0].article.width * 2;
                    }
                    break;

                case 180:
                    position = {
                        x: articles[0].meta.position.x + articles[0].article.width * 3,
                        y: (articles[lastIndex].meta.position.y - articles[0].meta.position.y + articles[lastIndex].article.length) / 2 + articles[0].meta.position.y,
                        orientation: articles[lastIndex].meta.orientation,
                    };

                    if (articles[0].meta.type === "corner") {
                        position.y += articles[0].article.length - articles[0].article.width * 2;
                    }
                    break;

                case 270:
                    position = {
                        x: (articles[0].meta.position.x + articles[0].article.length - articles[lastIndex].meta.position.x) / 2 + articles[lastIndex].meta.position.x,
                        y: articles[0].meta.position.y + articles[0].article.width * 3,
                        orientation: articles[lastIndex].meta.orientation,
                    };
                    break;
            }

            positions[lane] = position;

        });

        return positions;
    }


    renderItems() {
        const { zoom } = this.state,
            {
                items, activeItems, selectedItem, color, mountingType, showDimensions,
                handleItemSelect, handleItemChange, handleItemInsert, handleItemRemove, handleItemClose
            } = this.props,
            stroke = Canvas.getColorCode(color),
            laneLabelPositions = this.getLaneLabelPositions();

        let active = false, rendered = [];

        if (items) {
            Object.entries(items).map(([key, item]) => {
               let isActive = key === selectedItem,
                   tempItem = <g key={key}>
                   {item.meta.type === 'corner' &&
                        <Corner
                            itemKey={key}
                            article={item.article}
                            active={isActive}
                            onClick={handleItemSelect}
                            onChange={handleItemChange}
                            onInsert={handleItemInsert}
                            onClose={handleItemClose}
                            x={item.meta.position.x / zoom}
                            y={item.meta.position.y / zoom}
                            orientation={item.meta.orientation}
                            direction={item.meta.direction}
                            length={item.article.length / zoom}
                            width={61 / zoom}
                            stroke={activeItems && activeItems.indexOf(Number(key)) !== -1 ? Canvas.strokeActive : stroke}
                        />
                    }
                   {item.meta.type === 'line' &&
                        <Line
                            itemKey={key}
                            article={item.article}
                            active={isActive}
                            onClick={handleItemSelect}
                            onRemove={handleItemRemove}
                            onChange={handleItemChange}
                            onInsert={handleItemInsert}
                            onClose={handleItemClose}
                            spotlightCount={showDimensions && ItemService.getSpotlightCount(item)}
                            x={item.meta.position.x / zoom}
                            y={item.meta.position.y / zoom}
                            length={item.article.length / zoom}
                            width={item.article.width / zoom}
                            orientation={item.meta.orientation}
                            stroke={activeItems && activeItems.indexOf(Number(key)) !== -1 ? Canvas.strokeActive : stroke}
                            isLast={Number(key) + 1 === Object.entries(items).length}
                        />
                    }
                   {showDimensions && item.meta.type === 'end' && mountingType === "Pendel-Lichtbandmontage" &&
                       <End
                           x={item.meta.position.x / zoom}
                           y={item.meta.position.y / zoom}
                           length={item.article.width / zoom}
                           width={item.article.width / zoom}
                           orientation={item.meta.orientation}
                           stroke={stroke}
                       />
                   }
                </g>;

               if (isActive) {
                   active = tempItem;
               }
               else {
                   rendered.push(tempItem);
               }
            });
        }

        if (laneLabelPositions) {
            Object.entries(laneLabelPositions).map(([lane, position]) => {
                rendered.push(<LaneLabel x={position.x / zoom} y={position.y / zoom} orientation={position.orientation} laneNumber={lane} />);
            });
        }

        if (active) {
            rendered.push(active);
        }

        return rendered;
    }

    onCanvasWheel = event => {
        event.preventDefault();

        let zoom = this.state.zoom;
        zoom += event.deltaY * -0.01;
        zoom = Math.min(Math.max(2.5, zoom), 100);

        this.setState({
            ...this.state,
            zoom: zoom,
        });
    };

    render() {

        const { controls, width, height, canvasRef, items, showDimensions } = this.props,
            dimensions = ItemService.getDimensions(items);

        return (

            <CanvasElement style={{width: width, height: height}} ref={canvas => this.canvas = canvas} onWheel={controls && this.onCanvasWheel}>
                <AutoSizer>
                    {(({width, height}) => width === 0 || height === 0 ? null : (
                        <ComponentDidMount onComponentDidMount={this.handleCenterCanvas}>
                            <ReactSVGPanZoom width={width} height={height}
                                 ref={this.viewer}
                                 tool={controls ? TOOL_AUTO : TOOL_NONE}
                                 value={this.state.value}
                                 scaleFactorMax={1}
                                 scaleFactorMin={1}
                                 onChangeTool={tool => this.changeTool(tool)} onChangeValue={value => this.changeValue(value)}
                                 customToolbar={CanvasController} customMiniature={CanvasMiniature}
                                 background={"transparent"} SVGBackground={"transparent"}
                                 detectAutoPan={false} detectWheel={false}>
                                <svg>
                                    <g fillOpacity="1" strokeWidth="1" ref={group => this.group = group}>
                                        {this.renderItems()}
                                    </g>
                                </svg>
                            </ReactSVGPanZoom>
                        </ComponentDidMount>
                    ))}
                </AutoSizer>

                {controls &&
                    <ZoomControls>
                        <ZoomItem onClick={() => this.increaseZoom()}>
                            <FontAwesomeIcon color={"#DDDDDD"} icon={["fal", "plus"]} />
                        </ZoomItem>
                        <ZoomItem onClick={() => this.decreaseZoom()}>
                            <FontAwesomeIcon color={"#DDDDDD"} icon={["fal", "minus"]} />
                        </ZoomItem>
                    </ZoomControls>
                }

                {showDimensions && dimensions && <Dimensions>
                    {Object.entries(dimensions)
                        .map(([line, length]) => (
                            <span key={line}><DimensionLabel>{i18n.t("title.laneShort")}{line}</DimensionLabel> {length} mm<br /></span>
                        ))
                    }
                </Dimensions>}

                <PerspectiveInfo>
                    <span style={{marginRight: 6}}><FontAwesomeIcon color={"#C0C0C0"} icon={["fal", "binoculars"]} /></span>{i18n.t("configurator.topView")}
                </PerspectiveInfo>
                

            </CanvasElement>

        )
    }
}


const CanvasElement = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    flex: 1 0 0;
    background-image: radial-gradient(hsla(0, 0%, 0%, .1) 20%, transparent 20%);
    background-size: 5px 5px;
`;
const ZoomControls = styled.div`
    position: absolute;
    right: 20px;
    bottom: 10px;
`;
const ZoomItem = styled.div`
    cursor: pointer;
    height: 28px;
    width: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px -2px rgba(0,0,0,0.3);
    margin: 0 0 10px;
`;

const PerspectiveInfo = styled.p`
    position: absolute;
    bottom: 10px;
    left: 20px;
    color: #C0C0C0;
    font-size: 14px;
    margin-bottom: 0;
`;
const Dimensions = styled.div`
    position: absolute;
    left: 20px;
    top: 15px;
    font-size: 15px;
    line-height: 24px;
`;
const DimensionLabel = styled.span`
    font-weight: bold;
    color: #C0C0C0;
    margin-right: 4px;
`;

export default connect(mapStateToProps)(Canvas);

import React, { Component } from 'react';
import {connect} from 'react-redux';

import {AutoSizer} from 'react-virtualized';

import CanvasController from "../../components/Canvas/CanvasController";
import CanvasMiniature from "../../components/Canvas/CanvasMiniature";

import {
    INITIAL_VALUE,
    ReactSVGPanZoom,
    TOOL_NONE,
} from 'react-svg-pan-zoom';

import styled from "styled-components";
import i18n from 'i18n-js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./formStyling.css";

import * as ActionTypes from "../../actions";
import * as ProductService from "../../services/ProductService";

import FormLengthInput from "../../components/FormLengthInput";
import ComponentDidMount from "../../components/ComponentDidMount";

import SimpleCorner from "../../components/Configuration/SimpleCorner";
import SimpleLine from "../../components/Configuration/SimpleLine";
import Tooltip from "../../components/Tooltip";

const mapStateToProps = (state) => {
    return {
        configuration: state.configuration,
        products: state.products,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeLayoutLineLengths: (params) => dispatch(ActionTypes.changeLayoutLineLengths(params)),
        changeItems: (params) => dispatch(ActionTypes.changeItems(params)),
        changeLayoutPowerSupplyLocation: (params) => dispatch(ActionTypes.changeLayoutPowerSupplyLocation(params)),
    };
};

class UForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableLines: {
                0: false,
                1: false,
                2: false,
            },
            tool: TOOL_NONE,
            value: INITIAL_VALUE,
        };

        this.canvas = null;
        this.viewer = null;
        this.group = null;
    }

    changeTool(nextTool) {
        this.setState({tool: nextTool})
    }

    changeValue(nextValue) {
        this.setState({value: nextValue})
    }

    componentDidMount() {
        this.updateAvailableLines();
    };

    componentDidUpdate(prevProps) {

        // Layout properties (flip, rotation, lengths) have changed, recalculate configuration
        if (prevProps.configuration.layout !== this.props.configuration.layout) {

            if (Object.entries(this.props.configuration.layout.lineLengths).length === 3) {
                this.extractItems();
            }
            else {
                this.props.changeItems([]);
            }
        }

        if (prevProps.configuration.items !== this.props.configuration.items) {
            this.handleCenterCanvas();
        }
    };

    handleCenterCanvas = () => {
        const boundingBox = this.group?.getBBox();

        if (boundingBox && this.viewer) {
            this.viewer.fitSelection(boundingBox.x, boundingBox.y, boundingBox.width, boundingBox.height);
            this.viewer.setPointOnViewerCenter(boundingBox.width / 2, boundingBox.height / 2, 1);
        }
    };

    onLineLengthChange = (key, newValue) => {
        const value = (newValue ? newValue: false),
            lineLenghts = {
                ...this.props.configuration.layout.lineLengths
            };
        let match = false;

        if (value) {
            this.state.availableLines[key].forEach((line) => {
                if (line.length === newValue) {
                    lineLenghts[key] = line;
                    match = true;
                }
            });

            if (lineLenghts[2] && key === 2) {
                lineLenghts[2].items.reverse();
            }

            if (!match && lineLenghts[key]) {
                delete lineLenghts[key];
            }

            this.props.changeLayoutLineLengths(lineLenghts);
        }
    };


    updateAvailableLines = () => {
        this.setState({
            ...this.state,
            availableLines: {
                0: ProductService.getOffsetForLine(
                    this.props.configuration.availableLines,
                    this.props.configuration.selectedAttributes,
                    this.props.products.corners,
                    this.props.products.ends,
                    1,
                    1
                ),
                1: ProductService.getOffsetForLine(
                    this.props.configuration.availableLines,
                    this.props.configuration.selectedAttributes,
                    this.props.products.corners,
                    this.props.products.ends,
                    0,
                    2
                ),
                2: ProductService.getOffsetForLine(
                    this.props.configuration.availableLines,
                    this.props.configuration.selectedAttributes,
                    this.props.products.corners,
                    this.props.products.ends,
                    1,
                    1
                )
            }
        });
    };

    onChangePowerSupplyLocation = (newValue) => {
        this.props.changeLayoutPowerSupplyLocation(newValue);
    };

    extractItems = () => {
        const {layout} = this.props.configuration;
        const {lineLengths} = this.props.configuration.layout;
        const lineLengthsArray = Object.values(lineLengths);

        // Count all direction changing parameters
        let directionChangingCount = 0;
        if (layout.powerSupplyLocation) directionChangingCount++;
        if (layout.flipX) directionChangingCount++;
        if (layout.flipY) directionChangingCount++;

        // Apply them to corner direction
        let direction = 'right';
        if (directionChangingCount % 2 === 1) {
            direction = 'left';
        }

        // Reverse items list if power supply location changed
        if (layout.powerSupplyLocation) {
            lineLengthsArray.reverse();
        }

        // Calculate initial orientation
        let orientation = 0;
        if (layout.flipY) orientation += 180;

        orientation += layout.rotation;
        // Prevent negative values
        orientation = (orientation + 360) % 360;

        let positionX = 0;
        let positionY = 0;

        let minimalPositionX = 0;
        let minimalPositionY = 0;

        const end = ProductService.getEnd(this.props.products.ends, this.props.configuration.selectedAttributes);
        let flattenedList = [
            ProductService.getFlatItem(end, "end", positionX, positionY,  orientation)
        ];

        positionX = ProductService.getNewPositionX(end, orientation, positionX);
        positionY = ProductService.getNewPositionY(end, orientation, positionY);

        minimalPositionX = Math.min(minimalPositionX, positionX);
        minimalPositionY = Math.min(minimalPositionY, positionY);

        lineLengthsArray.forEach((element, index) => {
            if (element.length) {
                element.items.forEach((article) => {
                    for (let i = 0; i < article.qty; i++) {
                        if (orientation === 270 || orientation === 0) {
                            positionX = ProductService.getNewPositionX(article.line, orientation, positionX);
                            positionY = ProductService.getNewPositionY(article.line, orientation, positionY);
                        }

                        minimalPositionX = Math.min(minimalPositionX, positionX);
                        minimalPositionY = Math.min(minimalPositionY, positionY);

                        flattenedList.push(
                            ProductService.getFlatItem(article.line, "line", positionX, positionY,  orientation)
                        );

                        if (orientation === 90 || orientation === 180) {
                            positionX = ProductService.getNewPositionX(article.line, orientation, positionX);
                            positionY = ProductService.getNewPositionY(article.line, orientation, positionY);
                        }

                        minimalPositionX = Math.min(minimalPositionX, positionX);
                        minimalPositionY = Math.min(minimalPositionY, positionY);
                    }
                });
            }

            // Add a corner after first and second line
            if (index === 0 || index === 1) {
                const corner = ProductService.getCorner(this.props.products.corners, direction, this.props.configuration.selectedAttributes);

                positionX = ProductService.getNewPositionXBeforeCorner(corner, orientation, direction, positionX);
                positionY = ProductService.getNewPositionYBeforeCorner(corner, orientation, direction, positionY);

                minimalPositionX = Math.min(minimalPositionX, positionX);
                minimalPositionY = Math.min(minimalPositionY, positionY);

                flattenedList.push(
                    ProductService.getFlatItem(corner, "corner", positionX, positionY, orientation, {
                        direction: direction
                    })
                );

                positionX = ProductService.getNewPositionXAfterCorner(corner, orientation, direction, positionX);
                positionY = ProductService.getNewPositionYAfterCorner(corner, orientation, direction, positionY);

                minimalPositionX = Math.min(minimalPositionX, positionX);
                minimalPositionY = Math.min(minimalPositionY, positionY);

                if (direction === 'left') {
                    orientation -= 90;
                } else {
                    orientation += 90;
                }

                // Prevent negative values
                orientation = (orientation + 360) % 360;
            }
        });

        minimalPositionX = Math.abs(minimalPositionX);
        minimalPositionY = Math.abs(minimalPositionY);

        flattenedList = ProductService.shiftPositions(flattenedList, minimalPositionX, minimalPositionY);

        this.props.changeItems(flattenedList);
    };


    render() {
        const {configuration} = this.props;
        const {availableLines} = this.state;
        const {layout} = configuration;

        let line1Rotation = 0;
        let line2Rotation = 90;
        let line3Rotation = 180;

        if (layout.flipX) line1Rotation += 180;
        if (layout.flipY) line2Rotation += 180;
        if (layout.flipX) line3Rotation += 180;

        line1Rotation += layout.rotation;
        line2Rotation += layout.rotation;
        line3Rotation += layout.rotation;

        line1Rotation = (line1Rotation + 360) % 360;
        line2Rotation = (line2Rotation + 360) % 360;
        line3Rotation = (line3Rotation + 360) % 360;

        const boundingBox = this.group?.getBBox();

        let line1Position, line2Position, line3Position;
        switch (line1Rotation) {
            case 270:
                // bottom
                line1Position = {x: boundingBox?.width / 2 - 90, y: boundingBox?.height + 10};
                break;
            case 0:
                // left
                line1Position = {x: 0 - 180, y: boundingBox?.height / 2 - 15};
                break;
            case 90:
                // top
                line1Position = {x: boundingBox?.width / 2 - 90, y: 0 - 50};
                break;
            case 180:
            default:
                // right
                line1Position = {x: boundingBox?.width + 10, y: boundingBox?.height / 2 - 15};
                break;
        }

        switch (line2Rotation) {
            case 270:
                // bottom
                line2Position = {x: boundingBox?.width / 2 - 90, y: boundingBox?.height + 10};
                break;
            case 0:
                // left
                line2Position = {x: 0 - 180, y: boundingBox?.height / 2 - 15};
                break;
            case 90:
                // top
                line2Position = {x: boundingBox?.width / 2 - 90, y: 0 - 50};
                break;
            case 180:
            default:
                // right
                line2Position = {x: boundingBox?.width + 10, y: boundingBox?.height / 2 - 15};
                break;
        }

        switch (line3Rotation) {
            case 270:
                // bottom
                line3Position = {x: boundingBox?.width / 2 - 90, y: boundingBox?.height + 10};
                break;
            case 0:
                // left
                line3Position = {x: 0 - 180, y: boundingBox?.height / 2 - 15};
                break;
            case 90:
                // top
                line3Position = {x: boundingBox?.width / 2 - 90, y: 0 - 50};
                break;
            case 180:
            default:
                // right
                line3Position = {x: boundingBox?.width + 10, y: boundingBox?.height / 2 - 15};
                break;
        }

        let scaleFactor = 0.05;
        if (configuration.layout.lineLengths[0]?.length > 12000 || configuration.layout.lineLengths[1]?.length > 12000) {
            scaleFactor = 0.02;
        }
        else if (configuration.layout.lineLengths[0]?.length > 8000 || configuration.layout.lineLengths[1]?.length > 8000) {
            scaleFactor = 0.04;
        }

        return (

                <Canvas ref={canvas => this.canvas = canvas}>
                    <AutoSizer>
                        {(({width, height}) => width === 0 || height === 0 ? null : (
                            <ComponentDidMount onComponentDidMount={this.handleCenterCanvas}>
                                <ReactSVGPanZoom width={width}
                                     height={height}
                                     ref={viewer => this.viewer = viewer}
                                     value={this.state.value}
                                     tool={this.state.tool}
                                     scaleFactorMax={1}
                                     scaleFactorMin={1}
                                     onChangeTool={tool => this.changeTool(tool)} onChangeValue={value => this.changeValue(value)}
                                     customToolbar={CanvasController} customMiniature={CanvasMiniature}
                                     background={"transparent"} SVGBackground={"transparent"}
                                     detectAutoPan={false}>
                                    <svg>
                                        <g fillOpacity="1" strokeWidth="1" ref={group => this.group = group}>

                                            {Object.entries(this.props.configuration.layout.lineLengths).length === 3 ? (
                                                <g>

                                                    {Object.entries(configuration.items).map(([key, item], index) => (
                                                        <g key={key}>
                                                            {item.meta.type === 'corner' ? (
                                                                <SimpleCorner
                                                                    x={item.meta.position.x * scaleFactor}
                                                                    y={item.meta.position.y * scaleFactor}
                                                                    orientation={item.meta.orientation}
                                                                    direction={item.meta.direction}
                                                                    length={item.article.length * scaleFactor}
                                                                    width={61 * scaleFactor} />
                                                            ) : (
                                                                <SimpleLine
                                                                    x={item.meta.position.x * scaleFactor}
                                                                    y={item.meta.position.y * scaleFactor}
                                                                    length={item.article.length * scaleFactor}
                                                                    width={item.article.width * scaleFactor}
                                                                    orientation={item.meta.orientation} />
                                                            )}

                                                            {index === 1 && (
                                                                <foreignObject
                                                                    fillOpacity="1"
                                                                    x={item.meta.orientation % 360 === 270 ? (item.meta.position.x + item.article.length) * scaleFactor - 10 : item.meta.position.x * scaleFactor - 10}
                                                                    y={item.meta.orientation % 360 === 0 ? (item.meta.position.y + item.article.length) * scaleFactor - 10 : item.meta.position.y * scaleFactor - 10}
                                                                    width="20"
                                                                    height="20">
                                                                    <div onClick={() => this.onChangePowerSupplyLocation( ! layout.powerSupplyLocation)} className={'supply active'}>
                                                                        <FontAwesomeIcon icon={["fas", "bolt"]} />
                                                                    </div>
                                                                </foreignObject>
                                                            )}

                                                            {index === Object.entries(configuration.items).length - 1 && (
                                                                <foreignObject
                                                                    fillOpacity="1"
                                                                    x={item.meta.orientation % 360 === 90 ? (item.meta.position.x + item.article.length) * scaleFactor - 10 : item.meta.position.x * scaleFactor - 10}
                                                                    y={item.meta.orientation % 360 === 180 ? (item.meta.position.y + item.article.length) * scaleFactor - 10 : item.meta.position.y * scaleFactor - 10}
                                                                    width="20"
                                                                    height="20">
                                                                    <div onClick={() => this.onChangePowerSupplyLocation( ! layout.powerSupplyLocation)} className={'supply'}>
                                                                        <FontAwesomeIcon icon={["fas", "bolt"]} />
                                                                    </div>
                                                                </foreignObject>
                                                            )}
                                                        </g>
                                                    ))}

                                                </g>

                                            ) : (
                                                <g>
                                                    <SimpleLine
                                                        x={0}
                                                        y={0}
                                                        length={100}
                                                        width={61 * scaleFactor}
                                                        orientation={0} />
                                                    <SimpleLine
                                                        x={0}
                                                        y={0}
                                                        length={200}
                                                        width={61 * scaleFactor}
                                                        orientation={90} />
                                                    <SimpleLine
                                                        x={200}
                                                        y={0}
                                                        length={100}
                                                        width={61 * scaleFactor}
                                                        orientation={0} />

                                                    <foreignObject
                                                        fillOpacity="1"
                                                        x={-35}
                                                        y={92}
                                                        width="20"
                                                        height="20">
                                                        <Tooltip languageKey={"powerSupply"} />
                                                    </foreignObject>

                                                    <foreignObject
                                                        fillOpacity="1"
                                                        x={-10}
                                                        y={90}
                                                        width="20"
                                                        height="20">
                                                        <div onClick={() => this.onChangePowerSupplyLocation( ! layout.powerSupplyLocation)} className={layout.powerSupplyLocation ? 'supply' : 'supply active'}>
                                                            <FontAwesomeIcon icon={["fas", "bolt"]} />
                                                        </div>
                                                    </foreignObject>
                                                    <foreignObject
                                                        fillOpacity="1"
                                                        x={190}
                                                        y={90}
                                                        width="20"
                                                        height="20">
                                                        <div onClick={() => this.onChangePowerSupplyLocation( ! layout.powerSupplyLocation)} className={layout.powerSupplyLocation ? 'supply active' : 'supply'}>
                                                            <FontAwesomeIcon icon={["fas", "bolt"]} />
                                                        </div>
                                                    </foreignObject>
                                                </g>
                                            )}

                                        </g>

                                        {boundingBox && (
                                            <g>
                                                <foreignObject x={line1Position.x} y={line1Position.y} width="160" height="30">
                                                    <FormLengthInput laneNumber={layout.powerSupplyLocation ? 3 : 1} value={configuration.layout.lineLengths[0]?.length} availableLines={availableLines[0]} placeholder={i18n.t('form.lengthPlaceholder')} onValueChange={(newValue) => this.onLineLengthChange(0, newValue)} autofocus />
                                                </foreignObject>
                                                <foreignObject x={line2Position.x} y={line2Position.y} width="160" height="30">
                                                    <FormLengthInput laneNumber={2} value={configuration.layout.lineLengths[1]?.length} availableLines={availableLines[1]} placeholder={i18n.t('form.lengthPlaceholder')} onValueChange={(newValue) => this.onLineLengthChange(1, newValue)} />
                                                </foreignObject>
                                                <foreignObject x={line3Position.x} y={line3Position.y} width="160" height="30">
                                                    <FormLengthInput laneNumber={layout.powerSupplyLocation ? 1 : 3} value={configuration.layout.lineLengths[2]?.length} availableLines={availableLines[2]} placeholder={i18n.t('form.lengthPlaceholder')} onValueChange={(newValue) => this.onLineLengthChange(2, newValue)} />
                                                </foreignObject>

                                            </g>
                                        )}
                                    </svg>
                                </ReactSVGPanZoom>
                            </ComponentDidMount>
                        ))}
                    </AutoSizer>
                </Canvas>

        )
    }
}

const Canvas = styled.div`
    flex: 1 0 0;
`;

export default connect(mapStateToProps, mapDispatchToProps)(UForm);

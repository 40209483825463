import React, { PureComponent } from 'react';
import styled from 'styled-components';


class Badge extends PureComponent {
    render() {

        const {
            label,
            block,
            onClick,
            ...props
        } = this.props;


        const Element = styled.div`
            display: ${block ? "block" : "inline-block"};
            padding: 4px;
            margin: 0 8px;
            text-align: center;
            color: #E45200;
            background-color: #ebebeb;
            font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
            font-weight: bold; 
            font-size: 14px; 
            line-height: 14px;
            text-transform: uppercase;
            cursor: ${onClick  ? "pointer" : "default"};
            a {
                color: inherit;
            }
        `;


        return (
            <Element onClick={onClick} {...props}>{label}</Element>
        );
    }
}


export default Badge;

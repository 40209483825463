import React, { PureComponent } from 'react';

class SimpleCorner extends PureComponent {
    render() {
        const {
            orientation,
            direction,
            x,
            y,
            length,
            width
        } = this.props;

        let rotation = (orientation + 360) % 360;
        let translation;

        switch (rotation) {
            case 90:
                translation = "0, -"+length;
                break;
            case 180:
                translation = "-"+length+", -"+length;
                break;
            case 270:
                translation = "-"+length+", 0";
                break;
            default:
                translation = "0, 0";
        }

        return (
            <svg x={x} y={y}>
                <g transform={"rotate("+rotation+") translate("+translation+")"}>
                    {direction === "left" ? (
                        <rect
                            x={length - width}
                            y={0}
                            width={width}
                            height={length}
                            fill="#888888" />
                    ) : (
                        <rect
                            x={0}
                            y={0}
                            width={width}
                            height={length}
                            fill="#888888" />
                    )}
                    <rect
                        x={0}
                        y={0}
                        width={length}
                        height={width}
                        fill="#888888" />
                </g>
            </svg>
        );
    }
}

export default SimpleCorner;

import * as DownloadService from '../services/DownloadService'
import * as Const from '../Const'
import axios from "axios/index";
import handleError from "../services/handleError";

export const CHANGE_INQUIRY_STATUS = 'CHANGE_INQUIRY_STATUS';

export const INQUIRY_URL = Const.API_URL + '?type=2000&query=inquiry';

export function changeInquiryStatus(payload) {
    return {
        type: CHANGE_INQUIRY_STATUS,
        payload
    }
}

export function sendInquiry(data) {
    return (dispatch) => {
        dispatch(changeInquiryStatus({
            newStatus: Const.INQUIRY_STATUS_SENDING
        }));

        callSendInquiry(data)
            .then(response => {

                if (response.status === 200 && response.data.error === false) {
                    dispatch(changeInquiryStatus({
                        newStatus: Const.INQUIRY_STATUS_SUCCESS
                    }));
                }
                else {
                    dispatch(changeInquiryStatus({
                        newStatus: Const.INQUIRY_STATUS_ERROR
                    }));
                }

            })
            .catch(error => {
                dispatch(changeInquiryStatus({
                    newStatus: Const.INQUIRY_STATUS_ERROR
                }));
            });
    }
}


export const callSendInquiry = data => {

    data.configuration = DownloadService.reduceConfigurationData(data.configuration);

    return new Promise((resolve, reject) => {

        axios.post(INQUIRY_URL, data)
            .then(response => { resolve(response) })
            .catch(error => { reject(handleError(error)) });
    });

};

import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as ProductService from "../../services/ProductService";

import WebsiteImage from "../../components/WebsiteImage";
import SpotSelectorTooltip from "./SpotSelectorTooltip";



class SpotSelector extends Component {

    decreaseCount = () => {
        this.props.onCountChange(
            this.props.spot,
            Math.max(this.props.count - 1, 0)
        );
    };
    increaseCount = () => {
        this.props.onCountChange(
            this.props.spot,
            this.props.count + 1
        );
    };

    getTooltipContent = (isIncrease) => {
        const {
            spot,
        } = this.props;

        return (
            <>

                {isIncrease ? "+" : "-"} {spot.requiresLength} mm <br />
                {isIncrease ? "+" : "-"} <NumberFormat value={spot.weight} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} suffix={" kg"} />
            </>
        )

    };

    render() {

        const {
            spot,
            count,
            isIncreaseAllowed,
        } = this.props;

        return (

            <Container>
                <Image>
                    <WebsiteImage name={"produktbilder/" + ProductService.getImageName(spot)} absolute />
                    <Label>{spot.name}</Label>
                </Image>
                <Controls>

                    <Control onClick={() => this.decreaseCount()} disabled={count < 1}>
                        <SpotSelectorTooltip content={this.getTooltipContent(false)}>
                            <FontAwesomeIcon icon={['fal', 'minus']} />
                        </SpotSelectorTooltip>
                    </Control>

                    <CountLabel>{count}</CountLabel>

                    <Control onClick={isIncreaseAllowed ? () => this.increaseCount() : null} disabled={!isIncreaseAllowed}>
                        <SpotSelectorTooltip content={this.getTooltipContent(true)}>
                            <FontAwesomeIcon icon={['fal', 'plus']} />
                        </SpotSelectorTooltip>
                    </Control>

                </Controls>
            </Container>

        )
    }
}

const Container = styled.div`
    display: inline-block;
    width: 200px;
    margin: 0 20px 20px 0;
    position: relative;
`;

const Image = styled.div`
    position: relative;
    padding-bottom: 100%;
`;
const Label = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
`;

const Controls = styled.div`
    width: 120px;
    margin: 0 auto;
    display: flex; 
    flex-direction: row;
    border: 1px solid #C0C0C0;
    :hover {
        border-color: #e45200;
    }
`;
const Item = styled.div`
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    padding: 12px 5px;
    box-sizing: border-box;
`;
const Control = styled(Item)`
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    opacity: ${props => props.disabled ? .5 : 1};
    :hover {
        color: #e45200;
    }
`;
const CountLabel = styled(Item)`
    
`;


export default SpotSelector;


import * as ProductService from "./ProductService";


/*
 * Spotlight helper functions
 */


export const getSpotlightCount = (item) => {
    let count = 0;

    if (item?.meta?.spots) {
        Object.entries(item.meta.spots).map(([articleNumber, spot]) => {
            count += spot.meta.qty;
        });
    }

    return count;
};


export const getCompleteRequiredSpotLength = (spots) => {
    let length = 0;

    if (spots) {
        Object.entries(spots).map(([articleNumber, spot]) => {
            length += spot.article.requiresLength * spot.meta.qty
        });
    }

    return length;
};

export const getCompleteRequiredSpotWeight = (spots) => {
    let weight = 0.0;

    if (spots) {
        Object.entries(spots).map(([articleNumber, spot]) => {
            weight += spot.article.weight * spot.meta.qty
        });
    }

    return weight;
};



/*
 * Article list helper
 */
export const getAccessoryList = (items, diffusors) => {
    let list = {};

    if (items) {
        items.forEach(item => {

            // Add spots to accessory list
            if (item.meta.spots) {
                Object.entries(item.meta.spots).map(([articleNumber, spot]) => {

                    if (list[spot.article.articleNumber]) {
                        list[spot.article.articleNumber].qty += spot.meta.qty;
                    }
                    else {
                        list[spot.article.articleNumber] = {
                            article: spot.article,
                            qty: spot.meta.qty,
                            meta: {
                                type: "spot",
                            },
                        }
                    }
                });

            }
        });

    }

    if (diffusors) {
        diffusors.forEach(item => {
            if (list[item.articleNumber]) {
                list[item.articleNumber].qty++;
            }
            else {
                list[item.articleNumber] = {
                    article: item,
                    qty: 1,
                    meta: {
                        type: 'diffusor',
                    }
                }
            }
        });
    }

    return list;
};
export const getLineList = (items) => {
    let list = {};

    if (items) {
        items.forEach(item => {

            if (list[item.article.articleNumber]) {
                list[item.article.articleNumber].qty++;
            }
            else {
                list[item.article.articleNumber] = {
                    article: item.article,
                    qty: 1,
                    meta: {
                        type: item.meta.type
                    }
                }
            }

        });
    }

    return list;
};

export const getArticleList = (items, diffusors) => {
    let list = getLineList(items),
        accessories = getAccessoryList(items, diffusors);

    list = Object.values(list);
    accessories = Object.values(accessories);

    list = list.concat(accessories);

    return list;
};


export const getArticleListPerLine = (items) => {

    let lines = {},
        currentLength = 0,
        currentArticleList = {},
        currentLine = 1;

    if (items) {
        items.forEach((item, index) => {

            currentLength += item.article.length;

            if (item.meta.type === "corner" || item.meta.type === "line") {
                if (currentArticleList[item.article.articleNumber]) {
                    currentArticleList[item.article.articleNumber].qty++;
                }
                else {
                    currentArticleList[item.article.articleNumber] = {
                        article: item.article,
                        qty: 1,
                        meta: {
                            type: item.meta.type
                        }
                    }
                }
            }

            // add corner length, write lane and start new one with the corners length as initial value
            if (item.meta.type === "corner") {
                lines[currentLine] = {
                    length: currentLength,
                    articleList: currentArticleList,
                };
                currentLength = item.article.length;
                currentArticleList = {};
                currentLine++;
            }
            // add length of end (always the first element of items) to last lane before writing it
            else if (items.length === index+1) {
                currentLength += items[0].article.length;
                lines[currentLine] = {
                    length: currentLength,
                    articleList: currentArticleList,
                };
            }
        });
    }

    return lines;
};

export const getLineBasedArticleList = (items) => {

    let lanes = {},
        currentArticleList = [],
        currentLane = 1,
        cornerCount = 0;

    if (items) {
        items.forEach((item, index) => {

            currentArticleList.push(item.article);

            // write lane and start new one with the corner as initial value
            if (item.meta.type === "corner") {
                lanes[currentLane] = currentArticleList;
                currentArticleList = [
                    item.article
                ];
                currentLane++;
                cornerCount++;

                // Special case for square elements: prepend the last corner element to the first line
                if (cornerCount === 4) {
                    lanes[1].unshift(item.article);
                }
            }
            // add end (always the first element of items) to last lane before writing it
            else if (items.length === index+1) {
                currentArticleList.push(items[0].article);
                lanes[currentLane] = currentArticleList;
            }
        });
    }

    return lanes;
};

export const getStructuralArticleList = (items) => {

    let lanes = {},
        currentArticleList = [],
        currentLane = 1,
        cornerCount = 0;

    if (items) {
        items.forEach((item, index) => {

            currentArticleList.push(item.article);

            // write lane and start new one
            if (item.meta.type === "corner") {
                lanes[currentLane] = currentArticleList;
                currentArticleList = [];
                currentLane++;
                cornerCount++;

                // Special case for square elements: prepend the last corner element to the first line
                if (cornerCount === 4) {
                    lanes[1].unshift(item.article);
                }
            }
            // add last lane on end reached
            else if (items.length === index+1) {
                lanes[currentLane] = currentArticleList;
            }
        });
    }

    return lanes;
};



/*
 * Calculate something from items
 */
export const getDimensions = (items) => {

    let lanes = getLineBasedArticleList(items),
        laneLengths = {};

    Object.entries(lanes).map(([laneNumber, laneItems]) => {
        laneLengths[laneNumber] = ProductService.reduceArticleParameter(laneItems, "length");
    });

    return laneLengths;
};

export const getTotalDimension = (items) => {
    let dimension = {
        width: 0,
        height: 0,
    };
    Object.entries(items).map(([key, item]) => {

        if (item.meta.type === "line") {
            if (item.meta.orientation === 0 || item.meta.orientation === 180) {
                dimension.height = Math.max(dimension.height, item.meta.position.y + item.article.length);
                dimension.width = Math.max(dimension.width, item.meta.position.x + item.article.width);
            }
            else if (item.meta.orientation === 90 || item.meta.orientation === 270) {
                dimension.height = Math.max(dimension.height, item.meta.position.y + item.article.width);
                dimension.width = Math.max(dimension.width, item.meta.position.x + item.article.length);
            }
        }
        else if (item.meta.type === "corner") {
            dimension.height = Math.max(dimension.height, item.meta.position.y + item.article.length);
            dimension.width = Math.max(dimension.width, item.meta.position.x + item.article.length);
        }

    });

    return dimension;
};

export const getTotalOpticsList = (items, mainOptics) => {
    let optics = {
        direct: [],
        indirect: [],
    }, mainOpticsDirect = false;
    const mainOpticsSplit = mainOptics.split("|");

    if (mainOpticsSplit.length) {
        mainOpticsDirect = mainOpticsSplit[0]
    }

    /* Possible value structure:
     * Endlosdiffusor opal satiniert|Diffusor opal satiniert => direct|indirect
     * Diffusor opal satiniert => indirect
     */

    items.forEach(item => {
        let splitOptics = item?.article?.optics?.split("|") ?? [],
            opticDirect = splitOptics[1] ? splitOptics[0] : false;

        // Don't add if
        // - already added
        // - is the same as main direct optic
        // - is powerrail
        if (opticDirect && optics.direct.indexOf(opticDirect) === -1
            && !(mainOpticsDirect && opticDirect === mainOpticsDirect)
            && opticDirect !== "Stromschiene") {
            optics.direct.push(opticDirect);
        }

        if (opticDirect && optics.indirect.indexOf(splitOptics[1]) === -1) {
            optics.indirect.push(splitOptics[1]);
        }
        else if (!opticDirect && splitOptics[0] && optics.indirect.indexOf(splitOptics[0])) {
            optics.indirect.push(splitOptics[0]);
        }

        if (item?.meta?.spots) {
            Object.entries(item.meta.spots).map(([articleNumber, spot]) => {

                if (spot.article.optics && optics.direct.indexOf(spot.article.optics) === -1) {
                    optics.direct.push(spot.article.optics);
                }

            });
        }

    });

    return optics;
};

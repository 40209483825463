import React, { Component } from 'react';

import styled from 'styled-components';
import i18n from 'i18n-js';

import logo from "../assets/Images/logo_tagline.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends Component {
    render() {

        const {customContent} = this.props;

        return (
            <>

                {customContent &&
                    <NewIndicator>{i18n.t("header.newInformation")}</NewIndicator>
                }

                <Container>

                    <Logo src={logo} title="Lichtwerk" alt="Lichtwerk Logo" />
                    <VL />
                    <h1>{i18n.t('header.title')}</h1>

                    <Spacer />

                    {customContent ? customContent :
                        <Homepage href={i18n.t('header.link')} target="_blank" rel="noopener noreferrer">{i18n.t('header.linkTitle')} <FontAwesomeIcon icon={['fal', 'arrow-circle-right']} style={{marginLeft: ".25em"}} /></Homepage>
                    }
                </Container>

            </>
        );
    }
}

const NewIndicator = styled.div`
    padding: 8px;
    background-color: #2d2d2d;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
`;
const Container = styled.header`
    padding: 10px 30px;
    height: 90px; // hardcoded for now 
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
`;
const Logo = styled.img`
    height: 32px;
    width: auto;
`;
const VL = styled.div`
    border-left: 1px solid #DDDDDD;
    height: 40px;
    margin: 0 20px;
`;
const Spacer = styled.div`
    flex: 1 0 auto;
`;
const Homepage = styled.a`
    color: #C0C0C0;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 19px;
`;


export default Header;

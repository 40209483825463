import React, { PureComponent } from 'react';
import styled from 'styled-components';

import * as Const from '../Const';


class WebsiteImage extends PureComponent {
    render() {
        const {
            name,
            absolute,
            ...props
        } = this.props;

        return (
            <>
                {absolute ?
                    <ImageAbsolute src={Const.ASSET_URL + "fileadmin/serio/" + name} {...props} /> :
                    <Image src={Const.ASSET_URL + "fileadmin/serio/" + name} {...props} />
                }
            </>
        );
    }
}


const Image = styled.img`
    width: 100%;
    height: auto;  
`;
const ImageAbsolute = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;



export default WebsiteImage;

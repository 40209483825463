export const CHANGE_SELECTED_ATTRIBUTE = 'CHANGE_SELECTED_ATTRIBUTE';

// export function changeSelectedAttribute(payload) {
//     return {
//         type: CHANGE_SELECTED_ATTRIBUTE,
//         payload
//     }
// }

export function changeSelectedAttribute(payload) {
    return (dispatch, getState) => {
        const { products } = getState();

        dispatch({
            type: CHANGE_SELECTED_ATTRIBUTE,
            payload: payload,
            products: products
        });
    };
}
